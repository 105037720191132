import React, { useState } from "react";
import {
  Stack,
  Typography,
  Button,
  IconButton,
  TextField,
  styled,
} from "@mui/material";
import { Breadcrumb, PageIndicator, InputField, Alert } from "../../components";

import {
  dashboardIcon,
  userIcon,
  addIcon,
  demoAvatar,
  historyIcon,
  pointIcon,
  calendarIcon,
} from "../../assets";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import profileImg from "../../assets/emojis/girl-with-nose-ring.svg";
import useFetch from "../../helpers/useFetch";
import axios from "axios";
import { success, error } from "../../common/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "iconsax-react";
import CircularProgress from "@mui/material/CircularProgress";
import { fetchUser } from "../../redux/slices/users/userSlice";
import { Input } from "../user-management/ManageProfile";
import { isUpperCase } from "../../utils/helpers";

import Loading from "../../common/Loading";

export const Form = styled(TextField)((theme) => ({
  border: "none",
  "& .MuiOutlinedInput-root": {
    fontFamily: "PT Sans",
    border: "none",
    fontSize: 14,
    alignItems: "flex-start",
    height: 48,
    borderRadius: 0,
    background: "#F4F4F4",
    borderColor: "#003163",
    // background: theme.palette.secondary.light,
    color: "#000 !important",

    "& fieldset": {
      border: "none",
      borderBottom: "1px solid",
      borderColor: "#b5b5b5",
      fontSize: 14,
    },

    "&:hover fieldset": {
      borderBottom: "1px solid",
      borderColor: "#b5b5b5",
    },

    "&:disabled .MuiTextField-root:disabled fieldset": {
      borderBottom: "1px solid",
      borderColor: "#b5b5b5",
    },

    "&.Mui-focused fieldset": {
      borderBottom: "1px solid",
      borderColor: "#b5b5b5",
    },
  },
}));

const roles = [
  {
    id: "1",
    value: "Super Admin",
  },
];

function ProfileSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [formState, setFormState] = useState(true);
  const user = useSelector((state) => state.users.user);
  const currentUserRoles = useSelector((state) => state.users?.user?.roles);

  const {
    data: data,
    isLoading: isLoading,
    refetch: refetch,
  } = useFetch(`api/user/${user?.id}/`);

  const {
    data: roleData,
    isLoading: roleIsLoading,
    refetch: roleRefetch,
  } = useFetch(`api/identity-manager/select-roles/`);
  const {
    data: auditData,
    isLoading: auditIsLoading,
    refetch: auditRefresh,
  } = useFetch(`api/audit/get_user_management_trails/`);

  const [updateFormState, setUpdateFormState] = useState({});
  const [userRoles, setUserRoles] = useState(roles);
  const [loading, setLoading] = React.useState(false);
  const [imageObjectURL, setImageObjectURL] = useState(null);

  const inputRef = React.useRef(null);
  const onButtonClick = () => {
    inputRef.current.click();
  };

  React.useEffect(() => {
    if (!roleIsLoading) {
      setUserRoles(roleData.data);
    }
  }, [roleData, roleIsLoading]);

  React.useEffect(() => {
    if (!isLoading) {
      setUpdateFormState(data.data);
    }
  }, [data, isLoading]);

  function handleEditForm() {
    setFormState(!formState);
  }

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const objectURL = URL.createObjectURL(e.target.files[0]);
      setUpdateFormState((prev) => ({
        ...prev,
        profile_image: e.target.files[0],
      }));
      setImageObjectURL(objectURL);
    }
  };

  async function handleFormSubmit() {
    setLoading(true);

    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_API}api/user/${user?.id}/`,
        updateFormState,
        config
      );
      success(res.data.message);
      setLoading(false);
      refetch();
      dispatch(fetchUser());
      auditRefresh();
    } catch (err) {
      error(err.response?.data?.message);
      setLoading(false);
      console.log(err);
    }
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Stack gap={"24px"}>
      <Alert
        display={false}
        iconClass={"bi bi-check-circle"}
        color={"#42BE65"}
        title={"Alert"}
        message={"Successful"}
      />
      <Stack marginBottom={"16px"}>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>

      <Stack
        sx={{
          backgroundColor: "#ffffff",
          px: "24px",
          py: "36px",
          borderRadius: "16px",
        }}
      >
        <div className="font-semibold text-xl border-b pb-3">
          <h2>User Setting</h2>
        </div>
        <Stack
          width={"100%"}
          columnGap={"100px"}
          direction={"row"}
          marginBottom={"16px"}
          marginTop={"24px"}
        >
          <Stack
            width={"70%"}
            minWidth={"70%"}
            gap={"24px"}
            sx={{
              background: "#fff",
              borderRadius: "4px",
            }}
          >
            <Stack width={"100%"} direction={"row"} gap={"48px"}>
              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  First Name
                </Typography>

                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  disabled={formState}
                  fullWidth
                  placeholder={"First Name"}
                  value={updateFormState?.first_name}
                  onChange={({ target }) => {
                    setUpdateFormState((prev) => ({
                      ...prev,
                      first_name: target.value,
                    }));
                  }}
                />
              </Stack>

              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Last Name
                </Typography>

                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  disabled={formState}
                  value={updateFormState?.last_name}
                  fullWidth
                  placeholder={"Idris"}
                  onChange={({ target }) => {
                    setUpdateFormState((prev) => ({
                      ...prev,
                      last_name: target.value,
                    }));
                  }}
                />
              </Stack>
            </Stack>

            <Stack width={"100%"} direction={"row"} gap={"48px"}>
              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Email Address
                </Typography>

                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  disabled={true}
                  fullWidth
                  placeholder={"kamali@aib.ng.com"}
                  value={updateFormState?.email}
                  onChange={({ target }) => {
                    setUpdateFormState((prev) => ({
                      ...prev,
                      email: target.value,
                    }));
                  }}
                />
              </Stack>

              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Phone Number
                </Typography>

                <Input
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "#D9D9D9",
                      },

                      "&.Mui-focused fieldset": {
                        borderColor: "#003163",
                      },
                    },
                  }}
                  disabled={formState}
                  fullWidth
                  type="number"
                  placeholder={"081 504 044 0405"}
                  value={updateFormState?.phone_number}
                  onChange={({ target }) => {
                    setUpdateFormState((prev) => ({
                      ...prev,
                      phone_number: target.value,
                    }));
                  }}
                />
              </Stack>
            </Stack>

            {/* <Stack width={"100%"} direction={"row"} gap={"48px"}>
            <Stack width={'100%'} gap={'6px'}>
            <Typography
              variant='p'
              fontSize={12}
              fontFamily={'Pt Sans'}
              color={'#646464'}
            >
              Initial Password
            </Typography>

            <Form InputProps={{
              endAdornment: (<IconButton onClick={() => handleFormState((prev) => ({
                ...prev,
                passwordState: !prev.passwordState
              }))} sx={{ alignSelf: 'center'}}><i className={ generalFormState.passwordState ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"} style={{ fontSize: 16, color:'#747474'}}></i></IconButton>)
            }} sx={{ 
              '& .MuiOutlinedInput-root': {
                "& fieldset": {
                  borderColor: formState ? '#b5b5b5' : '#003163',
                },
            
                "&:hover fieldset": {
                  borderColor: formState ? '#b5b5b5' : '#003163',
                },
            
                "&.Mui-focused fieldset": {
                  borderColor: formState ? '#b5b5b5' : '#003163',
                }
              }
            }} disabled={formState} type={generalFormState.passwordState ? 'text' : 'password'} fullWidth placeholder={'Password'} onChange={({ target }) => {
              handleFormState((prev) => ({
                ...prev,
                password: target.value
              }))
            }} />
          </Stack>

          <Stack width={'100%'} gap={'6px'}>
            <Typography
              variant='p'
              fontSize={12}
              fontFamily={'Pt Sans'}
              color={'#646464'}
            >
              Confirm Password
            </Typography>

            <Form InputProps={{
              endAdornment: (<IconButton onClick={() => handleFormState((prev) => ({
                ...prev,
                confirmPasswordState: !prev.confirmPasswordState
              }))} sx={{ alignSelf: 'center'}}><i className={ generalFormState.confirmPasswordState ? "bi bi-eye-fill" : "bi bi-eye-slash-fill"} style={{ fontSize: 16, color:'#747474'}}></i></IconButton>)
            }} sx={{ 
              '& .MuiOutlinedInput-root': {
                "& fieldset": {
                  borderColor: formState ? '#b5b5b5' : '#003163',
                },
            
                "&:hover fieldset": {
                  borderColor: formState ? '#b5b5b5' : '#003163',
                },
            
                "&.Mui-focused fieldset": {
                  borderColor: formState ? '#b5b5b5' : '#003163',
                }
              }
            }} disabled={formState} type={generalFormState.confirmPasswordState ? 'text' : 'password'} fullWidth placeholder={'Password'} onChange={({ target }) => {
              handleFormState((prev) => ({
                ...prev,
                confirmPassword: target.value
              }))
            }} />
          </Stack>
          </Stack> */}

            <Stack gap={"8px"} width={"50%"} paddingRight={"24px"}>
              <Typography variant="p" color={"#D8D8D8"}>
                Role Assigned
              </Typography>

              <Input
                disabled={formState}
                fullWidth
                className={`${
                  isUpperCase(updateFormState?.role) ? "capitalize" : ""
                }`}
                placeholder={"Super Management"}
                value={
                  isUpperCase(updateFormState?.role)
                    ? updateFormState?.role?.replace("-", " ")?.toLowerCase()
                    : updateFormState?.role?.replace("-", " ")
                }
              />

              {/* <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={
                  userRoles?.find((role) => role.id == updateFormState?.roles)
                    ?.value || ""
                }
                onChange={(e) => {
                  setUpdateFormState((prevState) => ({
                    ...prevState,
                    roles: e.target.value,
                  }));
                }}
                disabled={formState}
                // displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="h-[53px]"
              >
                <MenuItem value="">
                  <Typography
                    fontFamily={"PT Sans"}
                    fontSize={14}
                    color={"inherit"}
                  >
                    Select Role
                  </Typography>
                </MenuItem>
                {userRoles?.map((eachItem) => (
                  <MenuItem
                    key={eachItem.id}
                    value={eachItem.value}
                    aria-selected={updateFormState?.roles === eachItem.id}
                  >
                    <Typography
                      fontFamily={"PT Sans"}
                      fontSize={14}
                      color={"inherit"}
                    >
                      {eachItem.value}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}

              {/* <Stack
              ref={selectFormRef}
              direction={'row'}
              alignItems={'center'}
              gap={'10px'}
              sx={{
                background: '#F4F4F4',
                height: '48px',
                paddingLeft: '16px',
                borderBottom: formState ? '1px solid #b5b5b5' : '1px solid #003163',
              }}
            >
              {selectedRoles.map((eachRole) => (
                <Typography
                  key={eachRole.id}
                  variant='body2'
                  fontSize={'14px'}
                  fontFamily={'PT Sans'}
                  color={'#161616'}
                  sx={{
                    background: '#CBCBCB',
                    px: '8px',
                    py: '2px',
                    width: 'fit-content',
                    lineHeight: '16.8px',
                    letterSpacing: '0.16px',
                    borderRadius: '50px',
                  }}
                >
                  {updateFormState.roles}
                </Typography>
              ))}
              <Typography
                variant='body2'
                fontSize={'14px'}
                fontFamily={'PT Sans'}
                color={'#7E7E7E'}
                sx={{
                  lineHeight: '16.8px',
                  letterSpacing: '0.16px',
                  display: selectedRoles.length > 0 ? 'none' : 'block'
                }}
              >
                Select Role(s)
              </Typography>

              <Button
                variant='contained'
                disableElevation
                onClick={handleClick}
                sx={{
                  borderRadius: 0,
                  textTransform: 'none',
                  height: '100%',
                  fontFamily: 'PT Sans',
                  color: '#CFE0FF',
                  fontSize: 14,
                  marginLeft: 'auto',
                  minWidth: 'fit-content',
                  p: '8px',
                  '&.MuiButton-contained': {
                    background: formState ? '#CBCBCB' : '#003163',
                  }

                }}>
                <img src={addIcon} alt="" />
              </Button>

              <MenuComponent anchorEl={anchorEl} handleClose={handleClose} open={open} userRoles={userRoles} selectFormWidth={selectFormWidth} handleSelectRole={handleSelectRole} />
            </Stack> */}

              {/* <Stack marginY={'10px'} width={'100%'}>
              <Stack direction={'row'} alignItems={'center'}>
                <Checkbox disabled={formState} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />
                <Typography fontSize={14} color={'#161616'} lineHeight={'120%'} fontFamily={'PT Sans'}>
                  Automatically create a password
                </Typography>
              </Stack>

              <Stack direction={'row'} alignItems={'center'}>
                <Checkbox disabled={formState} sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />
                <Typography fontSize={14} color={'#161616'} lineHeight={'120%'} fontFamily={'PT Sans'}>
                  Require this user to change their password when they first sign in
                </Typography>
              </Stack>
            </Stack> */}
            </Stack>
          </Stack>

          <Stack alignSelf={"flex-start"} gap={"20px"}>
            <div className="w-[184px] h-[184px] bg-red-500 rounded-full grid place-content-center">
              <img
                src={profileImg}
                // updateFormState?.profile_image instanceof File
                //   ? URL.createObjectURL(updateFormState.profile_image)
                //   : updateFormState?.profile_image
                //   ? updateFormState.profile_image
                //   : demoAvatar

                width={"124px"}
                height={"124px"}
                alt=""
              />
            </div>
            <Stack
              height={"fit-content"}
              gap={"6px"}
              visibility={formState ? "hidden" : "none"}
            >
              <input
                ref={inputRef}
                type="file"
                id="input-file-upload"
                className="hidden h-full"
                onChange={handleChange}
              />
              <Typography
                fontFamily={"PT Sans"}
                fontSize={14}
                color={"#161616"}
                fontWeight={700}
              >
                Upload Photo
              </Typography>
              <Typography
                lineHeight={"120%"}
                fontFamily={"PT Sans"}
                fontSize={14}
                color={"#525252"}
                fontWeight={400}
              >
                Max file size is 500kb. Supported file types are .jpg and .png.
              </Typography>

              <Button
                variant="contained"
                disableElevation
                sx={{
                  "&.MuiButton-contained": {
                    background: "#003163",
                    width: "120px",
                    height: "fit-content",
                    borderRadius: 0,
                    textTransform: "none",
                    paddingX: "14px",
                    paddingY: "5px",
                    marginTop: "8px",
                  },
                }}
                onClick={onButtonClick}
              >
                Upload Photo
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {/* {currentUserRoles?.role
        ?.toLowerCase()
        ?.includes("super-administrator") && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
          sx={{
            background: "#fff",
            px: "32px",
            py: "16px",
            height: "fit-content",
          }}
        >
          <Button
            disableElevation
            variant="contained"
            onClick={formState ? handleEditForm : handleFormSubmit}
            sx={{
              width: "81px",
              height: "45px",
              textTransform: "none",
              borderRadius: 0,
              "&.MuiButton-contained": {
                background: "#003163",
              },
            }}
          >
            {formState ? (
              "Edit"
            ) : (
              <>
                Save{" "}
                {loading ? (
                  <CircularProgress className="w-3 h-3 ml-1 text-white" />
                ) : null}
              </>
            )}
          </Button>
          {formState ? null : (
            <Button
              disableElevation
              variant="outlined"
              onClick={handleEditForm}
              sx={{
                width: "81px",
                height: "45px",
                textTransform: "none",
                borderRadius: 0,
                color: "#DA1E28",
                "&.MuiButton-outlined": {
                  borderColor: "#DA1E28",
                },
              }}
            >
              Cancel
            </Button>
          )}
        </Stack>
      )} */}

      {/* {currentUserRoles?.role
        ?.toLowerCase()
        ?.includes("super-administrator") && (
        <Stack width={"100%"}>
          <Stack
            gap={"4px"}
            direction={"row"}
            alignItems={"center"}
            sx={{
              background: "#F1C21B",
              width: "100%",
              padding: "24px 42px",
              borderRadius: "4px 4px 0 0",
            }}
          >
            <img src={historyIcon} alt="" />
            <Typography
              color={"#161616"}
              fontSize={"12px"}
              fontFamily={"PT Sans"}
              fontWeight={700}
            >
              Audit Trail
            </Typography>
          </Stack>
          <Stack
            minHeight={"200px"}
            sx={{
              p: "18px 42px 38px 42px",
              background: "#fff",
            }}
          >
            <Stack
              gap={"32px"}
              sx={{
                borderLeft: "1px solid",
                borderColor: "#7E7E7E",
              }}
            >
              {auditData?.data?.map((eachAudit) => (
                <Stack
                  key={eachAudit.id}
                  direction={"row"}
                  gap={"8px"}
                  position={"relative"}
                  paddingLeft={"48px"}
                >
                  <img
                    src={pointIcon}
                    style={{
                      position: "absolute",
                      left: "-12px",
                    }}
                    alt=""
                  />
                  <Typography fontWeight={700} fontSize={14}>
                    {eachAudit.fullname}
                  </Typography>
                  <Typography
                    fontStyle={"italic"}
                    fontWeight={400}
                    fontSize={14}
                  >
                    {eachAudit.action}
                  </Typography>
                  <Typography
                    fontWeight={400}
                    fontSize={14}
                    sx={{
                      marginLeft: "8px",
                    }}
                  >
                    {eachAudit.time}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      )} */}
    </Stack>
  );
}

export default ProfileSettings;
