import React from "react";
import { Link } from "react-router-dom";
import {
  Stack,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  CircularProgress,
} from "@mui/material";

import { ArrowLeft } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { isUpperCase } from "../../utils/helpers";
import useFetch from "../../helpers/useFetch";

const columns = [
  {
    id: "sn",
    label: "S/N",
  },
  {
    id: "role",
    label: "Roles",
    minWidth: 170,
  },
  {
    id: "user_count",
    label: "Users",
  },
  {
    id: "status",
    label: "Status",
  },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  a: {
    border: 0,
  },
  "td, th": {
    border: 0,
  },
}));

export default function UserRoles() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tableData, setTableData] = React.useState([]);
  const navigate = useNavigate();

  const { data, isLoading, refetch } = useFetch("api/identity-manager/roles/");

  React.useEffect(() => {
    if (!isLoading) {
      setTableData(data.data);
    }
  }, [isLoading, data]);

  const [searchRole, setSearchRole] = React.useState({
    role: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = [
    tableData?.map((item) => {
      return { ...item };
    }),
  ];

  console.log(rows, "roesssd");

  function SearchRoleChange(e) {
    setSearchRole({ ...searchRole, role: e });

    const filteredData = data?.data?.filter((item) => {
      return item.role.toLowerCase().includes(e.toLowerCase());
    });

    setTableData(filteredData);
  }

  return (
    <Stack gap={"23px"}>
      <Stack gap={"32px"}>
        <div className="flex items-center justify-between gap-4">
          <div
            className="flex items-center gap-1 cursor-pointer ml-6 w-fit"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft size="22" color="#000000" />
            <p>Back</p>
          </div>
        </div>
      </Stack>

      <Stack width={"100%"} marginTop={"20px"}>
        <Stack
          minHeight={"350px"}
          justifyContent={"flex-start"}
          sx={{
            background: "#fff",
            px: "22px",
            py: "20px",
            borderRadius: "16px",
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography
                        fontSize={13}
                        color={"#AEAEAE"}
                        variant="body2"
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <StyledTableRow>
                    <TableCell colSpan={columns?.length} align="center">
                      <div className="mt-10">
                        <CircularProgress />
                        <div>
                          <p className="text-center animate-pulse">
                            Loading...
                          </p>
                        </div>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ) : tableData.length === 0 ? (
                  <StyledTableRow>
                    <TableCell colSpan={columns.length} align="center">
                      <div className="pt-12">
                        <Typography
                          fontSize={14}
                          color={"#161616"}
                          variant="body2"
                        >
                          No roles found
                        </Typography>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  tableData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(`/user-management/manage-role/${row.id}`)
                            }
                          >
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              variant="body2"
                            >
                              {column?.id === "sn" ? (
                                index + 1 + "."
                              ) : column?.id == "role" ? (
                                <span
                                  className={`${
                                    isUpperCase(row[column?.id])
                                      ? "capitalize"
                                      : ""
                                  }`}
                                >
                                  {isUpperCase(row[column.id])
                                    ? row[column.id]
                                        ?.replace("-", " ")
                                        ?.toLowerCase()
                                    : row[column.id]?.replace("-", " ")}
                                </span>
                              ) : column?.id === "status" ? (
                                <span
                                  className={`${
                                    row?.system_role === true
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }`}
                                >
                                  {row?.system_role === true
                                    ? "Active"
                                    : "Inactive"}
                                </span>
                              ) : (
                                row[column.id]
                              )}
                            </Typography>
                          </TableCell>
                        ))}
                      </StyledTableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <div className="py-4 flex justify-center">
          <Pagination
            rowsPerPageOptions={[5, 25, 100]}
            count={Math.ceil(rows[0].length / rowsPerPage)}
            rowsPerPage={rowsPerPage}
            color="primary"
            page={page + 1}
            onChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            // onChange={handlePageChange}
          />
        </div>
      </Stack>
    </Stack>
  );
}
