import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import {
  dashboardIcon,
  userManagementIcon,
  userCardIcon,
  folderIcon,
  userSmall,
} from "../../assets";
import { Breadcrumb, PageIndicator, UserMgtCards } from "../../components";
import axios from "axios";
import {
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import useFetch from "../../helpers/useFetch";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import { error, success } from "../../common/Notifications";
import backgroundBlur from "../../assets/backgrounds/user-managament-count.svg";
import lightbg from "../../assets/backgrounds/light-bg-management.svg";
import lightbgtop from "../../assets/backgrounds/light-top-managament.svg";
import { Profile2User, Folder2 } from "iconsax-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isUpperCase } from "../../utils/helpers";

export default function UserManagement() {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const navigate = useNavigate();

  const currentUserRoles = useSelector((state) => state.users?.user?.roles);

  const [userList, setUser] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [requests, setRequests] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [itemID, setItemID] = React.useState("");

  const { data, isLoading, refetch } = useFetch("api/user/");
  const {
    data: dataRoles,
    isLoading: isLoadingRoles,
    refetch: refetchRoles,
  } = useFetch("api/identity-manager/roles/");
  // const {
  //   data: requestsData,
  //   isLoading: isLoadingRequests,
  //   refetch: refetchRequest,
  // } = useFetch("api/user/all_request_form");
  // console.log(requestsData);

  async function approveRequest(id) {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_API}api/user/approve_password_reset/`,
        { id: id },
        config
      );
      success(res.data.message);
      refetchRequest();
    } catch (err) {
      error(err.response.data.message);
      refetchRequest();
      console.log(err);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    if (!isLoading) {
      setUser(data.data);
    }

    if (!isLoadingRoles) {
      setRoles(dataRoles.data);
    }

    // if (!isLoadingRequests) {
    //   setRequests(requestsData.data);
    // }
  }, [
    isLoading,
    data,
    isLoadingRoles,
    dataRoles,
    // isLoadingRequests,
    // requestsData,
  ]);

  return (
    <Stack gap={"18px"}>
      <Stack gap={"32px"}>
        <div>
          <h2 className="text-gray-500 pl-4">
            <span
              className="cursor-pointer"
              onClick={() => navigate("/dashboard")}
            >
              Dashboard
            </span>
            / User Management
          </h2>
        </div>
      </Stack>
      <Stack
        className="flex flex-col xl:flex-row xl:items-stretch"
        gap={"16px"}
        marginTop={"20px"}
      >
        <Stack className="bg-white rounded-2xl overflow-hidden flex-1 pb-3">
          <Stack
            className="relative overflow-hidden bg-primary rounded-2xl"
            minWidth={"445px"}
            height={"256px"}
          >
            <div className="text-white flex justify-center items-center px-7 h-full relative z-10">
              <div className="flex justify-between items-start gap-2 w-full">
                <div>
                  <h2 className="text-2xl font-semibold pb-2">
                    Create New User
                  </h2>
                  <p>Add new user to your system</p>
                  <Button
                    onClick={() => navigate("/user-management/create-user")}
                    className="px-10 py-2 mt-8 rounded-lg bg-white text-primary-700 font-bold text-[15px] capitalize"
                  >
                    Create User
                  </Button>
                </div>
                <div className="flex items-end gap-1">
                  {isLoading ? (
                    <Icon icon="ep:loading" className="text-5xl animate-spin" />
                  ) : (
                    <h1 className="font-bold text-[72px] leading-none">
                      {userList?.length}
                    </h1>
                  )}

                  <span className="font-medium text-2xl">Users</span>
                </div>
              </div>
            </div>
            <img
              src={backgroundBlur}
              className="absolute -top-8 -left-2 -rotate-[32deg] opacity-70 h-[20rem]"
            />
            <Profile2User
              className="text-primary-700 absolute -bottom-16 -right-12 opacity-45"
              size="250"
              variant="Bold"
            />
          </Stack>
          <Stack className="px-6">
            <Stack marginTop={"26px"} marginBottom={"20px"}>
              <div className="flex justify-between items-center">
                <h2 className="font-semibold text-xl">Users</h2>
                <Link
                  to="/user-management/users"
                  className="font-medium text-sm"
                >
                  View all
                </Link>
              </div>
            </Stack>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    color="#AEAEAE"
                    sx={{
                      color: "#AEAEAE",
                      maxWidth: "10px",
                    }}
                  >
                    <TableCell
                      sx={{
                        color: "#AEAEAE",
                        fontSize: "13px",
                      }}
                    >
                      S/N
                    </TableCell>
                    <TableCell
                      color="#7e7e7e"
                      sx={{
                        color: "#AEAEAE",
                        fontSize: "13px",
                      }}
                    >
                      Full Name
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#AEAEAE",
                        fontSize: "13px",
                      }}
                    >
                      Email
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#AEAEAE",
                        fontSize: "13px",
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="center"
                        sx={{
                          py: "50px",
                          border: "none",
                        }}
                      >
                        <CircularProgress />
                        <p className="animate-pulse text-sm">Loading...</p>
                      </TableCell>
                    </TableRow>
                  ) : (
                    userList?.slice(0, 6)?.map((user, index) => (
                      <TableRow
                        key={user?.id}
                        hover
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/user-management/manage-user/${user?.id}`)
                        }
                      >
                        <TableCell
                          sx={{
                            border: "none",
                            maxWidth: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {index + 1}.
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {user?.fullname}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            border: "none",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {user?.email}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {user?.is_verified ? "Verified" : "Not Verified"}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
        <Stack className="bg-white rounded-2xl overflow-hidden flex-1 pb-4">
          <Stack
            className="bg-primary-700 relative overflow-hidden rounded-2xl"
            minWidth={"445px"}
            height={"256px"}
          >
            <div className="text-white flex justify-center items-center px-6 h-full relative z-10">
              <div className="flex justify-between items-start gap-2 w-full">
                <div>
                  <h2 className="text-2xl font-semibold pb-2">View Roles</h2>
                  <p>Check roles in your system</p>
                  <Button
                    onClick={() => navigate("/user-management/roles")}
                    className="px-10 py-2 mt-8 rounded-lg bg-white text-primary-700 font-bold text-[15px] capitalize"
                  >
                    View All Roles
                  </Button>
                </div>
                <div className="flex items-end gap-1">
                  {isLoadingRoles ? (
                    <Icon icon="ep:loading" className="text-5xl animate-spin" />
                  ) : (
                    <h1 className="font-bold text-[72px] leading-none">
                      {roles?.length}
                    </h1>
                  )}

                  <span className="font-medium text-2xl">Roles</span>
                </div>
              </div>
            </div>
            <img
              src={lightbgtop}
              className="absolute top-0 -left-2 -rotate-[32deg] opacity-70 "
            />
            <img
              src={lightbg}
              className="absolute bottom-8 -left-2 -rotate-[32deg] opacity-70 "
            />
            <Folder2
              className="text-primary-600 absolute -bottom-14 -right-10 opacity-45"
              size="250"
              variant="Bold"
            />
          </Stack>

          <Stack className="px-6">
            <Stack marginTop={"26px"} marginBottom={"20px"}>
              <div className="flex justify-between items-center">
                <h2 className="font-semibold text-xl">Roles</h2>
                <Link
                  to="/user-management/roles"
                  className="font-medium text-sm"
                >
                  View all
                </Link>
              </div>
            </Stack>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    color="#AEAEAE"
                    sx={{
                      color: "#AEAEAE",
                      maxWidth: "10px",
                    }}
                  >
                    <TableCell
                      sx={{
                        color: "#AEAEAE",
                        fontSize: "13px",
                      }}
                    >
                      S/N
                    </TableCell>
                    <TableCell
                      color="#7e7e7e"
                      sx={{
                        color: "#AEAEAE",
                        fontSize: "13px",
                      }}
                    >
                      Title
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: "#AEAEAE",
                        fontSize: "13px",
                      }}
                    >
                      Users
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#AEAEAE",
                        fontSize: "13px",
                      }}
                    >
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingRoles ? (
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        align="center"
                        sx={{
                          py: "50px",
                          border: "none",
                        }}
                      >
                        <CircularProgress />
                        <p className="animate-pulse text-sm">Loading...</p>
                      </TableCell>
                    </TableRow>
                  ) : (
                    roles?.slice(0, 6)?.map((role, index) => (
                      <TableRow
                        key={role?.id}
                        hover
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/user-management/manage-role/${role?.id}`)
                        }
                      >
                        <TableCell
                          sx={{
                            border: "none",
                            maxWidth: "10px",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {index + 1}.
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          <span
                            className={`${
                              isUpperCase(role?.role) ? "capitalize" : ""
                            }`}
                          >
                            {isUpperCase(role?.role)
                              ? role?.role?.replace("-", " ")?.toLowerCase()
                              : role?.role?.replace("-", " ")}
                          </span>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            border: "none",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {role?.user_count}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            fontSize: "14px",
                            fontWeight: 500,
                          }}
                        >
                          {role?.system_role ? "Verified" : "Not Verified"}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
