import React from 'react'
import { Stack, Typography } from '@mui/material'

export default function Page404() {
  return (
    <Stack width={'100vw'} height={'100vh'} justifyContent={'center'} alignItems={'center'}>
        <Typography variant='h2'>
            Error 404
        </Typography>
    </Stack>
  )
}
