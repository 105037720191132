import React from "react";
import {
  Stack,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Table,
  TableBody,
} from "@mui/material";

const similar = [
  {
    id: 1,
    title:
      "Airplane Technical Assistance to Recreational Aviation Collision with terrain involving Jabiru J230-C, registration 24-5067 on 18 September 2022",
    number: "NGA-AIB-003-2022",
    date: "22 Nov 2022",
    status: "Open",
  },
  {
    id: 2,
    title:
      "Airplane Technical Assistance to Recreational Aviation Collision with terrain involving Jabiru J230-C, registration 24-5067 on 18 September 2022",
    number: "NGA-AIB-003-2022",
    date: "22 Nov 2022",
    status: "Final",
  },
];

function SimilarOccurrence() {
  return (
    <div>
      <div>
        <p className="font-bold text-2xl">Similar Occurrence</p>
      </div>

      <div className="mt-6 bg-white p-8 rounded-xl shadow-md">
        <TableContainer component={Stack}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="px-8 py-6 rounded-t-lg">
              <TableRow>
                <TableCell>
                  <p className="text-sm ">Title</p>
                </TableCell>

                <TableCell>
                  <p className="text-sm ">Occurence Number</p>
                </TableCell>

                <TableCell>
                  <p className="text-sm ">Occurence Date</p>
                </TableCell>

                <TableCell>
                  <p className="text-sm ">Status</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="mt-10 bg-white px-8">
              {similar.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row" className="w-2/5">
                    <p className="font-bold text-sm ">{row.title}</p>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <p className="">{row.number}</p>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <p className="">{row.date}</p>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    <p
                      className={` ${
                        row.status === "Closed"
                          ? "text-error"
                          : row.status === "Final"
                          ? "text-primary"
                          : ""
                      }`}
                    >
                      {row.status}
                    </p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default SimilarOccurrence;
