import React from "react";
import { Link } from "react-router-dom";
import { Stack, Typography, Button, Dialog } from "@mui/material";
import { Alert, InputField } from "../../components";

import {
  dashboardIcon,
  userIconPrimary,
  folderPlusIcon,
  folderSmallPrimary,
} from "../../assets";
import { Input } from "./ManageProfile";
import {
  CustomCheckBox,
  CustomFormControlLabel,
} from "../../components/muiRestyled";
import { success, error, warning } from "../../common/Notifications";
import axios from "axios";
import { useSelector } from "react-redux";
import { ArrowLeft } from "iconsax-react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmDialog from "../../common/ConfirmDialog";
import DoneDialog from "../../common/DoneDialog";

const initialRoleState = {
  role: "",
  occurence_manager: {
    edit: false,
    view: false,
    print: false,
    create: false,
    delete: false,
    import: false,
  },
  user_management: {
    edit: false,
    view: false,
    print: false,
    create: false,
    delete: false,
    import: false,
    activate: false,
    deactivate: false,
  },
  media_library: {
    edit: false,
    view: false,
    print: false,
    create: false,
    delete: false,
    import: false,
  },
  system_role: false,
  tenancy: "AIBNIGERIA",
};

export default function CreateNewRole() {
  const navigate = useNavigate();
  const [formState, setFormState] = React.useState(initialRoleState);
  const lists = ["occurence_manager", "user_management", "media_library"];
  const [loading, setLoading] = React.useState(false);

  const accessToken = useSelector((state) => state.auth.accessToken);

  function handleFormChange(e, dictTop, dictLow, field) {
    const { checked } = e.target;
    setFormState((prev) => {
      if (dictLow) {
        let updatedFormState = {
          ...prev,
          [dictTop]: {
            ...prev[dictTop],
            [dictLow]: {
              ...prev[dictTop][dictLow],
              [field]: checked,
            },
          },
        };
        return updatedFormState;
      } else {
        let updatedFormState = {
          ...prev,
          [dictTop]: {
            ...prev[dictTop],
            [field]: checked,
          },
        };
        console.log(field, "field");
        return updatedFormState;
      }
    });
  }

  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  function initRoleCreate() {
    // Check if notthing is selected
    const occurence_selected_keys = Object.keys(
      Object.fromEntries(
        Object.entries(formState["occurence_manager"]).filter(
          ([, val]) => val == true
        )
      )
    );

    const user_selected_keys = Object.keys(
      Object.fromEntries(
        Object.entries(formState["user_management"]).filter(
          ([, val]) => val == true
        )
      )
    );

    const media_selected_keys = Object.keys(
      Object.fromEntries(
        Object.entries(formState["media_management"]).filter(
          ([, val]) => val == true
        )
      )
    );
    if (!formState.role) {
      warning("Please enter role name");
      return;
    }
    if (
      occurence_selected_keys.length == 0 &&
      user_selected_keys.length == 0 &&
      media_selected_keys.length == 0
    ) {
      warning("Please select at least one permission");
      return;
    }
    setOpenCreateDialog(true);
  }

  async function handleFormSubmit() {
    setLoading(true);

    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/identity-manager/roles/`,
        formState,
        config
      );
      // console.log(res);
      setOpenCreateDialog(false);
      setIsDone(true);
      // success(res.data.message);
      setLoading(false);
      setFormState(initialRoleState);
    } catch (err) {
      error(
        err.response?.data?.message
          ? err.response?.data?.message
          : err.response?.data?.status
      );
      setLoading(false);
    }
  }

  return (
    <Stack gap={"24px"}>
      <Alert
        display={false}
        iconClass={"bi bi-check-circle"}
        color={"#42BE65"}
        title={"Alert"}
        message={"Successful"}
      />
      <Dialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={false}
          confirmText="Create"
          title="Create New Role?"
          isLoading={loading}
          description="Are you sure you want to create this new role with these permissions? You can edit later."
          handleClose={() => setOpenCreateDialog(false)}
          handleConfirm={handleFormSubmit}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => setIsDone(false)}
      >
        <DoneDialog
          title="Role Successfully Created"
          description="You have successfully created this role."
        />
      </Dialog>
      <Stack marginBottom={"16px"}>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <Stack
        gap={"20px"}
        width={"100%"}
        borderRadius={"16px"}
        bgcolor={"#fff"}
        px={"24px"}
        py={"32px"}
      >
        <Stack width={"288px"} gap={"6px"}>
          <Typography variant="p" color={"#D8D8D8"} className="font-semibold">
            Role Assigned
          </Typography>
          <Input
            value={formState.role}
            onChange={(e) =>
              setFormState((prev) => ({
                ...prev,
                role: e.target.value,
              }))
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#D9D9D9",
                },

                "&:hover fieldset": {
                  borderColor: "#D9D9D9",
                },

                "&.Mui-focused fieldset": {
                  borderColor: "#D9D9D9",
                },
              },
            }}
            placeholder="Type in role title"
          />
        </Stack>
        <Stack>
          <Stack
            gap={"24px"}
            width={"100%"}
            className="pt-10 border-t lg:flex-row lg:divide-x divide-gray-300"
          >
            <Stack className="lg:w-[55%] pt-6 ">
              {lists?.map((occurence) => (
                <div key={occurence} className="mb-10 last:mb-0 ">
                  <h2 className="font-semibold text-[#D8D8D8] capitalize">
                    {occurence === "occurence_manager"
                      ? "Occurence Management"
                      : occurence === "media_library"
                      ? "Media Management"
                      : occurence?.split("_").join(" ")}
                  </h2>
                  <div className="border rounded-lg border-[#D9D9D9] py-4 px-4 mt-2 min-h-[8rem]">
                    {/* Get key of selected occurence(i.e value = true) */}
                    <ul className="flex flex-wrap gap-3">
                      {Object.keys(
                        Object.fromEntries(
                          Object.entries(formState[occurence]).filter(
                            ([, val]) => val == true
                          )
                        )
                      ).map((key) => (
                        <li key={key}>
                          <span className=" bg-[#F9F9F9] border py-1 px-3 xl:px-4 rounded flex items-center justify-between gap-1 capitalize font-medium text-sm xl:text-base">
                            <span>{key}</span>
                            <Icon
                              icon="iconamoon:close-fill"
                              title="close"
                              className="cursor-pointer"
                              onClick={() =>
                                handleFormChange(
                                  { target: { checked: false } },
                                  occurence,
                                  null,
                                  key?.toLowerCase()
                                )
                              }
                            />
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </Stack>
            <Stack className="lg:w-[45%] pt-6 pl-6">
              {lists.map((occurence) => (
                <div key={occurence} className="mb-10 lg:mb-[6.4rem] last:mb-0">
                  <h2 className="pb-3 font-semibold text-[#D8D8D8]">
                    Permissions
                  </h2>
                  <ul className="xl:px-10 grid grid-cols-3 gap-4">
                    <li>
                      <CustomFormControlLabel
                        control={
                          <CustomCheckBox
                            size="small"
                            checked={formState[occurence]?.view}
                            onChange={(e) =>
                              handleFormChange(e, occurence, null, "view")
                            }
                          />
                        }
                        label="View"
                      />
                    </li>
                    <li>
                      <CustomFormControlLabel
                        control={
                          <CustomCheckBox
                            size="small"
                            checked={formState[occurence]?.create}
                            onChange={(e) =>
                              handleFormChange(e, occurence, null, "create")
                            }
                          />
                        }
                        label="Create"
                      />
                    </li>
                    <li>
                      <CustomFormControlLabel
                        control={
                          <CustomCheckBox
                            size="small"
                            checked={formState[occurence]?.edit}
                            onChange={(e) =>
                              handleFormChange(e, occurence, null, "edit")
                            }
                          />
                        }
                        label="Edit"
                      />
                    </li>
                    <li>
                      <CustomFormControlLabel
                        control={
                          <CustomCheckBox
                            size="small"
                            checked={formState[occurence]?.delete}
                            onChange={(e) =>
                              handleFormChange(e, occurence, null, "delete")
                            }
                          />
                        }
                        label="Delete"
                      />
                    </li>
                    <li>
                      <CustomFormControlLabel
                        control={
                          <CustomCheckBox
                            size="small"
                            checked={formState[occurence]?.import}
                            onChange={(e) =>
                              handleFormChange(e, occurence, null, "import")
                            }
                          />
                        }
                        label="Import"
                      />
                    </li>
                    <li>
                      <CustomFormControlLabel
                        control={
                          <CustomCheckBox
                            size="small"
                            checked={formState[occurence]?.print}
                            onChange={(e) =>
                              handleFormChange(e, occurence, null, "print")
                            }
                          />
                        }
                        label="Print"
                      />
                    </li>
                  </ul>
                </div>
              ))}
            </Stack>
          </Stack>
        </Stack>

        <Stack marginTop={"24px"}>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            sx={{
              height: "45px",
              textTransform: "none",
              borderRadius: 0,
              "&.MuiButton-contained": {
                background: "#003163",
              },
            }}
            className="bg-primary rounded-xl px-16 w-fit font-bold"
            onClick={initRoleCreate}
          >
            {loading ? (
              <CircularProgress className="w-2 h-2 ml-2 text-white" />
            ) : (
              "Create Role"
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
