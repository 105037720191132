import React, { useState } from 'react'
import { Stack, Typography, Button } from '@mui/material'
import { Breadcrumb, InputField } from '../../components'
import { useNavigate } from 'react-router-dom'
import { fileIconPrimary, dashboardIcon, fileIconLight } from '../../assets'
import { Form } from '../user-management/ManageProfile'
import useFetch from '../../helpers/useFetch';
import { success, error } from '../../common/Notifications'
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios'
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import CustomTextField from '../../components/common/CustomTextField'
import CustomSelectField, { AutoCompleteField } from '../../components/common/CustomSelectField'
import useWebservice from '../../helpers/useWebservice'



export default function OccurenceDetails() {
    const navigate = useNavigate()
    const accessToken = useSelector(state => state.auth.accessToken);
    const [loading, setLoading] = React.useState(false)

    const [aircraftManufacturer, setAircraftManufacturer] = React.useState([])
    const [aircraftType, setAircraftType] = React.useState([])
    const [typeOfOperation, setTypeOfOperation] = React.useState([])
    const [aircraftDamage, setAircraftDamage] = React.useState([])
    const [locationData, setLocationData] = React.useState([])
    const [stateData, setStateData] = React.useState([])
    const [countryData, setCountryData] = React.useState([])
    const [flightPhaseData, setFlightPhaseData] = React.useState([])
    const [injuryData, setInjuryData] = React.useState([])
    const [airlineData, setAirlineData] = React.useState([])


    const { occurrenceId } = useParams()
    const { data, isLoading, refetch } = useFetch(`api/occurrence-manager/information/${occurrenceId}/`)
    const { data: dataAircraftManufacturer, isLoading: isLoadingAircraftManufacturer, refetch: refetchAircraftManufacturer } = useWebservice(`api/settings/aircraft-data/manufacturer/webtool_aircraft_manufacturer/`)
    const { data: dataAircrafttype, isLoading: isLoadingAircraftType, refetch: refetchAircraftType } = useWebservice(`api/settings/aircraft-data/aircraft/webtool_aircraft_type/`)
    const { data: dataOccurrenceOperation, isLoading: isLoadingOccurrenceOperation, refetch: refetchOccurrenceOperation } = useWebservice(`api/settings/occurrence/operations/webtool_occurrence_operations/`)
    const { data: dataDamage, isLoading: isLoadingDamage, refetch: refetchDamage } = useWebservice(`api/settings/aircraft-data/damages/webtool_aircraft_damage/`)
    const { data: dataLocation, isLoading: isLoadingLocation, refetch: refetchLocation } = useWebservice(`api/settings/location/city/webtool_location_city/`)
    const { data: dataState, isLoading: isLoadingState, refetch: refetchState } = useWebservice(`api/settings/location/state/webtool_location_state/`)
    const { data: dataCountry, isLoading: isLoadingCountry, refetch: refetchCountry } = useWebservice(`api/settings/location/country/webtool_location_country/`)
    const { data: dataFlightPhase, isLoading: isLoadingFlightPhase, refetch: refetchFlightPhase } = useWebservice(`api/settings/occurrence/flight-phase/webtool_occurrence_flight_phase/`)
    const { data: dataInjuryData, isLoading: isLoadingInjuryData, refetch: refetchInjuryData } = useWebservice(`api/settings/occurrence/injury/webtool_occurrence_injury/`)
    const { data: dataAirlines, isLoading: isLoadingAirlines, refetch: refetchAirlines } = useWebservice(`api/settings/datasets/airline/webtool_airline/`)



    const History = useNavigate()
    const [formState, manageFormState] = useState({})

    React.useEffect(() => {
        const stateMappings = [
            { isLoading: isLoading, setState: manageFormState, data: data?.data?.occurrence_details },
            { isLoading: isLoadingAircraftManufacturer, setState: setAircraftManufacturer, data: dataAircraftManufacturer.data },
            { isLoading: isLoadingAircraftType, setState: setAircraftType, data: dataAircrafttype.data },
            { isLoading: isLoadingOccurrenceOperation, setState: setTypeOfOperation, data: dataOccurrenceOperation.data },
            { isLoading: isLoadingDamage, setState: setAircraftDamage, data: dataDamage.data },
            { isLoading: isLoadingLocation, setState: setLocationData, data: dataLocation.data },
            { isLoading: isLoadingState, setState: setStateData, data: dataState.data },
            { isLoading: isLoadingCountry, setState: setCountryData, data: dataCountry.data },
            { isLoading: isLoadingFlightPhase, setState: setFlightPhaseData, data: dataFlightPhase.data },
            { isLoading: isLoadingInjuryData, setState: setInjuryData, data: dataInjuryData.data },
            { isLoading: isLoadingAirlines, setState: setAirlineData, data: dataAirlines.data },
        ];

        stateMappings.forEach(({ isLoading, setState, data }) => {
            if (!isLoading) {
                setState(data);
            }
        });
    }, [data, isLoading, dataAircraftManufacturer, isLoadingAircraftManufacturer, isLoadingAircraftType, dataAircrafttype, isLoadingOccurrenceOperation, dataOccurrenceOperation, isLoadingDamage, dataDamage, isLoadingLocation, dataLocation, isLoadingState, dataState, isLoadingCountry, dataCountry, isLoadingFlightPhase, dataFlightPhase, isLoadingInjuryData, dataInjuryData, isLoadingAirlines, dataAirlines]);


    const handleSubmit = async () => {
        console.log(formState)
        setLoading(true)

        try {
            const config = {
                headers: {
                    'Authorization': `JWT ${accessToken}`,
                    "Content-Type": "multipart/form-data"
                },
            }
            const res = await axios.put(`${process.env.REACT_APP_BASE_API}api/occurrence-manager/detail/${formState?.id}/`, formState, config);
            console.log(res)
            success(res.data?.message)
            setLoading(false)
            navigate(`/occurrence/manage-occurrence/${occurrenceId}`)
        } catch (err) {
            error(err.response?.data?.message)
            setLoading(false)
            console.log(err)
        }
    }
    return (
        <Stack gap={'32px'}>

            <Stack
                gap={'32px'}
                width={'100%'}
            >
                <Breadcrumb
                    rootLinkPath={'/dashboard'}
                    rootLink={'Dashboard'}
                    firstLinkPath={'/occurrence'}
                    rootLinkIcon={dashboardIcon}
                    firstLinkIcon={fileIconPrimary}
                    firstLink={'Occurrence'}
                    secondLink={data?.data?.title}
                    secondLinkPath={`/occurrence/manage-occurrence/${data?.data?.id}`}
                    secondLinkIcon={fileIconPrimary}
                    thirdLink={'Occurence Details'}
                />
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'} gap={'8px'} alignItems={'center'} bgcolor={'#003163'} px={'32px'} py={'18px'}>
                <img src={fileIconLight} alt="" />
                <Typography marginRight={'auto'} fontFamily={'PT Sans'} fontSize={16} color={'#fff'} fontWeight={700}>
                    {data?.data?.title}
                </Typography>

                <Button onClick={() => History(`/occurrence/manage-occurrence/${occurrenceId}`)} disableElevation variant='contained' sx={{
                    '&.MuiButton-contained': {
                        backgroundColor: 'none',
                        height: '41px',
                        fontFamily: 'PT Sans',
                        p: '14px 28px',
                        width: 'fit-content',
                        borderRadius: 0,
                        marginLeft: '24px',
                        border: '1px solid #fff',
                        textTransform: 'none',
                        fontSize: 14,
                        '&:hover': {
                            background: 'none'
                        }
                    }
                }}>
                    Back to Overview
                </Button>

                <Button onClick={handleSubmit} disableElevation variant='contained' sx={{
                    '&.MuiButton-contained': {
                        backgroundColor: '#fff',
                        height: '41px',
                        color: '#003163',
                        fontFamily: 'PT Sans',
                        p: '14px 28px',
                        width: 'fit-content',
                        borderRadius: 0,
                        marginLeft: '24px',
                        border: 0,
                        textTransform: 'none',
                        fontSize: 14,
                    }
                }}>
                    Update {loading && <CircularProgress className='w-2 h-2 ml-2 text-primary' />}
                </Button>
            </Stack>

            <Stack bgcolor={'#fff'} px={'32px'} pt={'14px'} pb={'70px'} borderRadius={'4px'} gap={'32px'}>
                <Stack width={'100%'} direction={'row'} paddingBottom={'16px'} borderBottom={'1px solid #CBCBCB'} gap={'64px'} alignItems={'flex-end'} justifyContent={'space-between'}>
                    <Typography fontSize={16} fontFamily={'PT Sans'} fontWeight={700} color={'#161616'}>
                        Occurrence Details
                    </Typography>
                </Stack>

                <Stack alignItems={'center'} px={'20px'} direction={'row'} justifyContent={'space-between'} gap={'24px'}>
                    <Stack flex={1} gap={'6px'}>
                        <Typography
                            variant='p'
                            fontSize={12}
                            fontFamily={'Pt Sans'}
                            color={'#646464'}
                        >
                            Registered Owner
                        </Typography>

                        <Form sx={{
                            '& .MuiOutlinedInput-root': {
                                fontSize: 14,
                                "& fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&:hover fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&.Mui-focused fieldset": {
                                    borderColor: '#b5b5b5',
                                }
                            }
                        }} fullWidth placeholder={'Registration owner name'} value={formState?.registered_owner} onChange={({ target }) => {
                            manageFormState({
                                ...formState,
                                registered_owner: target.value
                            })
                        }} />
                    </Stack>

                    {/* <CustomTextField label={`Registered Operator (Airline)`} placeholder={`Enter airline`} value={formState?.airline} onChange={(e) => {
                        console.log('here')
                        manageFormState((prevState) => ({
                            ...prevState,
                            airline: e.target.value
                        }))
                    }} /> */}
                    <AutoCompleteField value={formState?.airline} loading={isLoadingAirlines} onChange={(event, newValue) => {
                        manageFormState((prevState) => ({
                            ...prevState,
                            airline: newValue
                        }))
                    }} placeholder={'Choose an option'} label={`Registered Operator (Airline)`} data={airlineData} />
                </Stack>

                <Stack px={'20px'} alignItems={'center'} direction={'row'} justifyContent={'space-between'} gap={'24px'}>
                    <AutoCompleteField value={formState?.aircraft_manufacturer} loading={isLoadingAircraftManufacturer} onChange={(event, data) => {
                        manageFormState((prevState) => ({
                            ...prevState,
                            aircraft_manufacturer: data
                        }))
                    }} placeholder={'Choose an option'} label={'Aircraft Manufacturer'} data={aircraftManufacturer} />

                    <AutoCompleteField value={formState?.aircraft_type} loading={isLoadingAircraftType} onChange={(event, newValue) => {
                        manageFormState((prevState) => ({
                            ...prevState,
                            aircraft_type: newValue
                        }))
                    }} placeholder={'Choose an option'} label={'Aircraft Type'} data={aircraftType} />




                </Stack>

                <Stack px={'20px'} alignItems={'center'} direction={'row'} justifyContent={'space-between'} gap={'24px'}>
                    <Stack flex={1} gap={'6px'}>
                        <Typography
                            variant='p'
                            fontSize={12}
                            fontFamily={'Pt Sans'}
                            color={'#646464'}
                        >
                            Aircraft Model
                        </Typography>

                        <Form sx={{
                            '& .MuiOutlinedInput-root': {
                                fontSize: 14,
                                "& fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&:hover fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&.Mui-focused fieldset": {
                                    borderColor: '#b5b5b5',
                                }
                            }
                        }} fullWidth placeholder={'Aircraft model number'} value={formState?.aircraft_model} onChange={({ target }) => {
                            manageFormState({
                                ...formState,
                                aircraft_model: target.value
                            })
                        }} />
                    </Stack>

                    <Stack flex={1} gap={'6px'}>
                        <Typography
                            variant='p'
                            fontSize={12}
                            fontFamily={'Pt Sans'}
                            color={'#646464'}
                        >
                            Aircraft Registration Number
                        </Typography>

                        <Form sx={{
                            '& .MuiOutlinedInput-root': {
                                fontSize: 14,
                                "& fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&:hover fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&.Mui-focused fieldset": {
                                    borderColor: '#b5b5b5',
                                }
                            }
                        }} fullWidth placeholder={'Registration number'} value={formState?.aircraft_registration_number} onChange={({ target }) => {
                            manageFormState({
                                ...formState,
                                aircraft_registration_number: target.value
                            })
                        }} />
                    </Stack>
                </Stack>

                <Stack px={'20px'} alignItems={'center'} direction={'row'} justifyContent={'space-between'} gap={'24px'}>
                    <Stack flex={1} gap={'6px'}>
                        <Typography
                            variant='p'
                            fontSize={12}
                            fontFamily={'Pt Sans'}
                            color={'#646464'}
                        >
                            Aircraft Serial Number
                        </Typography>

                        <Form sx={{
                            '& .MuiOutlinedInput-root': {
                                fontSize: 14,
                                "& fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&:hover fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&.Mui-focused fieldset": {
                                    borderColor: '#b5b5b5',
                                }
                            }
                        }} fullWidth placeholder={'Serial Number'} value={formState?.aircraft_serial_number} onChange={({ target }) => {
                            manageFormState({
                                ...formState,
                                aircraft_serial_number: target.value
                            })
                        }} />
                    </Stack>
                    <AutoCompleteField loading={isLoadingOccurrenceOperation} onChange={(event, newValue) => {
                        manageFormState((prevState) => ({
                            ...prevState,
                            type_of_operation: newValue
                        }))
                    }} value={formState?.type_of_operation} placeholder={'Choose an option'} label={'Type of Operation'} data={typeOfOperation} />
                </Stack>

                <Stack px={'20px'} alignItems={'center'} direction={'row-reverse'} justifyContent={'space-between'} gap={'24px'}>
                    <Stack flex={1} gap={'6px'}>
                        <Typography
                            variant='p'
                            fontSize={12}
                            fontFamily={'Pt Sans'}
                            color={'#646464'}
                        >
                            Flight Destination
                        </Typography>

                        <Form sx={{
                            '& .MuiOutlinedInput-root': {
                                fontSize: 14,
                                "& fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&:hover fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&.Mui-focused fieldset": {
                                    borderColor: '#b5b5b5',
                                }
                            }
                        }} fullWidth value={formState?.flight_destination} placeholder={'Destination'} onChange={({ target }) => {
                            manageFormState({
                                ...formState,
                                flight_destination: target.value
                            })
                        }} />
                    </Stack>
                    <AutoCompleteField loading={isLoadingDamage} value={formState?.damage_to_aircraft} onChange={(event, newValue) => {
                        manageFormState((prevState) => ({
                            ...prevState,
                            damage_to_aircraft: newValue
                        }))
                    }} placeholder={'Choose an option'} label={'Damage to Aircraft'} data={aircraftDamage} />
                </Stack>

                <Stack px={'20px'} alignItems={'center'} direction={'row'} justifyContent={'space-between'} gap={'24px'}>
                    <Stack flex={1} gap={'6px'}>
                        <Typography
                            variant='p'
                            fontSize={12}
                            fontFamily={'Pt Sans'}
                            color={'#646464'}
                        >
                            Flight Departure
                        </Typography>

                        <Form sx={{
                            '& .MuiOutlinedInput-root': {
                                fontSize: 14,
                                "& fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&:hover fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&.Mui-focused fieldset": {
                                    borderColor: '#b5b5b5',
                                }
                            }
                        }} fullWidth placeholder={'Departure'} value={formState?.flight_departure} onChange={({ target }) => {
                            manageFormState({
                                ...formState,
                                flight_departure: target.value
                            })
                        }} />
                    </Stack>

                    <Stack flex={1} gap={'6px'}>
                        <Typography
                            variant='p'
                            fontSize={12}
                            fontFamily={'Pt Sans'}
                            color={'#646464'}
                        >
                            Total Airframe Time
                        </Typography>

                        <Form sx={{
                            '& .MuiOutlinedInput-root': {
                                fontSize: 14,
                                "& fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&:hover fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&.Mui-focused fieldset": {
                                    borderColor: '#b5b5b5',
                                }
                            }
                        }} fullWidth placeholder={'00:00'} value={formState?.total_airframe_time} onChange={({ target }) => {
                            manageFormState({
                                ...formState,
                                total_airframe_time: target.value
                            })
                        }} />
                    </Stack>
                </Stack>

                <Stack px={'20px'} alignItems={'center'} direction={'row'} justifyContent={'space-between'} gap={'24px'}>
                    <InputField value={formState?.certificate_of_airworthiness} onChange={(e) => {
                        const selectedDate = new Date(e);
                        const year = selectedDate.getFullYear();
                        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
                        const day = selectedDate.getDate().toString().padStart(2, '0');
                        const formattedDate = `${year}-${month}-${day}`;
                        manageFormState((prevState) => ({
                            ...prevState,
                            certificate_of_airworthiness: formattedDate
                        }))
                    }} border={true} fieldType={'date'} flex={1} placeholder={'Select a date'} label={'Certificate of Airworthiness Valid Till'} />

                    <InputField value={formState?.certificate_of_registration} onChange={(e) => {
                        const selectedDate = new Date(e);
                        const year = selectedDate.getFullYear();
                        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
                        const day = selectedDate.getDate().toString().padStart(2, '0');
                        const formattedDate = `${year}-${month}-${day}`;
                        manageFormState((prevState) => ({
                            ...prevState,
                            certificate_of_registration: formattedDate
                        }))
                    }} border={true} fieldType={'date'} flex={1} placeholder={'Select a date'} label={'Certificate of Registration Date Issued'} />
                </Stack>

                <Stack px={'20px'} alignItems={'center'} direction={'row'} justifyContent={'space-between'} gap={'24px'}>
                    <AutoCompleteField value={formState?.country} loading={isLoadingCountry} onChange={(event, newValue) => {
                        manageFormState((prevState) => ({
                            ...prevState,
                            country: newValue
                        }))
                    }} placeholder={'Choose an option'} label={'Country'} data={countryData} />

                    {/* <Stack flex={1} gap={'6px'}>
                        <Typography
                            variant='p'
                            fontSize={12}
                            fontFamily={'Pt Sans'}
                            color={'#646464'}
                        >
                            Location
                        </Typography>

                        <Form sx={{
                            '& .MuiOutlinedInput-root': {
                                fontSize: 14,
                                "& fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&:hover fieldset": {
                                    borderColor: '#b5b5b5',
                                },

                                "&.Mui-focused fieldset": {
                                    borderColor: '#b5b5b5',
                                }
                            }
                        }} fullWidth value={formState?.location} placeholder={'Occurrence location'} onChange={({ target }) => {
                            manageFormState({
                                ...formState,
                                location: target.value
                            })
                        }} />
                    </Stack> */}
                    <AutoCompleteField border={true} loading={isLoadingState} value={formState?.state} flex={1} onChange={(event, data) => {
                        manageFormState((prevState) => ({
                            ...prevState,
                            state: data
                        }))
                    }} placeholder={'Choose an option'} label={'State'} data={stateData} />
                </Stack>

                <Stack px={'20px'} alignItems={'center'} direction={'row'} justifyContent={'space-between'} gap={'24px'}>
                    <AutoCompleteField loading={isLoadingLocation} value={formState?.location} onChange={(event, data) => {
                        manageFormState((prevState) => ({
                            ...prevState,
                            location: data
                        }))
                    }} placeholder={'Choose an option'} label={'Location (City)'} data={locationData} />


                    <AutoCompleteField value={formState?.flight_phase} loading={isLoadingFlightPhase} onChange={(event, newData) => {
                        manageFormState((prevState) => ({
                            ...prevState,
                            flight_phase: newData
                        }))
                    }} placeholder={'Choose an option'} label={'Flight Phase'} data={flightPhaseData} />
                </Stack>

                <Stack px={'20px'} alignItems={'center'} direction={'row'} justifyContent={'space-between'} gap={'24px'}>
                    <AutoCompleteField value={formState?.high_injury_level} loading={isLoadingInjuryData} onChange={(event, newData) => {
                        manageFormState((prevState) => ({
                            ...prevState,
                            high_injury_level: newData
                        }))
                    }} placeholder={'Choose an option'} label={'Highest Injury Level'} data={injuryData} />

                    <Stack flex={1}>

                    </Stack>
                </Stack>




            </Stack>
        </Stack>
    )
}
