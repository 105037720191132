import React from "react";
import {
  Button,
  Stack,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { StyledTableRow } from "../../pages/user-data";
import { useNavigate } from "react-router-dom";
import { stakeholderColumns } from "../../components/dashboard/StakeholderHome";
import useFetch from "../../helpers/useFetch";

const StakeholderOccurrenceHome = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useFetch(
    "api/occurrence-manager/stakeholders/occurrence_by_stakeholders/"
  );

  const [unreviewedData, setUnreviewedData] = React.useState([]);
  React.useEffect(() => {
    if (data.data) {
      const unreviewedOccurrence = data.data.filter(
        (occurrence) => occurrence.is_reviewed === false
      );
      setUnreviewedData(unreviewedOccurrence);
    }
  }, [data]);

  return (
    <div>
      <TableContainer className="bg-white rounded-2xl py-6 px-8">
        <Table>
          <TableHead>
            <TableRow>
              {stakeholderColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography className="text-[#AEAEAE] font-medium text-sm">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <TableCell colSpan={stakeholderColumns?.length} align="center">
                  <div className="mt-10 mb-10">
                    <CircularProgress />
                  </div>
                </TableCell>
              </StyledTableRow>
            ) : unreviewedData?.length > 0 ? (
              unreviewedData?.map((item, index) => (
                <StyledTableRow key={index}>
                  {stakeholderColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      className="text-gray-900 font-medium text-sm"
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.id === "action" ? (
                        <div className="flex gap-4 items-center justify-center">
                          <Button
                            variant="contained"
                            className="bg-primary text-white text-sm shadow-none font-bold rounded-xl capitalize"
                            onClick={() => navigate(`/reviews/${item?.id}`)}
                          >
                            Review
                          </Button>
                          <Button
                            variant="outlined"
                            className="text-sm shadow-none font-bold rounded-xl capitalize"
                            onClick={() =>
                              navigate(
                                `/occurrence/manage-occurrence/${item?.occurrence?.id}`
                              )
                            }
                          >
                            Preview
                          </Button>
                        </div>
                      ) : (
                        item?.occurrence[column.id]
                      )}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan={stakeholderColumns?.length} align="center">
                  <div className="mt-10 mb-10 text-lg">
                    <p>No new occurrence added yet</p>
                  </div>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default StakeholderOccurrenceHome;
