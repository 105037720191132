import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

function CustomSelectField({ value, label, data, placeholder, onChange }) {
  return (
    <div className="flex-1 w-full">
      <p className=" text-xs text-secondary mb-2">{label}</p>
      <Select
        // defaultValue={value}
        // defaultValue=""
        value={
          value === undefined || value === null || data?.length === 0
            ? ""
            : value
        }
        onChange={onChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        variant="filled"
        fullWidth
        placeholder={placeholder}
        className="text-sm"
      >
        <MenuItem value="">
          <p className="text-sm text-secondary ">{placeholder}</p>
        </MenuItem>
        {data?.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            className="text-secondary  text-sm"
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

function AutoCompleteField({
  label,
  placeholder,
  data,
  value,
  onChange,
  loading,
  defaultValue,
}) {
  console.log(
    data?.filter((obj) => obj?.value === value)[0],
    "Value printed out"
  );

  return (
    <div className="flex-1 w-full">
      <p className=" text-xs text-secondary mb-2">{label}</p>
      <Autocomplete
        fullWidth
        disablePortal
        id={label}
        loading={loading}
        value={{ value: value || "" }}
        onInputChange={onChange}
        getOptionLabel={(option) => option.value}
        options={data || []}
        className=""
        renderInput={(params) => (
          <TextField
            variant="filled"
            {...params}
            placeholder={placeholder}
            fullWidth
            className="text-secondary"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            value={value || ""}
          />
        )}
      />
    </div>
  );
}

function CustomSelectField2({ value, label, data, placeholder, onChange }) {
  return (
    <div className="flex-1 w-full">
      <p className=" text-xs text-secondary mb-2">{label}</p>
      <Select
        // defaultValue={value}
        // defaultValue=""
        value={
          value === undefined || value === null || data.length === 0
            ? ""
            : value
        }
        onChange={onChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        variant="filled"
        fullWidth
        placeholder={placeholder}
        className="text-sm"
      >
        <MenuItem value="">
          <p className="text-sm text-secondary ">{placeholder}</p>
        </MenuItem>
        {value &&
          data?.map((item, index) => (
            <MenuItem
              key={index}
              value={item.value}
              className="text-secondary  text-sm"
            >
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
}

export default CustomSelectField;
export { CustomSelectField2, AutoCompleteField };
