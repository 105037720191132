import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";

import { ArrowLeft } from "iconsax-react";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../user-management/ManageProfile";
import { error, success } from "../../common/Notifications";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setTitle } from "../../redux/slices/users/titleSlice";
import useFetch from "../../helpers/useFetch";

const AddIcao = () => {
  const navigate = useNavigate();
  const { occurrenceId } = useParams();
  const dispatch = useDispatch();

  const { data: occurrenceDetail, isLoading: isLoadingOccurrence } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/`
  );

  React.useEffect(() => {
    dispatch(
      setTitle({
        isLoading: isLoadingOccurrence,
        title: occurrenceDetail?.data?.occurrence_report_status,
      })
    );

    return () => {
      dispatch(setTitle({ isLoading: false, title: "" }));
    };
  }, [occurrenceDetail, isLoadingOccurrence]);

  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
  });

  const accessToken = useSelector((state) => state.auth.accessToken);
  const [loading, setLoading] = React.useState(false);

  async function handleSubmit() {
    if (!formData.name || !formData.email || !formData.phone) {
      error("Please fill in all required fields");
      return;
    }
    if (formData.phone.length !== 11) {
      error("Please enter a valid phone number");
      return;
    }

    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "Application/json",
          Authorization: `JWT ${accessToken}`,
        },
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/icao/`,
        { ...formData, occurrence_id: occurrenceId },
        config
      );
      setFormData({
        name: "",
        email: "",
        phone: "",
      });
      success(res?.data?.message);
    } catch (err) {
      error(err.response.data.message);
    }

    setLoading(false);
  }

  return (
    <Stack gap={"40px"}>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <Stack className="bg-white rounded-2xl px-12 py-6">
        <div className="flex  gap-12">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Name
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.name}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  name: target.value,
                }));
              }}
              placeholder="ICAO Name"
            />
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Email
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.email}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  email: target.value,
                }));
              }}
              placeholder="ICAO Email"
            />
          </Stack>
        </div>
        <Stack className="pr-6 mt-10" gap={"6px"} width={"50%"}>
          <Typography variant="p" color={"#D8D8D8"}>
            Phone Number
          </Typography>
          <Input
            sx={{
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "#D9D9D9",
                },

                "&.Mui-focused fieldset": {
                  borderColor: "#003163",
                },
              },
            }}
            fullWidth
            value={formData?.phone}
            onChange={({ target }) => {
              setFormData((prev) => ({
                ...prev,
                phone: target.value,
              }));
            }}
            placeholder="ICAO Phone Number"
          />
        </Stack>

        <div className="flex justify-end mt-10">
          <Button
            variant="contained"
            className="px-10 py-2.5 bg-primary rounded-xl font-bold text-sm capitalize text-white shadow-none"
            disabled={loading}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress size={20} className="text-white" />
            ) : (
              "Save ICAO"
            )}
          </Button>
        </div>
      </Stack>
    </Stack>
  );
};

export default AddIcao;
