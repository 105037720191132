import React, { useState, useRef, useEffect } from "react";
import {
  Stack,
  Typography,
  styled,
  TextField,
  Select,
  MenuItem,
  Button,
  Dialog,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Alert, DialogComponent } from "../../components";
import useFetch from "../../helpers/useFetch";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { success, error } from "../../common/Notifications";
import { ArrowLeft } from "iconsax-react";
import profileImg from "../../assets/emojis/girl-with-nose-ring.svg";
import { useSelector } from "react-redux";
import Loading from "../../common/Loading";
import ConfirmDialog from "../../common/ConfirmDialog";
import DoneDialog from "../../common/DoneDialog";
import { stakeholderCategories } from "../../components/occurrence/manager-edits/StakeholderPermission";
import CustomSelect from "../../components/common/CustomSelect";

export const Form = styled(TextField)((theme) => ({
  border: "none",
  "& .MuiOutlinedInput-root": {
    alignItems: "flex-start",
    height: 48,
    color: "#000000 !important",

    "& fieldset": {
      border: "none",
      border: "1px solid",
      borderColor: "#b5b5b5",
      fontSize: 14,
    },

    "&:hover fieldset": {
      border: "1px solid",
      borderColor: "#b5b5b5",
    },

    "&:disabled .MuiTextField-root:disabled fieldset": {
      borderBottom: "1px solid",
      borderColor: "#b5b5b5",
    },

    "&.Mui-focused fieldset": {
      borderBottom: "1px solid",
      borderColor: "#b5b5b5",
    },
  },
}));

export const Input = styled((props) => (
  <TextField InputProps={{ disableunderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    overflow: "hidden",
    borderRadius: "8px",
    backgroundColor: "transparent",
    border: "1px solid",
    color: "#000000",
    outline: "none",
    borderColor: "#D9D9D9",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
      borderColor: theme.palette.primary.main,
    },
  },
  "& .MuiInputBase-root": {
    fontWeight: 600,
    borderRadius: 8,
  },
  "& .Mui-disabled": {
    color: "#acacac",
    "-webkit-text-fill-color": "#acacac !important",
  },
}));

export default function ManageUser() {
  const { data: aircraftManufacturerData, isLoading: manufacturerIsLoading } =
    useFetch(`api/settings/manufacturer/`);
  const { data: aircraftTypesData, isLoading: aircraftTypesIsLoading } =
    useFetch(`api/settings/airline/`);

  const navigate = useNavigate();
  const [formState, setFormState] = useState(true);

  const currentUserRoles = useSelector((state) => state.users?.user?.roles);
  const [userRoles, setUserRoles] = useState([]);
  const { id } = useParams();
  const {
    data: data,
    isLoading: isLoading,
    refetch: refetch,
  } = useFetch(`api/user/${id}/`);
  const { data: roleData, isLoading: roleIsLoading } = useFetch(
    `api/identity-manager/select-roles/`
  );
  const [updateFormState, setUpdateFormState] = useState({});
  const [loading, setLoading] = React.useState(false);

  const accessToken = useSelector((state) => state.auth.accessToken);

  React.useEffect(() => {
    if (!isLoading) {
      setUpdateFormState(data?.data);
    }
  }, [data, isLoading]);

  React.useEffect(() => {
    if (!roleIsLoading) {
      setUserRoles(roleData?.data);
    }
  }, [roleData, roleIsLoading]);

  const [subCategories, setSubCategories] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [shouldShowSubCategory, setShouldShowSubCategory] = useState(false);
  const [shouldShowCategory, setShouldShowCategory] = useState(false);
  const [allStakeholder, setAllStakeholder] = useState([]);
  React.useEffect(() => {
    if (stakeholderCategories) {
      setAllStakeholder([
        ...stakeholderCategories,
        { label: "Manufacturer", value: "MANUFACTURER" },
        { label: "Airline", value: "AIRLINE" },
      ]);
    }
  }, [stakeholderCategories]);

  // React.useEffect(() => {
  //   if (!data?.data) {
  //     console.log("data is empty");
  //     return;
  //   }
  //   console.log(data);
  //   // CHECK FOR SUB CATEGORY
  //   if (updateFormState?.roles) {
  //     setSelectedRole(
  //       roleData?.data?.find((role) => role?.id == updateFormState?.roles)
  //     );
  //   }
  //   if (updateFormState.code) {
  //     if (
  //       ((data?.data?.code || data?.data?.code == 0) &&
  //         updateFormState?.code?.toUpperCase() === "MANUFACTURER") ||
  //       updateFormState?.code?.toUpperCase() === "AIRLINE"
  //     ) {
  //       if (updateFormState?.code?.toUpperCase() === "MANUFACTURER") {
  //         setSubCategories(aircraftManufacturerData?.data);
  //       } else if (updateFormState?.code?.toUpperCase() === "AIRLINE") {
  //         setSubCategories(aircraftTypesData?.data);
  //       } else {
  //         setSubCategories([]);
  //       }
  //       setShouldShowSubCategory(true);
  //     } else {
  //       setShouldShowSubCategory(false);
  //       setUpdateFormState((prev) => ({ ...prev, sub_code: 0 }));
  //     }
  //   }

  //   // CHECK FOR CATEGORY
  //   if (data.data?.roles && selectedRole?.value == "STAKEHOLDER") {
  //     setShouldShowCategory(true);
  //   } else {
  //     setShouldShowCategory(false);
  //     setUpdateFormState((prev) => ({ ...prev, code: 0 }));
  //   }
  //   // else if(updateFormState.role && updateFormState?.role != "STAKEHOLDER"){

  //   // }

  //   // SET SELCETED CATEGORY
  //   if (updateFormState?.code) {
  //     const category = allStakeholder.find(
  //       (item) =>
  //         item.value?.toUpperCase() == updateFormState?.code?.toUpperCase()
  //     );
  //     setUpdateFormState((prev) => ({
  //       ...prev,
  //       code_name: category?.label,
  //     }));
  //   }

  //   // SET SELECTED SUB CATEGORY
  //   if (updateFormState?.sub_code) {
  //     const subCategory = subCategories?.find(
  //       (item) => item.id == updateFormState?.sub_code
  //     );
  //     setUpdateFormState((prev) => ({
  //       ...prev,
  //       sub_code_name: subCategory?.name,
  //     }));
  //   }
  // }, [updateFormState, data, allStakeholder, subCategories, selectedRole]);

  React.useEffect(() => {
    // Check if data is empty
    if (!data?.data) {
      return;
    }

    console.log(data);

    // Check for and set selected role
    if (updateFormState?.roles) {
      const foundRole = roleData?.data?.find(
        (role) => role?.id == updateFormState?.roles
      );
      if (foundRole) setSelectedRole(foundRole);
    }
  }, [updateFormState?.roles, roleData, data]);

  React.useEffect(() => {
    if (!data?.data) return;
    // Set sub-categories based on code
    if (updateFormState.code) {
      console.log("data", data?.data?.code);
      if (
        (data?.data?.code || data?.data?.code == null) &&
        ["MANUFACTURER", "AIRLINE"].includes(updateFormState.code)
      ) {
        setShouldShowSubCategory(true);
        if (updateFormState.code === "MANUFACTURER") {
          setSubCategories(aircraftManufacturerData?.data || []);
        } else if (updateFormState.code === "AIRLINE") {
          setSubCategories(aircraftTypesData?.data || []);
        } else {
          setSubCategories([]);
        }
      } else {
        setShouldShowSubCategory(false);
        setUpdateFormState((prev) => ({ ...prev, sub_code: null }));
        setUpdateFormState((prev) => ({ ...prev, sub_code_name: "" }));
      }
    }
  }, [updateFormState.code, aircraftManufacturerData, aircraftTypesData, data]);

  React.useEffect(() => {
    if (!data?.data?.roles || !selectedRole) {
      return;
    }
    // Check and show/hide category
    if (data?.data?.roles && selectedRole?.value === "STAKEHOLDER") {
      setShouldShowCategory(true);
    } else {
      setShouldShowCategory(false);
      setShouldShowSubCategory(false);
      setUpdateFormState((prev) => ({ ...prev, sub_code: null }));
      setUpdateFormState((prev) => ({ ...prev, sub_code_name: "" }));
      setUpdateFormState((prev) => ({ ...prev, code: null }));
      setUpdateFormState((prev) => ({ ...prev, code_name: "" }));
    }
  }, [selectedRole, data]);

  React.useEffect(() => {
    // Set the selected category
    if (updateFormState?.code) {
      const category = allStakeholder?.find(
        (item) =>
          item.value?.toUpperCase() === updateFormState?.code?.toUpperCase()
      );
      setUpdateFormState((prev) => ({
        ...prev,
        code_name: category?.label,
      }));
    }
  }, [updateFormState?.code, allStakeholder]);

  React.useEffect(() => {
    // Set the selected sub-category
    if (updateFormState?.sub_code) {
      const subCategory = subCategories?.find(
        (item) => item.id == updateFormState?.sub_code
      );
      setUpdateFormState((prev) => ({
        ...prev,
        sub_code_name: subCategory?.name,
      }));
    }
  }, [updateFormState?.sub_code, subCategories]);

  const [confirmEditUser, setConfirmEditUser] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  async function handleFormSubmit() {
    if (
      !updateFormState?.first_name ||
      !updateFormState?.last_name ||
      !updateFormState?.email ||
      !updateFormState?.roles ||
      !updateFormState.phone_number
    ) {
      error("All fields are required");
      return;
    }
    if (updateFormState?.phone_number?.length !== 11) {
      error("Phone number should be 11 digits");
      return;
    }
    if (updateFormState.roles === "STAKEHOLDER" && !updateFormState.code) {
      error("Please select stakeholder category");
      return;
    }
    if (
      (updateFormState.code?.toUpperCase() == "MANUFACTURER" ||
        updateFormState.code?.toUpperCase() == "AIRLINE") &&
      !updateFormState.sub_code
    ) {
      error("Please select stakeholder sub category");
      return;
    }
    if (updateFormState.roles === "STAKEHOLDER") {
      setUpdateFormState((prev) => ({ ...prev, user_type: "STAKEHOLDER" }));
    }
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      await axios.put(
        `${process.env.REACT_APP_BASE_API}api/user/${id}/`,
        updateFormState,
        config
      );
      setConfirmEditUser(false);
      setIsDone(true);
      setFormState(true);
    } catch (err) {
      error(err?.response?.data?.message);
      console.log(err);
    }
    setLoading(false);
  }

  const [dialogState, setDialogState] = React.useState(false);

  const [activateUserDialogState, setActivateUserDialogState] =
    React.useState(false);

  function showDialog() {
    setDialogState(!dialogState);
  }

  function showActivateUserDialog() {
    setActivateUserDialogState(true);
  }

  function hideActivateUserDialog() {
    setActivateUserDialogState(false);
  }

  function hideDialog() {
    setDialogState(false);
  }

  async function deactivateUser() {
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_API}api/user/${id}/deactivate_user/`,
        updateFormState,
        config
      );
      success(res?.data?.message);
      setLoading(false);
      refetch();
      setDialogState(false);
    } catch (err) {
      error(err?.response?.data?.message);
      console.log(err);
      setDialogState(false);
    }
  }

  async function activateUser() {
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      const res = await axios.put(
        `${process.env.REACT_APP_BASE_API}api/user/${id}/activate_user/`,
        updateFormState,
        config
      );
      success(res?.data?.message);
      setLoading(false);
      refetch();
      setActivateUserDialogState(false);
    } catch (err) {
      error(err?.response?.data?.message);
      setActivateUserDialogState(false);
    }
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Stack gap={"24px"}>
      <Dialog
        open={confirmEditUser}
        onClose={() => setConfirmEditUser(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "450px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={false}
          confirmText="Edit"
          title="Edit User?"
          isLoading={loading}
          description="Are you sure you want to edit this user with these details?"
          handleClose={() => setConfirmEditUser(false)}
          handleConfirm={handleFormSubmit}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => {
          setIsDone(false), navigate(-1);
        }}
      >
        <DoneDialog
          title="User Successfully Editted"
          description="You have successfully editted this user."
        />
      </Dialog>
      <DialogComponent
        title={"Deactivate User"}
        primaryActionBtnColor={"#DA1E28"}
        primaryActionBtnText={"Yes"}
        secondaryActionBtnColor={"#003163"}
        secondaryActionBtnText={"No"}
        content={"Are you sure you want to deactivate this user profile?"}
        dialogState={dialogState}
        secondaryBtnAction={hideDialog}
        primaryBtnAction={deactivateUser}
        handleClose={hideDialog}
      />

      <DialogComponent
        title={"Activate User"}
        primaryActionBtnColor={"#003163"}
        primaryActionBtnText={"Yes"}
        secondaryActionBtnColor={"#003163"}
        secondaryActionBtnText={"No"}
        content={"Are you sure you want to activate this user profile?"}
        dialogState={activateUserDialogState}
        secondaryBtnAction={hideActivateUserDialog}
        primaryBtnAction={activateUser}
        handleClose={hideActivateUserDialog}
      />
      <Alert
        display={false}
        iconClass={"bi bi-check-circle"}
        color={"#42BE65"}
        title={"Alert"}
        message={"Successful"}
      />

      <Stack marginBottom={"16px"}>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>

      <Stack
        px={"36px"}
        py={"40px"}
        marginBottom={"36px"}
        sx={{
          background: "#fff",
          borderRadius: "16px",
        }}
      >
        <div className="font-semibold text-xl border-b pb-3">
          <h2>User Setting</h2>
        </div>
        <Stack
          width={"100%"}
          columnGap={"40px"}
          alignItems={"center"}
          marginTop={"24px"}
          sx={{
            alignItems: "center",
            paddingRight: "40px",
            paddingBottom: "40px",
            justifyContent: "space-between",
          }}
          rowGap={"16px"}
          direction={"row"}
        >
          <Stack width={"70%"} minWidth={"70%"} gap={"18px"}>
            <Stack width={"100%"} direction={"row"} gap={"48px"}>
              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  First Name
                </Typography>

                <Input
                  disabled={formState}
                  variant="outlined"
                  fullWidth
                  placeholder={"First Name"}
                  value={updateFormState?.first_name}
                  onChange={({ target }) => {
                    setUpdateFormState((prev) => ({
                      ...prev,
                      first_name: target.value,
                    }));
                  }}
                />
              </Stack>

              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Last Name
                </Typography>

                <Input
                  disabled={formState}
                  value={updateFormState?.last_name}
                  fullWidth
                  placeholder={"Idris"}
                  onChange={({ target }) => {
                    setUpdateFormState((prev) => ({
                      ...prev,
                      last_name: target.value,
                    }));
                  }}
                />
              </Stack>
            </Stack>

            <Stack width={"100%"} direction={"row"} gap={"48px"}>
              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Email Address
                </Typography>

                <Input
                  disabled={true}
                  fullWidth
                  placeholder={"kamali@aib.ng.com"}
                  value={updateFormState?.email}
                  onChange={({ target }) => {
                    setUpdateFormState((prev) => ({
                      ...prev,
                      email: target.value,
                    }));
                  }}
                />
              </Stack>

              <Stack width={"100%"} gap={"6px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Phone Number
                </Typography>

                <Input
                  disabled={formState}
                  fullWidth
                  type="number"
                  placeholder={"081 504 044 0405"}
                  value={updateFormState?.phone_number}
                  onChange={({ target }) => {
                    setUpdateFormState((prev) => ({
                      ...prev,
                      phone_number: target.value,
                    }));
                  }}
                />
              </Stack>
            </Stack>
            <Stack width={"100%"} direction={"row"} gap={"48px"}>
              <Stack className="flex-1" gap={"8px"}>
                <Typography variant="p" color={"#D8D8D8"}>
                  Role Profile
                </Typography>

                <FormControl>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={updateFormState.roles || ""}
                    onChange={(e) => {
                      setUpdateFormState((prevState) => ({
                        ...prevState,
                        roles: e.target.value,
                      }));
                    }}
                    disabled={formState}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="rounded-lg font-semibold"
                  >
                    <MenuItem value="">
                      <Typography fontSize={14} color={"inherit"}>
                        Select Role
                      </Typography>
                    </MenuItem>
                    {userRoles?.map((eachItem) => (
                      <MenuItem key={eachItem.id} value={eachItem.id}>
                        <Typography fontSize={14} color={"inherit"}>
                          {eachItem.value}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Stack className="flex-1" gap={"8px"}>
                {shouldShowCategory && (
                  <>
                    <Typography variant="p" color={"#D8D8D8"}>
                      Stakeholder Category
                    </Typography>
                    <FormControl sx={{ minWidth: 120 }}>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={updateFormState.code}
                        onChange={(e) => {
                          setUpdateFormState((prevState) => ({
                            ...prevState,
                            code: e.target.value,
                            sub_code: "",
                          }));
                        }}
                        disabled={formState}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className="rounded-lg font-semibold"
                      >
                        <MenuItem value="">
                          <Typography color={"inherit"} fontWeight={600}>
                            Select Category
                          </Typography>
                        </MenuItem>
                        {allStakeholder?.map((eachItem) => (
                          <MenuItem key={eachItem.id} value={eachItem.value}>
                            <Typography color={"inherit"} fontWeight={600}>
                              {eachItem.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </Stack>
            </Stack>
            {shouldShowSubCategory && (
              <div className="max-w-[50%] pr-6">
                <Stack className="flex-1" gap={"8px"}>
                  <Typography variant="p" color={"#D8D8D8"}>
                    Sub Category
                  </Typography>
                  <CustomSelect
                    value={updateFormState.sub_code}
                    searchValue="value"
                    value_attribute="id"
                    list={subCategories || []}
                    isLoading={aircraftTypesIsLoading || manufacturerIsLoading}
                    maxHeight="max-h-[20rem]"
                    isTop
                    disabled={formState}
                    placeholder="Select Category"
                    onChange={(e) => {
                      setUpdateFormState((prevState) => ({
                        ...prevState,
                        sub_code: e,
                      }));
                    }}
                  />
                </Stack>
              </div>
            )}
          </Stack>

          <Stack alignSelf={"flex-center"} gap={"20px"}>
            <div className="w-[184px] h-[184px] bg-red-500 rounded-full grid place-content-center">
              <img src={profileImg} width={"124px"} height={"124px"} alt="" />
            </div>
          </Stack>
        </Stack>
        <Stack direction={"row"} gap={"20px"}>
          {!formState && (
            <Button
              variant="contained"
              className="bg-gray-500 text-white capitalize rounded-2xl text-sm font-bold py-2 px-14 w-fit shadow-none"
              onClick={() => setFormState(true)}
            >
              Discard
            </Button>
          )}
          <Button
            variant="contained"
            className="bg-primary text-white capitalize rounded-2xl text-sm font-bold py-2 px-16 w-fit shadow-none"
            onClick={() =>
              formState ? setFormState(false) : setConfirmEditUser(true)
            }
          >
            {formState ? "Edit" : "Save"}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
