import React from 'react'
import { Link } from 'react-router-dom'
import { Stack, Typography, Checkbox } from '@mui/material'
export default function LoginAdditionalOptions({ forgotPasswordRoute }) {

    return (
        <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
                <Checkbox sx={{
                    p: 0,
                    color: '#646464',
                    '& .MuiSvgIcon-root': { fontSize: 20 },
                    '&.Mui-checked': { color: '#003163' }
                }} />
                <Typography variant='body2' sx={{
                    fontSize: 12,
                    color: '#646464',
                }}>
                    Keep me logged in
                </Typography>
            </Stack>

            <Link style={{ textDecoration: 'none' }} to={forgotPasswordRoute}>
                <Typography sx={{
                    borderBottom: '1px solid #003163',
                    paddingBottom: '-1px'
                }} variant='body2' fontSize={12} color={'#003163'}>
                    Forgot your password?
                </Typography>
            </Link>
        </Stack>
    )
}
