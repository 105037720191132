import {
  Stack,
  Button,
  CircularProgress,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import React from "react";

import { createButton } from "react-simple-wysiwyg";

import {
  TextBold,
  TextItalic,
  TextalignCenter,
  TextalignLeft,
  TextalignRight,
  TextUnderline,
} from "iconsax-react";
import { useSelector } from "react-redux";
import { error, success } from "../../common/Notifications";
import axios from "axios";
import Editor from "../../common/Editor";
import { severityList } from "./section-owner/SectionForm";

export const BtnAlignCenter = createButton(
  "Align center",
  <TextalignCenter color="#A2A2A2" variant="Bold" />,
  "justifyCenter"
);
export const BtnAlignLeft = createButton(
  "Align left",
  <TextalignLeft color="#A2A2A2" variant="Bold" />,
  "justifyLeft"
);
export const BtnAlignRight = createButton(
  "Align right",
  <TextalignRight color="#A2A2A2" variant="Bold" />,
  "justifyRight"
);
export const BtnItalic = createButton(
  "Italic",
  <TextItalic color="#A2A2A2" variant="Bold" />,
  "italic"
);
export const BtnBold = createButton(
  "Bold",
  <TextBold color="#A2A2A2" variant="Bold" />,
  "bold"
);
export const BtnUnderline = createButton(
  "Underline",
  <TextUnderline color="#A2A2A2" variant="Bold" />,
  "underline"
);

const Synopsis = ({
  occurrenceId,
  information,
  isPreview,
  occurrenceOwner,
  goToNext,
  refetch,
}) => {
  const [value, setValue] = React.useState({
    content: "",
    severity: "",
  });
  React.useEffect(() => {
    if (information) {
      const section = information?.occurrence_section_group?.find(
        (group) => group?.section_name === "EXECUTIVE SUMMARY"
      );
      if (
        section &&
        section.occurence_section &&
        section.occurence_section.length > 0
      ) {
        setValue({
          content:
            section.occurence_section[0]?.occurrence_page_section[0]?.content,
          severity: section.occurence_section[0]?.severity,
        });
      }
    }
  }, []);
  const user = useSelector((state) => state.users.user);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [userIsOccurenceManager, setUserIsOccurenceManager] =
    React.useState(false);

  React.useEffect(() => {
    if (user) {
      if (user?.roles?.role?.toLowerCase()?.includes("occurence-manager/iic")) {
        setUserIsOccurenceManager(true);
      }
    }
  }, [user]);

  const [loading, setLoading] = React.useState(false);
  async function handleSubmit() {
    if (!value.content || !value.severity) {
      error("Please fill all the fields");
      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      const body = {
        section_name: "Synopsis",
        occurrence: occurrenceId,
        section_group_name: "EXECUTIVE SUMMARY",
        section_owner: occurrenceOwner,
        content: value.content,
        severity: value.severity,
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/`,
        body,
        config
      );
      success("Occurrence Details updated successfully");
      goToNext();
      refetch();
    } catch (err) {
      error(err.response?.data?.message);
    }
    setLoading(false);
  }

  return (
    <Stack gap={"32px"}>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Synopsis</h2>
      </div>

      <div className="bg-white py-6 px-6 rounded-2xl">
        <Stack gap={"6px"} marginBottom={"32px"}>
          <Typography variant="p" color={"#D8D8D8"}>
            Severity
          </Typography>
          <FormControl>
            <Select
              labelId="Severity"
              id="Severity"
              value={value?.severity}
              onChange={(e) => {
                setValue((prevState) => ({
                  ...prevState,
                  severity: e.target.value,
                }));
              }}
              disabled={userIsOccurenceManager ? false : true}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className="rounded-lg font-semibold"
              sx={{
                "&.MuiInputBase-root": {
                  width: "220px",
                },
              }}
            >
              <MenuItem value="">
                <p className="text-sm text-secondary ">Select Severity</p>
              </MenuItem>
              {severityList?.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item?.value}
                  className="text-secondary"
                  sx={{
                    outline: "0px",
                    "&.MuiMenuItem-root": {
                      backgroundColor: "#f4f4f4",
                      color: "#7E7E7E",
                      padding: "10px 14px",
                      borderBottom: "1px solid",
                      borderBottomColor: "#CBCBCB",
                      "&:last-child": { border: 0 },
                      "&:active": {
                        backgroundColor: "#051445",
                        color: "#ffffff !important",
                      },
                      "&:hover": {
                        backgroundColor: "#0C33AE",
                        color: "#ffffff !important",
                      },
                      "&:focus-visible": {
                        outline: 0,
                      },
                    },
                  }}
                >
                  <div className="flex items-center gap-3">
                    <span
                      className="w-3 h-3 rounded-full"
                      style={{
                        backgroundColor: item?.colorCode,
                        border: item?.border,
                      }}
                    ></span>
                    <span>{item?.label}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Editor
          value={value.content}
          onChange={(e) =>
            setValue((prevState) => ({ ...prevState, content: e }))
          }
          readOnly={userIsOccurenceManager ? false : true}
        />
      </div>
      <div className="flex justify-end">
        {!isPreview ? (
          <Button
            loading={loading}
            className="bg-primary py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress size={24} /> : "Save and Continue"}
          </Button>
        ) : (
          <Button
            className="bg-primary py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
            onClick={goToNext}
          >
            Next
          </Button>
        )}
      </div>
    </Stack>
  );
};

export default Synopsis;
