import React from "react";
import {
  Stack,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Input } from "../../../pages/user-management/ManageProfile";
import { error, success } from "../../../common/Notifications";
import axios from "axios";
import { commentColums } from "../StakeholderPermission";
import useFetch from "../../../helpers/useFetch";
import { StyledTableRow } from "../../../pages/user-data";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

export const stakeholderCategories = [
  {
    label: "NCAA",
    value: "NCAA",
  },
  {
    label: "NTSB",
    value: "NTSB",
  },
  {
    label: "TSB",
    value: "TSB",
  },
  {
    label: "Flight Crew",
    value: "Flight Crew",
  },
  {
    label: "Others",
    value: "OTHERS",
  },
];
const columns = [
  {
    id: "name",
    label: "Full Name",
    minWidth: 120,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 120,
  },
  {
    id: "phone_number",
    label: "Phone Number",
    minWidth: 120,
  },
  {
    id: "category",
    label: "Category",
    minWidth: 120,
  },
];
const StakeholderPermission = ({ occurrenceId, information }) => {
  const navigate = useNavigate();

  const [stakeholdersWithComments, setStakeholdersWithComments] =
    React.useState([]);

  React.useEffect(() => {
    if (information) {
      setStakeholdersWithComments(
        information?.occurence_stakeholders?.filter((data) => data?.comment)
      );
    }
  }, [information]);
  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/stakeholders/`
  );
  const { data: stakeholders } = useFetch(
    `api/occurrence-manager/sections/stakeholders_list/`
  );

  const [formData, setFormData] = React.useState({
    category: "",
    occurrence_id: occurrenceId,
    stakeholder_id: "",
  });

  const [stakeholdersList, setStakeholdersList] = React.useState([]);
  async function getStakeholder() {
    const config = {
      params: {
        category: formData.category,
      },
      headers: {
        Authorization: `JWT ${accessToken}`,
        "Content-Type": "Application/json",
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/stakeholders_list/`,
      config
    );
    setStakeholdersList(res?.data?.data);
  }

  React.useEffect(() => {
    if (formData.category) {
      getStakeholder();
    }
  }, [formData]);

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [loading, setLoading] = React.useState(false);

  async function addStakeholder() {
    if (!formData.category || !formData.stakeholder_id) {
      error("Please fill all the fields");
      return;
    }

    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/stakeholders/`,
        formData,
        config
      );

      setFormData({
        category: "",
        occurrence_id: occurrenceId,
        stakeholder_id: "",
      });
      refetch();
      success("Stakeholder Details added successfully");
    } catch (err) {
      error(err.response?.data?.message);
    }
    setLoading(false);
  }
  function updateStakeholder(item) {
    navigate(
      `/occurrence/manage-occurrence/${occurrenceId}/edit-sections/${item.id}?section_name=Stakeholders`
    );
  }

  return (
    <Stack gap={"32px"}>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Stakeholder Permissions</h2>
      </div>
      <div className="bg-white rounded-2xl py-4 px-8">
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Category
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="simple-select-standard-label"
                id="demo-simple-select-standard"
                value={formData?.category}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    category: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Category"}</p>
                </MenuItem>
                {stakeholderCategories?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    className="text-secondary"
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Stakeholder
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="simple-select-standard-label"
                id="demo-simple-select-standard"
                value={formData?.stakeholder_id}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    stakeholder_id: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Stakeholder"}</p>
                </MenuItem>
                {stakeholdersList?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    className="text-secondary"
                  >
                    {item.first_name + " " + item.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>

        <div className="flex justify-end">
          <Button
            variant="outlined"
            className="border-primary px-12 py-2.5 capitalize rounded-xl font-bold text-sm shadow-none mt-6 w-fit"
            onClick={addStakeholder}
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Add Stakeholder"
            )}
          </Button>{" "}
        </div>
        <TableContainer className="mt-10">
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 mb-10">
                      <CircularProgress />
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : data?.data?.length > 0 ? (
                data.data?.map((item, index) => (
                  <StyledTableRow
                    key={index}
                    hover
                    className="cursor-pointer"
                    onClick={() => updateStakeholder(item)}
                  >
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className="text-gray-500 font-medium"
                        style={{ minWidth: column.minWidth }}
                      >
                        {item[column.id]}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 mb-10">
                      <p>No Stakeholder added yet</p>
                    </div>
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Comments and Review</h2>
      </div>
      <TableContainer className="bg-white rounded-2xl py-6 px-8">
        <Table>
          <TableHead>
            <TableRow>
              {commentColums.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography color={"#AEAEAE"}>{column.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stakeholdersWithComments && stakeholdersWithComments.length > 0 ? (
              stakeholdersWithComments.map((comment, index) => (
                <StyledTableRow key={index}>
                  <TableCell>
                    <Typography fontSize={"14px"} fontWeight={500}>
                      {comment?.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={500}
                      align="center"
                    >
                      {comment?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={500}
                      align="center"
                    >
                      {new Date(comment?.created_at)?.toLocaleDateString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div className="mx-auto w-fit">
                      <Link
                        to={`/occurrence/manage-occurrence/${occurrenceId}/appendices-comments/${comment?.email}`}
                      >
                        <Button
                          variant="contained"
                          className="bg-primary capitalize shadow-none text-sm font-bold"
                        >
                          View
                        </Button>
                      </Link>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan={commentColums.length}>
                  <Typography fontSize={"14px"} fontWeight={500} align="center">
                    No comments
                  </Typography>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <div className="flex justify-end">
        <Button
          variant="contained"
          className="bg-primary text-white px-12 py-2.5 capitalize rounded-xl font-bold text-sm shadow-none"
        >
          Submit to Occurrence Owner
        </Button>
      </div> */}
    </Stack>
  );
};

export default StakeholderPermission;
