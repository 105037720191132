import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import FormBg from "../../components/user-auth/FormBg";
import Logo from "../../assets/general/nsib_logo.png";
import bg from "../../assets/general/resetPasswordBg.png";
import FormHeaders from "../../components/user-auth/FormHeaders";
import FormField from "../../components/user-auth/FormField";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { CustomPasswordField } from "../../components/common/CustomTextField";
import CircularProgress from "@mui/material/CircularProgress";
import { error, success } from "../../common/Notifications";
import axios from "axios";
import * as yup from "yup";
import { toast } from "react-toastify";

function SetNewPassword() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);
  const s = searchParams.get("token");

  const navigate = useNavigate();

  const ValidationSchema = yup.object({
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-_])[A-Za-z\d@$!%*?&]{8,}/,
        "Password is too weak"
      ),
    re_password: yup
      .string()
      .required("Confirm password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const [formErrors, setFormErrors] = React.useState({
    re_password: "",
    password: "",
    message: "",
  });

  const formik = useFormik({
    initialValues: { password: "", re_password: "", token: s },
    validationSchema: ValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);
      setFormErrors({
        re_password: "",
        password: "",
        message: "",
      });

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_API}api/user/password_reset_complete/`,
          { ...values }
        );
        success(res.data.message);
        setLoading(false);
        navigate("/reset-password-success");
      } catch (err) {
        setLoading(false);
        console.log(err);
        // error(err.response.data.message);

        Array.isArray(err?.response?.data)
          ? setFormErrors({
              re_password: err?.response?.data[0]?.hasOwnProperty("re_password")
                ? err?.response?.data[0]["re_password"]
                : "",
              password: err?.response?.data[0]?.hasOwnProperty("password")
                ? err?.response?.data[0]["password"]
                : "",
              message: err?.response?.data[0]?.hasOwnProperty("message")
                ? err?.response?.data[0]["message"]
                : "You have errors in your form",
            })
          : setFormErrors({
              re_password: err?.response?.data?.hasOwnProperty("re_password")
                ? err?.response?.data["re_password"]
                : "",
              password: err?.response?.data?.hasOwnProperty("password")
                ? err?.response?.data["password"]
                : "",
              message: err?.response?.data?.hasOwnProperty("message")
                ? err?.response?.data["message"]
                : "You have errors in your form",
            });
        toast.error(formErrors?.message, {
          theme: "dark",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      // dispatch(userLogin(values));
      // dispatch(userLogin(values)).unwrap().then(() => dispatch(fetchUser()).unwrap)
    },
  });

  return (
    <Stack
      direction={"row"}
      width={"100vw"}
      alignItems={"stretch"}
      minHeight={"100vh"}
    >
      <FormBg backgroundImage={bg} />
      <Stack
        direction={"column"}
        gap={"84px"}
        sx={{
          px: "64px",
          maxWidth: "50%",
          py: "52px",
        }}
        flex={1}
      >
        <Stack direction={"row"} gap={"8px"} alignItems={"center"}>
          <img
            src={Logo}
            // width={'55px'}
            // height={'54px'}
            alt=""
            className="h-[110px]"
          />
          {/* <p className='font-bold leading-6 text-ms break-all w-[245px] text-[#211357] tracking-wide uppercase'>Nigeria Safety <br /> Investigation Bureau</p> */}

          {/* <Typography
            variant='p'
            fontFamily={'Pt Sans'}
            letterSpacing={'0.16px'}
            fontSize={14}
            lineHeight={'16.8px'}
            color={'#211357'}
          >
            Accident Investigation
            <br /> Bureau, Nigeria
          </Typography> */}
        </Stack>

        <Stack
          direction={"column"}
          gap={"16px"}
          alignItems={"center"}
          alignSelf={"center"}
        >
          <FormHeaders
            title={"Reset Password"}
            titleColor={"#161616"}
            titleLineHeight={35}
            titleSize={22}
            marginBottom={32}
          />

          <div className="min-w-[400px] space-y-4">
            <Stack width={"100%"} gap={"6px"}>
              <CustomPasswordField
                label={`New Password`}
                value={formik.values.password}
                onChange={formik.handleChange}
                name={"password"}
                placeholder={"Enter new password"}
                error={!!(formik.errors.password && formik.touched.password)}
                helperText={formik.errors.password}
              />
            </Stack>

            <Stack width={"100%"} gap={"6px"}>
              <CustomPasswordField
                label={`Confirm Password`}
                value={formik.values.re_password}
                onChange={formik.handleChange}
                name={"re_password"}
                placeholder={"Enter new password"}
                error={
                  !!(formik.errors.re_password && formik.touched.re_password)
                }
                helperText={formik.errors.re_password}
              />
            </Stack>
          </div>

          <Button
            variant="contained"
            disableElevation
            sx={{
              backgroundColor: "#003163 !important",
              borderRadius: 0,
              width: "124px",
              height: "42px",
              fontFamily: "PT Sans",
              textTransform: "none",
              color: "#CFE0FF",
              fontSize: 14,
              mt: "16px",
              alignSelf: "flex-start",
              "&:hover": {
                backgroundColor: "#003163",
              },
            }}
            onClick={formik.handleSubmit}
          >
            {loading ? (
              <CircularProgress className="w-5 h-5 text-white" />
            ) : (
              "Confirm"
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default SetNewPassword;
