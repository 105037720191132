import Swal from "sweetalert2";
import axios from "axios";

const swalWithCustomButtons = Swal.mixin({
  customClass: {
    title: " text-[18px] font-bold text-black capitalize",
    content: " text-[14px] font-normal text-[#7e7e7e]",
    confirmButton: "bg-error px-[50px] py-4 text-white border border-error",
    cancelButton:
      "bg-transparent border border-primary px-[50px] py-4 text-primary",
    actions: "gap-10 py-4",
  },
  buttonsStyling: false,
});

const swalWithCustomButtons2 = Swal.mixin({
  customClass: {
    title: " text-[18px] font-bold text-black capitalize",
    content: " text-[14px] font-normal text-[#7e7e7e]",
    confirmButton: "bg-primary px-[50px] py-4 text-white border border-primary",
    cancelButton:
      "bg-transparent border border-primary px-[50px] py-4 text-primary",
    actions: "gap-10 py-4",
  },
  buttonsStyling: false,
});

function swalDelete(type, func) {
  swalWithCustomButtons
    .fire({
      title: `Delete ${type}`,
      text: `Are you sure you want to delete this ${type}?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
      showCloseButton: true,
      backdrop: `
            rgba(0,0,0,0.4)
            left top
            no-repeat
        `,
    })
    .then((result) => {
      if (result.isConfirmed) {
        func();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    });
}

function swalDeactivate(type, status, func) {
  swalWithCustomButtons
    .fire({
      title: `${status ? "Activate" : "Deactivate"} ${type}`,
      text: `Are you sure you want to ${
        status ? "activate" : "deactivate"
      } this ${type}?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
      showCloseButton: true,
      backdrop: `
            rgba(0,0,0,0.4)
            left top
            no-repeat
        `,
    })
    .then((result) => {
      if (result.isConfirmed) {
        func();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    });
}

function swalPublish(status, func) {
  swalWithCustomButtons2
    .fire({
      title: `${status ? "Publish" : "Unpublish"} Occurrence`,
      text: `Are you sure you want to ${
        status ? "publish" : "unpublish"
      } this occurrence`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
      showCloseButton: true,
      backdrop: `
            rgba(0,0,0,0.4)
            left top
            no-repeat
        `,
    })
    .then((result) => {
      if (result.isConfirmed) {
        func();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    });
}

function swalSentToStakeholders(func) {
  swalWithCustomButtons2
    .fire({
      title: `Send to stakeholder`,
      text: `Are you sure you want to send this occurrence section to stakeholders?`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true,
      showCloseButton: true,
      backdrop: `
            rgba(0,0,0,0.4)
            left top
            no-repeat
        `,
    })
    .then((result) => {
      if (result.isConfirmed) {
        func();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    });
}

export { swalDelete, swalDeactivate, swalPublish, swalSentToStakeholders };
