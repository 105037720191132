import * as React from "react";
// import div from '@mui/material/div';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import {
  Status,
  ArrowRotateLeft,
  Profile2User,
  Folder,
  Personalcard,
  ClipboardExport,
  Folder2,
  ClipboardText,
} from "iconsax-react";

import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

function DrawerList({ open, pathName }) {
  const listButtonStyle =
    "flex items-center px-5 py-3 text-gray-600 group font-semibold hover:font-bold my-2 hover:cursor-pointer hover:text-white hover:bg-primary/40 duration-300";
  const listItemIconStyle = `transition-all min-w-[36px] group-hover:text-white`;
  const listTextStyle = "";
  `transition-all ${
    open ? "opacity-100" : "opacity-0"
  } group-hover:text-primary `;
  const listIconStyle = "";
  `text-primary group-hover:text-primary`;

  const userRoles = useSelector((state) => state.users?.user?.roles);

  const navLinks = [
    {
      id: 1,
      name: "Dashboard",
      path: "dashboard",
      icon: <Status size="24" variant="Bold" />,
      access: true,
    },
    userRoles?.role?.toLowerCase()?.includes("super-administrator") && {
      id: 2,
      name: "Reset Request",
      path: "reset-requests",
      icon: <ArrowRotateLeft size="24" variant="Bold" />,
      access: true,
    },

    !userRoles?.role?.toLowerCase()?.includes("super-administrator") && {
      id: 2,
      name: "Occurrences",
      path:
        userRoles?.occurence_manager?.content_management?.view ||
        userRoles?.occurence_manager?.report_management?.view
          ? "occurrence"
          : "dashboard",
      icon: <Folder size="24" variant="Bold" />,
      access:
        userRoles?.occurence_manager?.content_management?.view ||
        userRoles?.occurence_manager?.report_management?.view,
    },
    // {
    //   id: 3, name: 'Occurrence report', path: 'report/section', icon: <NewspaperIcon className={`${pathName.includes('occurrence_report') ? 'text-primary group-hover:text-primary' : 'text-black group-hover:text-gray-600'}`} />,
    //   access: userRoles?.occurence_manager?.report_management?.view
    // },
    !userRoles?.role?.toLowerCase()?.includes("super-administrator") &&
      !userRoles?.role?.toLowerCase()?.includes("super-user") &&
      !userRoles?.role?.toLowerCase()?.includes("stakeholder") && {
        id: 3,
        name: "Media Library",
        path: "media",
        icon: <Folder2 size="24" variant="Bold" />,
        access: userRoles?.media_library?.view,
      },
    userRoles?.role?.toLowerCase()?.includes("super-user") && {
      id: 4,
      name: "Role Data",
      path: "role-data",
      icon: <Personalcard size="24" variant="Bold" />,
      access: true,
    },
    userRoles?.role?.toLowerCase()?.includes("super-user") && {
      id: 5,
      name: "User Data",
      path: "user-data",
      icon: <Profile2User size="24" variant="Bold" />,
      access: true,
    },
    userRoles?.role?.toLowerCase()?.includes("super-user") && {
      id: 6,
      name: "ICAO",
      path: "icao",
      icon: <ClipboardExport size="24" variant="Bold" />,
      access: true,
    },

    userRoles?.role?.toLowerCase()?.includes("super-administrator") && {
      id: 7,
      name: "User Management",
      path: "user-management",
      icon: <Profile2User size="24" variant="Bold" />,
      access: userRoles?.user_management?.user_profiles?.view,
    },

    userRoles?.role?.toLowerCase()?.includes("stakeholder") && {
      id: 8,
      name: "Reviews",
      path: "reviews",
      icon: <ClipboardText size="24" variant="Bold" />,
      access: true,
    },
  ];

  return (
    <div className="mt-[55px] select-none">
      {navLinks?.map((link) =>
        link?.access ? (
          <NavLink
            to={`/${link.path}`}
            key={link.id}
            className={`${listButtonStyle} rounded-3xl !font-semibold ${
              pathName.includes(link.path) && "bg-primary text-white"
            }`}
          >
            <ListItemIcon
              className={`${listItemIconStyle} ${
                pathName.includes(link.path) && "text-white"
              }`}
            >
              {link.icon}
            </ListItemIcon>

            <ListItemText
              primary={link.name}
              className={` ${
                pathName.includes(link.path) && "text-white"
              } ${listTextStyle}`}
            />
          </NavLink>
        ) : null
      )}
    </div>
  );
}

export default DrawerList;
