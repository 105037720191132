import React from "react";
import {
  Stack,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Pagination,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "iconsax-react";
import useFetch from "../../helpers/useFetch";
import { generateRandomNumbersBetween } from "../../utils/helpers";
import { styled } from "@mui/material/styles";
import { isUpperCase } from "../../utils/helpers";

// IMAGES
import firstDp from "../../assets/emojis/black-girl.svg";
import secondDp from "../../assets/emojis/boy-with-cap.svg";
import thirdDp from "../../assets/emojis/light-girl-1.svg";
import fourthDp from "../../assets/emojis/man-with-hat.svg";

const columns = [
  {
    id: "sn",
    label: "S/N",
    format: (value) => <p className="font-bold text-sm ">{value}</p>,
  },
  {
    id: "photo",
    label: "Profile Photo",
    minWidth: 140,
    format: (value) => <p className="font-bold text-sm ">{value}</p>,
  },
  {
    id: "fullname",
    label: "Name",
    minWidth: 170,
    format: (value) => <p className="font-bold text-sm ">{value}</p>,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
    format: (value) => <p className="">{value}</p>,
  },
  {
    id: "role",
    label: "Role",
    minWidth: 170,
    format: (value) => <p className="">{value}</p>,
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    format: (value) => <p className="">{value}</p>,
  },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  a: {
    border: 0,
  },
  "td, th": {
    border: 0,
  },
}));

export default function UserProfile() {
  // DATA

  const navigate = useNavigate();
  const pictures = [firstDp, secondDp, thirdDp, fourthDp];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [tableData, setTableData] = React.useState([]);

  const { data, isLoading, refetch } = useFetch("api/user/");

  React.useEffect(() => {
    if (!isLoading) {
      setTableData(data?.data);
    }
  }, [isLoading, data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = [
    tableData?.map((item) => {
      return { ...item };
    }),
  ];
  return (
    <Stack gap={"23px"}>
      <div className="flex items-center justify-between gap-4">
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
        <Button
          onClick={() => navigate("/user-management/create-user")}
          variant="contained"
          className="bg-primary shadow-none px-16 capitalize text-white py-3 rounded-xl font-bold text-[15px]"
        >
          Add User
        </Button>
      </div>

      <Stack width={"100%"} marginTop={"18px"}>
        <Stack
          minHeight={"350px"}
          justifyContent={"flex-start"}
          sx={{
            background: "#fff",
            py: "18px",
            px: "16px",
            borderRadius: "16px",
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography
                        fontSize={13}
                        color={"#AEAEAE"}
                        variant="body2"
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <StyledTableRow>
                    <TableCell colSpan={columns?.length} align="center">
                      <div className="mt-10">
                        <CircularProgress />
                        <div>
                          <p className="text-center animate-pulse">
                            Loading...
                          </p>
                        </div>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ) : tableData?.length === 0 && !isLoading ? (
                  <StyledTableRow>
                    <TableCell colSpan={columns?.length} align="center">
                      <Typography
                        fontSize={14}
                        color={"#161616"}
                        variant="body2"
                      >
                        No users found
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                ) : !tableData && !isLoading ? (
                  <StyledTableRow>
                    <TableCell colSpan={columns?.length} align="center">
                      <Typography
                        fontSize={14}
                        marginTop={10}
                        color={"#161616"}
                        variant="body2"
                      >
                        Unable to fetch Users
                      </Typography>
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  tableData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/user-management/manage-user/${row.id}`)
                        }
                      >
                        {columns.map((column) => (
                          <TableCell key={column.id} align={column.align}>
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              variant="body2"
                            >
                              {column?.id === "sn" ? (
                                index + 1 + "."
                              ) : column?.id === "photo" ? (
                                <div className="w-10 h-10 rounded-full bg-primary grid place-content-center">
                                  <img
                                    src={
                                      pictures[
                                        generateRandomNumbersBetween(0, 3)
                                      ]
                                    }
                                    alt="user"
                                  />
                                </div>
                              ) : column?.id == "role" ? (
                                <span
                                  className={`${
                                    isUpperCase(row[column?.id])
                                      ? "capitalize"
                                      : ""
                                  }`}
                                >
                                  {isUpperCase(row[column.id])
                                    ? row[column.id]
                                        ?.replace("-", " ")
                                        ?.toLowerCase()
                                    : row[column.id]?.replace("-", " ")}
                                </span>
                              ) : column?.id === "status" ? (
                                <span>
                                  {row?.is_active ? "Active" : "Inactive"}
                                </span>
                              ) : (
                                row[column.id]
                              )}
                            </Typography>
                          </TableCell>
                        ))}
                      </StyledTableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <div className="py-4 flex justify-center">
        <Pagination
          rowsPerPageOptions={[5, 25, 100]}
          count={Math.ceil(rows[0]?.length / rowsPerPage)}
          rowsPerPage={rowsPerPage}
          page={page + 1}
          color="primary"
          onChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // onChange={handlePageChange}
        />
      </div>
    </Stack>
  );
}
