import {
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  BtnAlignCenter,
  BtnAlignLeft,
  BtnAlignRight,
  BtnBold,
  BtnItalic,
  BtnUnderline,
} from "../Synopsis";
import { Editor, EditorProvider, Toolbar } from "react-simple-wysiwyg";
import React from "react";
import { Input } from "../../../pages/user-management/ManageProfile";
import { useSelector } from "react-redux";
import { error, success } from "../../../common/Notifications";
import axios from "axios";
import { severityList } from "../section-owner/SectionForm";

const SafetyRecommendationForm = ({
  information,
  goToNext,
  isPreview,
  occurrenceId,
  refetch,
}) => {
  const user = useSelector((state) => state.users.user);
  const [formData, setFormData] = React.useState({
    section_name: "",
    status: "",
    recommendation: "",
    severity: "",
    occurrence: occurrenceId,
    section_group_name: "SAFETY RECOMMENDATIONS",
  });

  React.useEffect(() => {
    if (information) {
      const section = information?.occurrence_section_group?.find(
        (group) => group?.section_name === "SAFETY RECOMMENDATIONS"
      );
      if (
        section &&
        section.occurence_section &&
        section.occurence_section.length > 0
      ) {
        setFormData((prev) => ({
          ...prev,
          section_name: section.occurence_section[0].title,
          status: section.occurence_section[0].status,
          severity: section.occurence_section[0].severity,
          recommendation: section.occurence_section[0].recommendation,
        }));
      }
    }
  }, [information]);

  const accessToken = useSelector((state) => state.auth.accessToken);

  const [loading, setLoading] = React.useState(false);
  async function handleSubmit() {
    if (
      !formData.recommendation ||
      !formData.status ||
      !formData.section_name ||
      !formData.severity
    ) {
      error("Please fill all the fields");
      return;
    }

    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/create_safety_recommendation/`,
        formData,
        config
      );
      goToNext();
      refetch();
      success("Occurrence Details updated successfully");
    } catch (err) {
      error(err.response?.data?.message);
    }
    setLoading(false);
  }
  return (
    <Stack gap={"32px"}>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Safety Recommendation</h2>
      </div>

      <div className="bg-white rounded-2xl py-4 px-8">
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Title <span className="text-red-500">*</span>
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              disabled={user?.roles?.role?.toLowerCase() == "super-user"}
              value={formData?.section_name}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  section_name: target.value,
                }));
              }}
              placeholder="Title"
            />
          </Stack>

          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Status <span className="text-red-500">*</span>
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="simple-select-standard-label"
                id="demo-simple-select-standard"
                value={formData?.status}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    status: e.target.value,
                  }));
                }}
                displayEmpty
                disabled={user?.roles?.role?.toLowerCase() == "super-user"}
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Status"}</p>
                </MenuItem>
                {["Closed", "Open"].map((item, index) => (
                  <MenuItem key={index} value={item} className="text-secondary">
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
        <div className="mt-8 max-w-[50%] pr-5">
          <Stack gap={"6px"} marginBottom={"20px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Severity
            </Typography>
            <FormControl>
              <Select
                labelId="Severity"
                id="Severity"
                value={formData?.severity}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    severity: e.target.value,
                  }));
                }}
                disabled={user?.roles?.role?.toLowerCase() == "super-user"}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
                sx={{
                  "&.MuiInputBase-root": {
                    // width: "220px",
                  },
                }}
              >
                <MenuItem value="">
                  <p className=" text-secondary ">Select Severity</p>
                </MenuItem>
                {severityList?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item?.value}
                    className="text-secondary"
                    sx={{
                      outline: "0px",
                      "&.MuiMenuItem-root": {
                        backgroundColor: "#f4f4f4",
                        color: "#7E7E7E",
                        padding: "10px 14px",
                        borderBottom: "1px solid",
                        borderBottomColor: "#CBCBCB",
                        "&:last-child": { border: 0 },
                        "&:active": {
                          backgroundColor: "#051445",
                          color: "#ffffff !important",
                        },
                        "&:hover": {
                          backgroundColor: "#0C33AE",
                          color: "#ffffff !important",
                        },
                        "&:focus-visible": {
                          outline: 0,
                        },
                      },
                    }}
                  >
                    <div className="flex items-center gap-3">
                      <span
                        className="w-3 h-3 rounded-full"
                        style={{
                          backgroundColor: item?.colorCode,
                          border: item?.border,
                        }}
                      ></span>
                      <span>{item?.label}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
        <Stack className="mt-6" gap={"6px"}>
          <Typography variant="p" color={"#D8D8D8"}>
            Recommendation <span className="text-red-500">*</span>
          </Typography>
          <EditorProvider>
            <Editor
              value={formData.recommendation}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  recommendation: e.target.value,
                }))
              }
              disabled={user?.roles?.role?.toLowerCase() == "super-user"}
            >
              <Toolbar>
                <Stack
                  direction={"row"}
                  gap={"32px"}
                  paddingLeft={"20px"}
                  paddingY={"10px"}
                  className="text-center"
                >
                  <BtnAlignLeft />
                  <BtnAlignCenter />
                  <BtnAlignRight />
                  <BtnItalic />
                  <BtnBold />
                  <BtnUnderline />
                </Stack>
              </Toolbar>
            </Editor>
          </EditorProvider>
        </Stack>
      </div>

      <div className="flex justify-end">
        {isPreview ? (
          <Button
            className="bg-primary text-white font-bold text-sm px-12 py-2.5 shadow-none capitalize rounded-xl"
            onClick={goToNext}
          >
            Next
          </Button>
        ) : (
          <Button
            className="bg-primary text-white font-bold text-sm px-12 py-2.5 shadow-none capitalize rounded-xl"
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress size={20} /> : "Save and Continue"}
          </Button>
        )}
      </div>
    </Stack>
  );
};

export default SafetyRecommendationForm;
