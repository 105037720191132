import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./redux/slices/users/authSlice";
import auditSlice from "./redux/slices/audits/auditSlice";
import userSlice from "./redux/slices/users/userSlice";
import { authApi } from "./redux/services/authService";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import sessionStorage from "redux-persist/lib/storage/session";
import stakeholderAccessSlice from "./redux/slices/users/stakeholderAccessSlice";
import titleSlice from "./redux/slices/users/titleSlice";

import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "users"],
};

const sessionPersistConfig = {
  key: "session",
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  audits: auditSlice,
  auth: authSlice,
  users: userSlice,
  title: titleSlice,
  stakeholder: persistReducer(sessionPersistConfig, stakeholderAccessSlice),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
