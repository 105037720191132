import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { Input } from "../../pages/user-management/ManageProfile";
import { error } from "../../common/Notifications";
import axios from "axios";
import { useSelector } from "react-redux";

const MediaEditModal = ({ imageDetail, occurrenceTitle, setDone, data }) => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [value, setValue] = React.useState({
    image_url: imageDetail?.url,
    title: imageDetail?.title,
    alt_text: imageDetail?.alt_text,
  });

  const [loading, setLoading] = React.useState(false);
  async function handleEditMedia() {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      await axios.put(
        `${process.env.REACT_APP_BASE_API}api/media-library/${imageDetail?.id}/`,
        {
          title: value?.title,
          alt_text: value.alt_text,
        },
        config
      );
      setDone();
    } catch (err) {
      error(err?.data?.message);
    }
    setLoading(false);
  }

  return (
    <div className="px-4 py-10">
      <div>
        <h2 className="text-center font-semibold text-xl">Edit Media Info</h2>
      </div>
      <div className="mt-6 h-[250px] rounded-lg overflow-hidden">
        {imageDetail?.media_type?.toLowerCase() === "image" ? (
          <img
            src={imageDetail?.url}
            className="w-full h-full object-cover"
            alt=""
          />
        ) : (
          <video
            src={imageDetail?.url}
            className="w-full h-full object-cover"
            controls
            alt=""
          />
        )}
      </div>
      <div className="mt-6 w-[80%] mx-auto px-1">
        <div>
          <h2 className="text-[#D8D8D8] pb-2.5">Media Title</h2>
          <Input
            value={value.title}
            type="text"
            fullWidth
            onChange={(e) =>
              setValue((prevVal) => {
                return { ...prevVal, title: e.target.value };
              })
            }
          />
        </div>
        <div className="mt-5">
          <h2 className="text-[#D8D8D8] pb-2.5">Occurrence Attached</h2>
          <input
            value={occurrenceTitle}
            readOnly
            className=" bg-gray-300 border-none outline-none rounded-lg px-4 py-4 w-full font-semibold"
          ></input>
        </div>
        <div className="mt-5">
          <h2 className="text-[#D8D8D8] pb-2.5">Alt Text</h2>
          <Input
            value={value.alt_text}
            type="text"
            fullWidth
            onChange={(e) =>
              setValue((prevVal) => {
                return { ...prevVal, alt_text: e.target.value };
              })
            }
          />
        </div>
      </div>

      <div className="mt-6 flex justify-center">
        <Button
          variant="contained"
          className="bg-primary capitalize font-bold px-12 py-2.5 rounded-xl shadow-none"
          onClick={handleEditMedia}
        >
          {loading ? <CircularProgress color="inherit" size={20} /> : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default MediaEditModal;
