import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Stack,
  Typography,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Button,
  CircularProgress,
} from "@mui/material";
import { fileIconGrey } from "../../../assets";
import useFetch from "../../../helpers/useFetch";
import { StyledTableRow } from "../../user-data";
import { Edit2 } from "iconsax-react";

export const SectionOwnerOccurrenceColumns = [
  {
    id: "occurrence_number",
    label: "Occurrence Number",
    minWidth: 200,
    align: "center",
  },
  {
    id: "title",
    label: "Title",
    minWidth: 170,
    align: "center",
  },
  {
    id: "occurrence_type",
    label: "Section",
    minWidth: 170,
    align: "center",
  },
  {
    id: "submission_date",
    label: "Submission Date",
    minWidth: 170,
    align: "center",
  },
  {
    id: "occurrence_status",
    label: "Status",
    minWidth: 170,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 90,
    align: "center",
  },
];

export default function SectionOwner() {
  // const user = useSelector((state) => state.users);
  // const userRoles = useSelector((state) => state.users?.user?.roles);

  const navigate = useNavigate();
  const { data, isLoading, refetch } = useFetch(
    "api/occurrence-manager/information/occurrence_by_section_member/"
  );
  const { data: mediaData, isLoading: loadingMedia } =
    useFetch("api/media-library/");

  return (
    <Stack marginTop={"32px"} gap={"32px"}>
      <Stack width={"100%"}>
        <div className="rounded-t-2xl bg-primary flex text-white items-center px-8 py-8 justify-between">
          <Typography fontSize={"20px"} className="font-semibold">
            Section Owner
          </Typography>
          <Link className="font-semibold text-sm" to="/occurrence">
            See all
          </Link>
        </div>
        <Stack className="bg-white rounded-b-2xl p-6">
          <TableContainer className="min-h-[calc(100dvh-30rem)]">
            <Table>
              <TableHead>
                <TableRow>
                  {SectionOwnerOccurrenceColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        color={"#AEAEAE"}
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <StyledTableRow>
                    <TableCell
                      colSpan={SectionOwnerOccurrenceColumns?.length}
                      align="center"
                    >
                      <div className="mt-10 mb-10">
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ) : data.data?.length > 0 ? (
                  data?.data
                    ?.filter((val) =>
                      val.occurrence_status?.toLowerCase()?.includes("progress")
                    )
                    ?.splice(0, 10)
                    ?.map((row) => (
                      <StyledTableRow
                        key={row.occurenceNumber}
                        sx={{ height: "68px" }}
                      >
                        {SectionOwnerOccurrenceColumns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "action" ? (
                                <Button
                                  variant="contained"
                                  className="bg-primary capitalize font-bold text-sm px-7 shadow-none rounded-xl"
                                  onClick={() =>
                                    navigate(
                                      `/occurrence/section/manage-occurrence/${row.id}`
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                              ) : column.id === "submission_date" ? (
                                <span>{row?.icao_deadline_date}</span>
                              ) : (
                                <Typography fontSize={14}>{value}</Typography>
                              )}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    ))
                ) : (
                  <StyledTableRow>
                    <TableCell
                      colSpan={SectionOwnerOccurrenceColumns?.length}
                      align="center"
                    >
                      <div className="mt-10 mb-10">
                        <p>No Section assigned to you yet</p>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <div className="mt-7">
          <div className="flex justify-between items-center px-5">
            <h2 className="text-xl font-semibold">Media</h2>
            <Link to="/media" className="text-gray-500">
              See all
            </Link>
          </div>
          {mediaData?.data?.length > 0 && (
            <Stack className="grid grid-cols-3 xl:grid-cols-4 gap-6 mt-6">
              {mediaData?.data?.slice(0, 6)?.map((item, index) => {
                return (
                  <div key={index} className="bg-white py-4 px-4 rounded-2xl">
                    <img
                      src={item?.url}
                      alt={item?.alt_text}
                      className="w-full h-[12rem] object-cover rounded-lg cursor-pointer"
                      onClick={() => navigate(`/media/${item?.id}`)}
                    />
                    <div className="mt-2 flex justify-between items-center gap-2">
                      <div className="min-w-0">
                        <h2 className="font-semibold truncate">
                          {item?.title}
                        </h2>
                        <p className="text-gray-600 truncate">{item?.slug}</p>
                      </div>
                      <Edit2
                        className="cursor-pointer"
                        size="24"
                        variant="Bold"
                        onClick={() => navigate(`/media/${item?.id}`)}
                      />
                    </div>
                  </div>
                );
              })}
            </Stack>
          )}
        </div>
      </Stack>
    </Stack>
  );
}
