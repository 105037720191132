import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const titleSlice = createSlice({
  name: "title",
  initialState: {
    title: "",
    isLoading: false,
  },

  reducers: {
    setTitle: (state, payload) => {
      state.title = payload?.payload?.title;
      state.isLoading = payload?.payload?.isLoading;
    },
  },
});

export const { setTitle } = titleSlice.actions;

export default titleSlice.reducer;
