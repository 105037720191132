import React from 'react';
import { IconButton } from '@mui/material';
import PhotoIcon from '@mui/icons-material/Photo';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';
import { Stack, TableContainer, TableCell, TableHead, TableRow, Typography, Table, TableBody } from '@mui/material';


const MediaGrid = ({ images, selectedImages, onImageSelect }) => {
    return (
        <div>
            <div className='grid gap-4 grid-cols-3 md:grid-cols-5 lg:grid-cols-7'>
                {images.map((image, index) => (
                    <Link to={`/media/${image.id}`} key={index}>

                        <div className='h-[180px] w-[180px]'>
                            {image.file_type === '.mp4' ?
                                <video src={image.url} className='w-full  h-full'></video>

                                :
                                <img
                                    src={image.url}
                                    alt={`Image ${image.id}`}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            }

                        </div>
                        <IconButton
                            onClick={() => onImageSelect(image.id)}
                            style={{
                                position: 'absolute',
                                top: '0.5rem',
                                right: '0.5rem',
                                color: selectedImages.includes(image.id) ? 'green' : 'gray',
                            }}
                        >
                            {selectedImages.includes(image.id) ? (
                                <CheckCircleIcon fontSize="large" />
                            ) : (
                                <PhotoIcon fontSize="large" />
                            )}
                        </IconButton>
                    </Link>
                ))}
            </div>

            <Stack justifySelf={'center'} display={images.length > 0 ? 'none' : 'block'} justifyItems={'center'} gap={'7px'} alignItems={'center'} alignSelf={'center'} className='flex'>
                {/* <img src={fileIconGrey} width={'32px'} style={{ margin: '0px auto' }} height={'32px'} alt="" /> */}
                {images.length === 0 &&

                    <Typography
                        fontFamily={'PT Sans'}
                        color={'#CBCBCB'}
                        fontSize={'16px'}
                        lineHeight={'19.2px'}
                        letterSpacing={'0.32px'}
                    >
                        No Media, Add new media
                    </Typography>
                }
            </Stack>
        </div>
    );
};

// MediaGrid.propTypes = {
//   images: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.string.isRequired,
//       url: PropTypes.string.isRequired,
//     })
//   ).isRequired,
//   selectedImages: PropTypes.arrayOf(PropTypes.string).isRequired,
//   onImageSelect: PropTypes.func.isRequired,
// };

export default MediaGrid;