import React from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { Profile2User, Folder2, ArrowRight } from "iconsax-react";
import suitcase from "../../assets/emojis/suitcase.svg";
import backgroundBlur from "../../assets/backgrounds/user-managament-count.svg";
import { generateRandomNumbersBetween } from "../../utils/helpers";

import firstDp from "../../assets/emojis/black-girl.svg";
import secondDp from "../../assets/emojis/boy-with-cap.svg";
import thirdDp from "../../assets/emojis/light-girl-1.svg";
import fourthDp from "../../assets/emojis/man-with-hat.svg";

function userManagement({
  title,
  noMainCounts,
  isSuperUser,
  counts,
  loading,
  roleList,
  roleLoading,
  userList,
  userRoleIsLoading,
}) {
  const pictures = [firstDp, secondDp, thirdDp, fourthDp];
  return (
    <div>
      <div className="pl-3">
        <h2 className="text-xl font-semibold">{title || "User Management"}</h2>
      </div>
      {!noMainCounts && (
        <div className="text-white grid grid-cols-2 gap-3 items-end mt-6">
          <Link to="/user-management/users">
            <div className="relative overflow-hidden bg-primary rounded-2xl flex items-center font-medium pl-4 h-20 cursor-pointer">
              <div className=" flex items-end gap-1 relative z-10">
                {loading ? (
                  <Icon icon="ep:loading" className="text-3xl animate-spin" />
                ) : (
                  <h2 className="text-[28px] leading-none">
                    {counts?.user_count}
                  </h2>
                )}
                <p className="text-sm">Users</p>
              </div>
              <img
                src={backgroundBlur}
                className="absolute top-0 -left-2 -rotate-[32deg] opacity-70"
              />
              <div className="absolute top-1 right-1 bg-primary-700 rounded-full w-8 h-8 grid place-content-center">
                <ArrowRight color="#FFFFFF" size={20} />
              </div>
              <Profile2User
                className="text-primary-700 absolute -bottom-3 -right-2 opacity-45"
                size="75"
                variant="Bold"
              />
            </div>
          </Link>
          <Link to="/user-management/roles">
            <div className="relative bg-primary rounded-2xl flex items-center font-medium pl-4 h-20 overflow-hidden cursor-pointer">
              <div className=" flex items-end gap-1 relative z-10">
                {loading ? (
                  <Icon icon="ep:loading" className="text-3xl animate-spin" />
                ) : (
                  <h2 className="text-[28px] leading-none">
                    {counts?.role_count}
                  </h2>
                )}
                <p className="text-sm">Roles</p>
              </div>
              <img
                src={backgroundBlur}
                className="absolute top-0 -left-2 -rotate-[32deg] opacity-70"
              />
              <div className="absolute top-1 right-1 bg-primary-700 rounded-full w-8 h-8 grid place-content-center">
                <ArrowRight color="#FFFFFF" size={20} />
              </div>
              <Folder2
                className="text-primary-700 absolute -bottom-3 -right-2 opacity-45"
                size="75"
                variant="Bold"
              />
            </div>
          </Link>
        </div>
      )}

      <div className="mt-6 px-3 py-3 bg-gray-100 rounded-2xl">
        <div className="flex justify-between items-center">
          <h2 className="text-sm font-semibold">
            User ({" "}
            {loading ? (
              <Icon icon="ep:loading" className="text-lg animate-spin inline" />
            ) : (
              counts?.user_count
            )}
            )
          </h2>
          <Link
            to={`${isSuperUser ? "/user-data" : "/user-management/users"}`}
            className="text-gray-400 font-medium text-[10px]"
          >
            See all
          </Link>
        </div>
        <ul className="mt-6">
          {userRoleIsLoading && (
            <div className="grid place-content-center mt-6">
              <Icon icon="ep:loading" className="text-lg animate-spin inline" />
            </div>
          )}
          {!userRoleIsLoading &&
            userList?.length &&
            userList?.slice(0, 4)?.map((user) => (
              <li
                key={user?.id}
                className="pb-3 border-b last:border-b-0 mt-3 first:mt-0"
              >
                <div className="flex items-center gap-2 mt-3 first:mt-0">
                  <div className="w-10 h-10 rounded-full bg-primary grid place-content-center">
                    <img
                      src={pictures[generateRandomNumbersBetween(0, 3)]}
                      className=""
                    />
                  </div>
                  <div>
                    <h2 className="text-sm font-semibold">{user?.fullname}</h2>
                    <p className="text-xs capitalize">
                      {user?.role?.replace("-", " ")?.toLowerCase()}
                    </p>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div className="mt-6 px-3 py-3 bg-gray-100 rounded-2xl">
        <div className="flex justify-between items-center">
          <h2 className="text-sm font-semibold">
            Roles (
            {loading ? (
              <Icon icon="ep:loading" className="text-lg animate-spin inline" />
            ) : (
              counts?.role_count
            )}
            )
          </h2>
          <Link
            to={`${isSuperUser ? "/role-data" : "/user-management/roles"}`}
            className="text-gray-400 font-medium text-[10px]"
          >
            See all
          </Link>
        </div>

        <ul className="mt-6">
          {roleLoading && (
            <div className="grid place-content-center">
              <Icon icon="ep:loading" className="text-lg animate-spin inline" />
            </div>
          )}
          {!roleLoading &&
            roleList?.length &&
            roleList?.slice(0, 3)?.map((role) => (
              <li
                key={role?.id}
                className="pb-3 border-b last:border-b-0 mt-3 first:mt-0"
              >
                <div className="flex items-center gap-2">
                  <div className="h-10 w-10 rounded-full bg-primary/30 grid place-content-center">
                    <img src={suitcase} />
                  </div>
                  <div>
                    <h2 className="text-sm font-semibold capitalize">
                      {role?.role?.replace("-", " ")?.toLowerCase()}
                    </h2>
                    <p className="text-xs ">
                      {role?.user_count} users occupy this role
                    </p>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default userManagement;
