import * as React from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Dashboard from "./layout/dashboard/Dashboard";
import Login from "./pages/auth/Login";
// import ResetPassword from "./pages/auth/ResetPassword";
import SetNewPassword from "./pages/auth/SetNewPassword";
import SuccessResetPassword from "./pages/auth/SuccessResetPassword";
import Page404 from "./pages/404";
import OccurenceHome from "./pages/occurence-management";
import CreateOccurence from "./pages/occurence-management/CreateOccurence";
import Home from "./pages/dashboard/Home";
import ProfileSettings from "./pages/profile";
import UserManagement from "./pages/user-management";
import CreateNewUser from "./pages/user-management/CreateNewUser";
import UserProfile from "./pages/user-management/UserProfiles";
import UserRoles from "./pages/user-management/UserRoles";
import CreateNewRole from "./pages/user-management/CreateNewRole";
import ManageRole from "./pages/user-management/ManageRole";
import ManageUser from "./pages/user-management/ManageProfile";
import MediaHome from "./pages/media";
import ViewMedia from "./pages/media/ViewMedia";
import OccurrenceIndexLayout from "./layout/occurrenceIndex/OccurrenceIndexLayout";
import OccurenceIndex from "./pages/occurrenceIndex";
import SingleOccurrenceIndex from "./pages/occurrenceIndex/SingleOccurrenceIndex";
import ExecutiveSummary from "./pages/occurrenceIndex/SIngleOccurrence/ExecutiveSummary";
import SafetyRecommendation from "./pages/occurrenceIndex/SIngleOccurrence/SafetyRecommendation";
import SimilarOccurrence from "./pages/occurrenceIndex/SIngleOccurrence/SimilarOccurrence";
import ManageOccurence from "./pages/occurence-management/ManageOccurence";
import OccurenceDetails from "./pages/occurence-management/OccurenceDetails";
import ManageSections from "./pages/occurence-management/ManageSections";
import SectionOwner from "./pages/occurence-management/section-owner";
import ManageOccurrenceSectionOwner from "./pages/occurence-management/section-owner/ManageOccurrence";
import ContentManagement from "./pages/occurence-management/section-owner/ContentManagement";

// NEW PAGES
import ResetRequest from "./pages/reset-request";
import RequestDetail from "./pages/reset-request/RequestDetail";
import RoleData from "./pages/role-data";
import RoleDetail from "./pages/role-data/roleDetail";
import UserData from "./pages/user-data";
import SuperUserDataView from "./pages/user-data/userDetail";
import ICAOInterim from "./pages/icao-interim";
import ICAOSubmitForm from "./pages/icao-interim/icao-form";
import FactualInformation from "./pages/occurence-management/FactualInformation";
import ChangePassword from "./pages/change-password";
import ManageSectionEdit from "./pages/occurence-management/ManageSectionEdit";
import ReviewHome from "./pages/reviews";

// UTILS
import { history } from "./helpers/history";

import { useSelector } from "react-redux";
import VerifyLogin from "./pages/auth/VerifyLogin";
import ResetPassword from "./pages/auth/ResetPasswordRequest";
import AppendixDetails from "./pages/occurence-management/AppendixDetails";
import CommentDetails from "./pages/occurence-management/CommentDetails";
import MediaIndex from "./pages/media-library";
import AddMedia from "./pages/media-library/AddMedia";
import MediaDetail from "./pages/media-library/MediaDetail";
import ManageSectionSectionOwner from "./pages/occurence-management/section-owner/ManageSection";
import ReviewDetail from "./pages/reviews/ReviewDetail";
import AddIcao from "./pages/icao-interim/add-icao";
import ICAODetail from "./pages/icao-interim/icao-detail";
import ICAOInterimUsers from "./pages/icao-interim/icao-users";

function RequireAuth({ children }) {
  let isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.info("is authenticated: " + isAuthenticated);
  return isAuthenticated ? children : <Navigate to={`/login`} />;
}
export default function App() {
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <Routes>
      {/* <Route path="/" element={<Login />} />
      <Route element={<Dashboard />}>
        <Route path="/dashboard" element={<Home />} />
      </Route> */}

      <Route path="/login" element={<Login />} />
      <Route path="/login/verify" element={<VerifyLogin />} />
      {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/confirm-password" element={<SetNewPassword />} />
      <Route
        path="/reset-password-success"
        element={<SuccessResetPassword />}
      />

      {/* Dashboard home routes */}
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="" element={<Home />} />
      </Route>

      {/* CHANGE PASSWORD */}
      <Route
        path="/change-password"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="" element={<ChangePassword />} />
      </Route>

      {/* Dashboard Occurrence route */}
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="/occurrence" element={<OccurenceHome />} />
        <Route
          path="/occurrence/create-occurrence"
          element={<CreateOccurence />}
        />
        <Route
          path="/occurrence/manage-occurrence/:occurrenceId"
          element={<ManageOccurence />}
        />
        <Route
          path="/occurrence/manage-occurrence/:occurrenceId/factual-info/:sectionId"
          element={<FactualInformation />}
        />
        <Route
          path="/occurrence/manage-occurrence/:occurrenceId/appendices/:sectionId"
          element={<AppendixDetails />}
        />
        <Route
          path="/occurrence/manage-occurrence/:occurrenceId/appendices-comments/:commentId"
          element={<CommentDetails />}
        />
        <Route
          path="/occurrence/manage-occurrence/:occurrenceId/occurrence-details"
          element={<OccurenceDetails />}
        />
        <Route
          path="/occurrence/manage-occurrence/:occurrenceId/manage-sections"
          element={<ManageSections />}
        />
        {/* // EDIT */}
        <Route
          path="/occurrence/manage-occurrence/:occurrenceId/edit-sections/:sectionId"
          element={<ManageSectionEdit />}
        />
        <Route path="/occurrence/section" element={<SectionOwner />} />
        <Route
          path="/occurrence/section/manage-occurrence/:occurrenceId"
          element={<ManageOccurrenceSectionOwner />}
        />
        <Route
          path="/occurrence/section/manage-occurrence/:occurrenceId/manage-sections/:sectionId"
          element={<ManageSectionSectionOwner />}
        />
        <Route
          path="/occurrence/section/manage-occurrence/:sectionId/:id/manage-content"
          element={<ContentManagement />}
        />
      </Route>

      {/* Dashboard Profile routes */}
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="/profile-settings" element={<ProfileSettings />} />
      </Route>

      {/* User Management routes */}
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="/user-management" element={<UserManagement />} />
        <Route
          path="/user-management/profile-settings"
          element={<ProfileSettings />}
        />
        <Route path="/user-management/users" element={<UserProfile />} />
        <Route path="/user-management/roles" element={<UserRoles />} />
        <Route
          path="/user-management/create-user"
          element={<CreateNewUser />}
        />
        <Route
          path="/user-management/create-role"
          element={<CreateNewRole />}
        />
        <Route
          path="/user-management/manage-role/:id"
          element={<ManageRole />}
        />
        <Route
          path="/user-management/manage-user/:id"
          element={<ManageUser />}
        />
      </Route>

      {/* Stakeholder Review Routes */}
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="/reviews" element={<ReviewHome />} />
        <Route path="/reviews/:id" element={<ReviewDetail />} />
      </Route>

      {/* User Data Routes */}
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="/user-data" element={<UserData />} />
      </Route>
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="/user-data/:id" element={<SuperUserDataView />} />
      </Route>

      {/* ICAO INTERIM Routes */}
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="/icao" element={<ICAOInterim />} />
      </Route>
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route
          path="/icao/users/:occurrenceId"
          element={<ICAOInterimUsers />}
        />
        <Route path="/icao/submit/:id" element={<ICAOSubmitForm />} />
        <Route
          path="/icao/:occurrenceId/users/:userId"
          element={<ICAODetail />}
        />
        <Route path="/icao/add/:occurrenceId" element={<AddIcao />} />
      </Route>

      {/* Role Data Routes */}
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="/role-data" element={<RoleData />} />
      </Route>
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="/role-data/:id" element={<RoleDetail />} />
      </Route>

      {/* Reset Request routes */}
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="/reset-requests" element={<ResetRequest />} />
        <Route path="/reset-requests/:id" element={<RequestDetail />} />
      </Route>

      {/* Dashboard Media Library routes */}
      <Route
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        {/* <Route path="/media" element={<MediaIndex />} /> */}
        <Route path="/media" element={<MediaHome />} />
        <Route path="/media/add" element={<AddMedia />} />
        <Route path="/media/:id" element={<MediaDetail />} />
      </Route>

      {/* Dashboard Media Library route */}
      {/* <Route
        path="/media"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="" element={<MediaHome />} />
        <Route path=":id" element={<ViewMedia />} />
      </Route> */}

      {/* Occurence Index route */}
      {/* <Route path="" element={<OccurrenceIndexLayout />}>
        
      </Route> */}

      <Route path="" element={<OccurenceIndex />} />

      {/* Single Occurence Index route */}
      <Route
        path="occurence-index/:occurrenceId"
        element={<OccurrenceIndexLayout singleIndex={true} />}
      >
        <Route path="" element={<SingleOccurrenceIndex />} />
        <Route path=":sectionId/:section_name" element={<ExecutiveSummary />} />
      </Route>

      <Route path="*" element={<Page404 />} />

      {/* <Route path="/occurence-index/:occurrenceId" element={<OccurrenceIndexLayout singleIndex={true} />}>
        <Route path="safety-recommendation" element={<SafetyRecommendation />} />
        <Route path="similar-occurence" element={<SimilarOccurrence />} />
      </Route> */}
    </Routes>
  );
}
