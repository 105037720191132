import React from "react";
import { Input } from "../user-management/ManageProfile";
import { Stack, Typography, Button } from "@mui/material";
import { Editor, EditorProvider, Toolbar } from "react-simple-wysiwyg";
import { ArrowLeft } from "iconsax-react";
import {
  BtnAlignCenter,
  BtnAlignLeft,
  BtnAlignRight,
  BtnBold,
  BtnItalic,
  BtnUnderline,
} from "../../components/occurrence/Synopsis";
import useFetch from "../../helpers/useFetch";
import { useNavigate, useParams } from "react-router-dom";
const CommentDetails = () => {
  const { occurrenceId, commentId } = useParams();
  const { data, isLoading } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/`
  );
  const [comment, setComment] = React.useState({});
  React.useEffect(() => {
    if (data?.data) {
      setComment(
        data?.data?.occurence_stakeholders?.find(
          (comment) => comment.email === commentId
        )
      );
    }
  }, [data]);
  const navigate = useNavigate();
  const [value, setValue] = React.useState("");

  return (
    <Stack gap={"32px"}>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>{comment?.occurrence?.title} </h2>
      </div>

      <div className="bg-white py-6 px-8 rounded-2xl">
        <Stack direction={"row"} gap={"32px"} justifyContent={"space-between"}>
          <div className="flex-1">
            <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
              Stakeholder Name
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={comment?.name}
            />
          </div>
          {/* <div className="flex-1">
            <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
              Section Title
            </Typography>
            <div className="border border-[#c3c3c3] rounded-md min-h-[55px]"></div>
          </div> */}
        </Stack>

        <div className="mt-6">
          <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
            Comment by Stakeholder
          </Typography>
          <div>
            <EditorProvider>
              <Editor disabled value={comment?.comment}>
                <Toolbar>
                  <Stack
                    direction={"row"}
                    gap={"32px"}
                    paddingLeft={"20px"}
                    paddingY={"10px"}
                    className="text-center"
                  >
                    <BtnAlignLeft />
                    <BtnAlignCenter />
                    <BtnAlignRight />
                    <BtnItalic />
                    <BtnBold />
                    <BtnUnderline />
                  </Stack>
                </Toolbar>
              </Editor>
            </EditorProvider>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <Button
          className="bg-primary py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </div>
    </Stack>
  );
};

export default CommentDetails;
