import React from "react";
import {
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  CircularProgress,
} from "@mui/material";
import { StyledTableRow } from "../../pages/user-data";
import useFetch from "../../helpers/useFetch";
import { Link } from "react-router-dom";

const columns = [
  {
    id: "name",
    label: "Full Name",
    minWidth: 120,
  },
  {
    id: "category",
    label: "Category",
    minWidth: 170,
    align: "center",
  },
  {
    id: "phone_number",
    label: "Phone Number",
    minWidth: 140,
    align: "center",
  },
  {
    id: "email",
    label: "Email",
    minWidth: 150,
    align: "center",
  },
];

export const commentColums = [
  {
    id: "email",
    label: "Email",
  },
  {
    id: "stake_holder",
    label: "Stakeholder",
    align: "center",
    minWidth: 170,
  },
  {
    id: "date",
    label: "Date",
    align: "center",
    minWidth: 170,
  },
  {
    id: "action",
    label: "Action",
    align: "center",
    minWidth: 120,
  },
];

const StakeholderPermission = ({ occurrenceId, information }) => {
  const [stakeholdersWithComments, setStakeholdersWithComments] =
    React.useState([]);

  React.useEffect(() => {
    if (information) {
      setStakeholdersWithComments(
        information?.occurence_stakeholders?.filter((data) => data?.comment)
      );
    }
  }, [information]);
  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/stakeholders/`
  );

  return (
    <Stack gap={"32px"}>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Stakeholder Permissions</h2>
      </div>

      <div className="bg-white py-6 px-8 rounded-2xl">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className="text-[#AEAEAE] font-medium"
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: "medium",
                      fontSize: "14px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 mb-10">
                      <CircularProgress />
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : data?.data?.length > 0 ? (
                data.data?.map((item, index) => (
                  <StyledTableRow key={index} hover>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className="text-gray-500 font-medium"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.id === "category" ? (
                          <Typography className="capitalize">
                            {item[column.id]?.replace(/_/g, " ")?.toLowerCase()}
                          </Typography>
                        ) : (
                          item[column.id]
                        )}
                        {/* {item[column.id]} */}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 mb-10">
                      <p>No Stakeholder added yet</p>
                    </div>
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Comments and Review</h2>
      </div>
      <TableContainer className="bg-white rounded-2xl py-6 px-8">
        <Table>
          <TableHead>
            <TableRow>
              {commentColums.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography color={"#AEAEAE"}>{column.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stakeholdersWithComments && stakeholdersWithComments.length > 0 ? (
              stakeholdersWithComments.map((comment, index) => (
                <StyledTableRow key={index}>
                  <TableCell>
                    <Typography fontSize={"14px"} fontWeight={500}>
                      {comment?.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={500}
                      align="center"
                    >
                      {comment?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize={"14px"}
                      fontWeight={500}
                      align="center"
                    >
                      {new Date(comment?.created_at)?.toLocaleDateString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div className="mx-auto w-fit">
                      <Link
                        to={`/occurrence/manage-occurrence/${occurrenceId}/appendices-comments/${comment?.email}`}
                      >
                        <Button
                          variant="contained"
                          className="bg-primary capitalize shadow-none text-sm font-bold"
                        >
                          View
                        </Button>
                      </Link>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <TableCell colSpan={commentColums.length}>
                  <Typography fontSize={"14px"} fontWeight={500} align="center">
                    No comments
                  </Typography>
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default StakeholderPermission;
