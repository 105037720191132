import React from "react";
import { Button, Stack, Dialog } from "@mui/material";
import { ArrowLeft } from "iconsax-react";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmDialog from "../../common/ConfirmDialog";
import DoneDialog from "../../common/DoneDialog";
import useFetch from "../../helpers/useFetch";
import { error } from "../../common/Notifications";
import { useSelector } from "react-redux";
import axios from "axios";
import Editor from "../../common/Editor";

const ReviewDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // DATA
  const { data, isLoading } = useFetch(
    "api/occurrence-manager/stakeholders/occurrence_by_stakeholders/"
  );
  const [occurrenceDetail, setOccurrenceDetail] = React.useState({});
  const [isReviewed, setIsReviewed] = React.useState(false);
  React.useEffect(() => {
    if (data.data) {
      const occurrence = data.data.find((occurrence) => occurrence?.id === id);
      setIsReviewed(occurrence?.is_reviewed);
      if (occurrence?.is_reviewed) {
        setReview(occurrence?.comment);
      }
      setOccurrenceDetail(occurrence);
    }
  }, [data]);

  // FORM
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [loading, setLoading] = React.useState(false);
  const [openCreateDialog, setOpenCreateDialog] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const [review, setReview] = React.useState("");

  function handleFormCheck() {
    if (!review) {
      error("Please add a comment");
      return;
    }
    setOpenCreateDialog(true);
  }
  async function handleFormSubmit() {
    if (!review) return error("Please add a comment");
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/stakeholders/${id}/stakeholder_section_review/`,
        {
          comment: review,
        },
        config
      );
      setOpenCreateDialog(false);
      setIsDone(true);
    } catch (err) {
      error(err?.response?.data?.message || err?.response?.data);
    }
    setLoading(false);
  }

  return (
    <Stack gap={"40px"}>
      <Dialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={false}
          confirmText="Review"
          title="Review Occurrence"
          isLoading={loading}
          description="Are you sure you want to review this occurrence?. You won't be able to edit it later."
          handleClose={() => setOpenCreateDialog(false)}
          handleConfirm={handleFormSubmit}
        />
      </Dialog>
      <Dialog
        open={isDone}
        onClose={() => navigate(-1)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
      >
        <DoneDialog
          title="Occurrence Reviewed"
          description="You have successfully reviewed this occurrence."
        />
      </Dialog>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>

      <div className="bg-white rounded-2xl px-8 py-6">
        <h2 className="text-xl font-semibold">
          {occurrenceDetail?.occurrence?.title}
        </h2>
      </div>

      <Stack className="py-8 px-6 bg-white rounded-2xl">
        <h2 className="text-[#D8D8D8] font-semibold pl-4">Review Details</h2>
        <div className="mt-5">
          {/* <EditorProvider>
            <Editor
              value={review}
              onChange={(e) => !isReviewed && setReview(e.target.value)}
            >
              <Toolbar>
                <Stack
                  direction={"row"}
                  gap={"32px"}
                  paddingLeft={"20px"}
                  paddingY={"10px"}
                  className="text-center"
                >
                  <BtnAlignLeft />
                  <BtnAlignCenter />
                  <BtnAlignRight />
                  <BtnItalic />
                  <BtnBold />
                  <BtnUnderline />
                </Stack>
              </Toolbar>
            </Editor>
          </EditorProvider> */}
          <Editor
            value={review}
            onChange={(e) => setReview(e)}
            readOnly={isReviewed ? true : false}
          />
        </div>
        {!isReviewed && (
          <div className="flex justify-end mt-6">
            <Button
              variant="contained"
              className="bg-primary text-white font-bold text-sm px-12 py-2.5 rounded-xl capitalize shadow-none"
              onClick={handleFormCheck}
            >
              Save Review
            </Button>
          </div>
        )}
      </Stack>
    </Stack>
  );
};

export default ReviewDetail;
