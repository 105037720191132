import { Button, CircularProgress, Stack } from "@mui/material";
import FormHeaders from "../../components/user-auth/FormHeaders";
import FormBg from "../../components/user-auth/FormBg";
import Logo from "../../assets/general/nsib_logo.png";
import bg from "../../assets/general/loginbackgroundlarge.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLoginVerify } from "../../redux/slices/users/authSlice";
import VerificationInput from "react-verification-input";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../../redux/slices/users/userSlice";
import { ArrowLeft } from "iconsax-react";

function VerifyLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const userID = useSelector((state) => state.auth.userLogin?.user_id);
  let isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { loading } = useSelector((state) => state.auth);

  async function handleSubmit() {
    try {
      dispatch(userLoginVerify({ user_id: userID, otp_number: code }))
        .unwrap()
        .then(() => {
          dispatch(fetchUser())
            .unwrap()
            .then(() => {})
            .catch((error) => {});
        })
        .catch((error) => {});
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/dashboard`);
    }
  }, [isAuthenticated]);

  return (
    <Stack
      direction={"row"}
      width={"100vw"}
      alignItems={"stretch"}
      minHeight={"100vh"}
    >
      <FormBg backgroundImage={bg} />
      <Stack
        direction={"column"}
        gap={"84px"}
        sx={{
          px: "64px",
          maxWidth: "50%",
          py: "52px",
        }}
        flex={1}
      >
        <Stack direction={"row"} gap={"8px"} alignItems={"center"}>
          <img
            src={Logo}
            // width={'55px'}
            // height={'54px'}
            alt=""
            className="h-[110px]"
          />
        </Stack>
        <Stack>
          <div
            className="flex items-center gap-1 cursor-pointer w-fit ml-6 mb-8"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft size="22" color="#000000" />
            <p>Back</p>
          </div>
          <Stack
            direction={"column"}
            gap={"16px"}
            alignItems={"center"}
            alignSelf={"center"}
            maxWidth={"500px"}
            width={"fit"}
          >
            <FormHeaders
              title={"Welcome"}
              titleColor={"#161616"}
              titleLineHeight={80}
              titleSize={64}
              descriptionColor={"#003163"}
              descriptionLineHeight={28.8}
              description={"We sent a verification code to your mail"}
              descriptionTextSize={18}
            />

            <Stack width={"100%"} gap={"6px"}>
              <VerificationInput
                length={6}
                value={code}
                onChange={setCode}
                validChars="0-9"
                placeholder="0"
                autoFocus
                // passwordMode
                classNames={{
                  container: "gap-4 p-0 m-0 h-full w-fit flex self-center",
                  character:
                    "w-14 h-14 text-2xl shadow-xSmall bg-white flex items-center justify-center rounded-full border border-[#7E7E7E]",
                  characterInactive: `text-[#7E7E7E] `,
                  characterSelected: `text-black outline-none border-[#04682C]`,
                  characterFilled: "text-black border-[#04682C]",
                }}
              />
            </Stack>

            <Button
              variant="contained"
              disableElevation
              sx={{
                backgroundColor: "#003163 !important",
                fontFamily: "PT Sans",
                textTransform: "none",
                color: "#CFE0FF",
                mt: "16px",
                alignSelf: "flex-start",
                "&:hover": {
                  backgroundColor: "#003163",
                },
              }}
              className="rounded-s-full rounded-e-full text-xl font-medium px-12 py-3"
              onClick={handleSubmit}
              disabled={loading}
              // href='/dashboard'
            >
              {loading ? (
                <CircularProgress className="w-5 h-5 text-white" />
              ) : (
                "Verify"
              )}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default VerifyLogin;
