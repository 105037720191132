import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  bgcolor: "white",
  boxShadow: 24,
  borderRadius: 6,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
    borderRadius: 0,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      {...other}
      style={{ background: "#003163", color: "white" }}
      className="flex items-center justify-between px-8 max-w-[700px] w-[640px]"
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          // sx={{
          //   position: 'absolute',
          //   right: 8,
          //   top: 8,
          //   color: (theme) => theme.palette.grey[500],
          // }}
        >
          <CloseIcon className="text-white" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function CustomModal({
  title,
  openModal,
  setOpenModal,
  children,
  handleModalClose,
}) {
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      open={openModal}
      onClose={handleModalClose}

      // sx={{ width: 640 }}
    >
      {title ? (
        <BootstrapDialogTitle
          id="custom-dialog-title"
          onClose={handleModalClose}
        >
          <p className=" font-bold text-lg">{title}</p>
        </BootstrapDialogTitle>
      ) : null}

      <div style={style} className="w-[640px] max-w-[640px] overflow-x-hidden">
        {children}
      </div>
    </Dialog>
  );
}

export default CustomModal;
