import React from "react";

import { Warning2 } from "iconsax-react";
import { Button, CircularProgress } from "@mui/material";

const ConfirmDialog = ({
  isDelete,
  isCustom,
  title,
  description,
  isLoading,
  confirmText,
  handleClose,
  handleConfirm,
}) => {
  return (
    <div className="bg-white rounded-2xl py-10  w-[90%] max-w-[32rem] mx-auto">
      <div className="text-center w-[80%] mx-auto">
        <Warning2
          size="45"
          className={`${
            isCustom
              ? "text-yellow-500"
              : isDelete
              ? "text-red-500"
              : "text-primary"
          } mx-auto`}
          variant="Bold"
        />
        <div className="mt-5">
          <h2
            className={`font-semibold text-xl ${
              isCustom
                ? "text-yellow-800"
                : isDelete
                ? "text-red-800"
                : "text-primary-700"
            }`}
          >
            {title}
          </h2>
          <p className="text-gray-400 text-sm font-medium pt-2">
            {description}
          </p>
        </div>
        <div className="mt-8 flex gap-4">
          <Button
            className="flex-1 py-2 shadow-none bg-[#A5A5A5] text-white text-[15px] font-bold rounded-xl capitalize"
            variant="contained"
            onClick={handleClose}
          >
            Discard
          </Button>
          <Button
            className={`flex-1 py-2 ${
              isDelete ? "bg-red-500" : "bg-primary"
            } shadow-none text-white text-[15px] font-bold rounded-xl capitalize`}
            variant="contained"
            onClick={handleConfirm}
          >
            {isLoading ? (
              <CircularProgress size={20} className="mx-auto" />
            ) : (
              confirmText
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
