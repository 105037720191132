import React from "react";
import {
  Stack,
  Pagination,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useFetch from "../../helpers/useFetch";
import { StyledTableRow } from "../user-data";
import { ArrowLeft } from "iconsax-react";
import { isUpperCase } from "../../utils/helpers";

const columns = [
  {
    id: "sn",
    label: "S/N",
  },
  {
    id: "role",
    label: "Roles",
    minWidth: 170,
  },
  {
    id: "user_count",
    label: "No of User",
    align: "center",
  },
  {
    id: "status",
    label: "Status",
  },
];
const RoleData = () => {
  const { data, isLoading, refetch } = useFetch("api/identity-manager/roles/");

  const navigate = useNavigate();
  function gotoRoleDetail(id) {
    navigate(`/role-data/${id}`);
  }

  // PAGINATION
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Stack>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>

      <Stack className="bg-white rounded-2xl py-8 px-10 mt-6">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell><Typography fontSize={13} color={"#AEAEAE"} variant="body2">
                     S/N
                    </Typography></TableCell> */}
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    <Typography fontSize={13} color={"#AEAEAE"} variant="body2">
                      {column.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 min-h-[20rem]">
                      <CircularProgress />
                      <div>
                        <p className="text-center animate-pulse">Loading...</p>
                      </div>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : (data?.data?.length === 0 || !data?.data) && !isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10 min-h-[20rem]">
                      <Typography
                        fontSize={14}
                        color={"#161616"}
                        variant="body2"
                      >
                        No Roles found
                      </Typography>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : (
                data?.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => (
                    <StyledTableRow
                      hover
                      key={index}
                      className="cursor-pointer"
                      onClick={() => gotoRoleDetail(row.id)}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell
                        className={`${
                          isUpperCase(row.role) ? "capitalize" : ""
                        }`}
                      >
                        {isUpperCase(row.role)
                          ? row.role?.replace("-", " ")?.toLowerCase()
                          : row.role?.replace("-", " ")}
                      </TableCell>
                      <TableCell className="text-center">
                        {row.user_count}
                      </TableCell>
                      <TableCell>
                        {row.system_role ? "Active" : "Inactive"}
                      </TableCell>
                    </StyledTableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      {!isLoading && (
        <div className="pb-4 pt-10 flex justify-center">
          <Pagination
            rowsPerPageOptions={[5, 25, 100]}
            count={Math.ceil(data.data?.length / rowsPerPage)}
            rowsPerPage={rowsPerPage}
            page={page + 1}
            color="primary"
            onChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
    </Stack>
  );
};

export default RoleData;
