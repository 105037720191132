import React from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../../../helpers/useFetch";
import {
  Stack,
  TableContainer,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Button,
} from "@mui/material";
import { StyledTableRow } from "../../user-data";
import { SectionOwnerOccurrenceColumns } from "../../../pages/occurence-management/section-owner/index";

const SectionOwnerOccurenceHome = () => {
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useFetch(
    "api/occurrence-manager/information/occurrence_by_section_member/"
  );
  return (
    <div>
      <Stack width={"100%"}>
        <div className="rounded-t-2xl bg-primary flex text-white items-center px-8 py-8 justify-between">
          <Typography fontSize={"20px"} className="font-semibold">
            Newly Assigned Sections (
            {
              data?.data?.filter((val) =>
                val.occurrence_status?.toLowerCase()?.includes("progress")
              )?.length
            }
            )
          </Typography>
        </div>
        <Stack
          justifyContent={data?.data?.length < 1 ? "center" : "flex-start"}
          className="bg-white rounded-b-2xl p-6"
        >
          <TableContainer className="min-h-[300px]">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {SectionOwnerOccurrenceColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        color={"#AEAEAE"}
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <StyledTableRow>
                    <TableCell
                      colSpan={SectionOwnerOccurrenceColumns?.length}
                      align="center"
                    >
                      <div className="mt-10 mb-10">
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ) : data.data?.filter((val) =>
                    val.occurrence_status?.toLowerCase()?.includes("progress")
                  )?.length ? (
                  data?.data
                    ?.filter((val) =>
                      val.occurrence_status?.toLowerCase()?.includes("progress")
                    )
                    ?.splice(0, 10)
                    ?.map((row) => (
                      <StyledTableRow
                        key={row.occurenceNumber}
                        sx={{ height: "68px" }}
                      >
                        {SectionOwnerOccurrenceColumns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "action" ? (
                                <Button
                                  variant="contained"
                                  className="bg-primary capitalize font-bold text-sm px-7 shadow-none rounded-xl"
                                  onClick={() =>
                                    navigate(
                                      `/occurrence/section/manage-occurrence/${row.id}`
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                              ) : column.id === "submission_date" ? (
                                <span>{row?.icao_deadline_date}</span>
                              ) : (
                                <Typography fontSize={14}>{value}</Typography>
                              )}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    ))
                ) : (
                  <StyledTableRow>
                    <TableCell
                      colSpan={SectionOwnerOccurrenceColumns?.length}
                      align="center"
                    >
                      <div className="mt-10 mb-10">
                        <p>No new section assigned to you yet</p>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <Stack width={"100%"} className="mt-12">
        <div className="rounded-t-2xl bg-primary flex text-white items-center px-8 py-8 justify-between">
          <Typography fontSize={"20px"} className="font-semibold">
            Completed Sections (
            {
              data?.data?.filter(
                (val) =>
                  !val.occurrence_status?.toLowerCase()?.includes("progress")
              )?.length
            }
            )
          </Typography>
        </div>
        <Stack
          justifyContent={data?.data?.length < 1 ? "center" : "flex-start"}
          className="bg-white rounded-b-2xl p-6"
        >
          <TableContainer className="min-h-[300px]">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {SectionOwnerOccurrenceColumns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={500}
                        color={"#AEAEAE"}
                      >
                        {column.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <StyledTableRow>
                    <TableCell
                      colSpan={SectionOwnerOccurrenceColumns?.length}
                      align="center"
                    >
                      <div className="mt-10 mb-10">
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </StyledTableRow>
                ) : data.data?.filter(
                    (val) =>
                      !val.occurrence_status
                        ?.toLowerCase()
                        ?.includes("progress")
                  )?.length ? (
                  data?.data
                    ?.filter(
                      (val) =>
                        !val.occurrence_status
                          ?.toLowerCase()
                          ?.includes("progress")
                    )
                    ?.splice(0, 10)
                    ?.map((row) => (
                      <StyledTableRow
                        key={row.occurenceNumber}
                        sx={{ height: "68px" }}
                      >
                        {SectionOwnerOccurrenceColumns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "action" ? (
                                <Button
                                  variant="contained"
                                  className="bg-primary capitalize font-bold text-sm px-7 shadow-none rounded-xl"
                                  onClick={() =>
                                    navigate(
                                      `/occurrence/section/manage-occurrence/${row.id}`
                                    )
                                  }
                                >
                                  View
                                </Button>
                              ) : column.id === "submission_date" ? (
                                <span>{row?.icao_deadline_date}</span>
                              ) : (
                                <Typography fontSize={14}>{value}</Typography>
                              )}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    ))
                ) : (
                  <StyledTableRow>
                    <TableCell
                      colSpan={SectionOwnerOccurrenceColumns?.length}
                      align="center"
                    >
                      <div className="mt-10 mb-10">
                        <p>You have no completed section yet</p>
                      </div>
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </div>
  );
};

export default SectionOwnerOccurenceHome;
