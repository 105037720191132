import React from "react";
import ConfirmDialog from "../../common/ConfirmDialog";
import DoneDialog from "../../common/DoneDialog";

import {
  Stack,
  Typography,
  FormControl,
  Button,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { Input } from "../user-management/ManageProfile";
import useFetch from "../../helpers/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import profileImg from "../../assets/emojis/girl-with-nose-ring.svg";
import { isUpperCase } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { error } from "../../common/Notifications";
import { ArrowLeft } from "iconsax-react";
import axios from "axios";

const UserDetail = () => {
  const { id } = useParams();
  const userRoles = useSelector((state) => state.users?.user?.roles);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [isSuperUser, setIsSuperUser] = React.useState(false);
  const [confirmActivation, setConfirmActivation] = React.useState(false);
  const [confirmDeactivation, setConfirmDeactivation] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const [doneDetails, setDoneDetails] = React.useState({
    title: "Request Successful",
    message: "Your request has been set",
  });

  const [requestIsLoading, setRequestLoading] = React.useState(false);

  async function handleAction(type) {
    setRequestLoading(true);
    try {
      const config = {
        headers: {
          "Authorization": `JWT ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      };

      if (type === "activate") {
        await axios.put(
          `${process.env.REACT_APP_BASE_API}api/user/${id}/activate_user/`,
          null,
          config
        );
        setConfirmActivation(false);
        setDoneDetails({
          title: "Successfully Activated",
          message: "You have successfully activated this user",
        });
      } else {
        await axios.put(
          `${process.env.REACT_APP_BASE_API}api/user/${id}/deactivate_user/`,
          null,
          config
        );
        setConfirmDeactivation(false);
        setDoneDetails({
          title: "Successfully Deactivated",
          message: "You have successfully deactivated this user",
        });
      }
      setIsDone(true);
    } catch (err) {
      error(err?.response?.data?.error || "Something went wrong");
    }
    setRequestLoading(false);
  }

  React.useEffect(() => {
    if (userRoles?.role?.toLowerCase()?.includes("super-user")) {
      setIsSuperUser(true);
    }
  }, [userRoles]);
  const {
    data: data,
    isLoading: isLoading,
    refetch: refetch,
  } = useFetch(`api/user/${id}/`);
  const navigate = useNavigate();

  return (
    <Stack>
      <Dialog
        open={confirmActivation}
        onClose={() => setConfirmActivation(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "450px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={false}
          confirmText="Activate"
          title="Activate User"
          isLoading={requestIsLoading}
          description="Are you sure you want to activate this user?"
          handleClose={() => setConfirmActivation(false)}
          handleConfirm={() => handleAction("activate")}
        />
      </Dialog>
      <Dialog
        open={confirmDeactivation}
        onClose={() => setConfirmDeactivation(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "450px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={true}
          confirmText="Deactivate"
          title="Deactivate User"
          isLoading={requestIsLoading}
          description="Are you sure you want to deactivate this user?"
          handleClose={() => setConfirmDeactivation(false)}
          handleConfirm={() => handleAction("deactivate")}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => {
          setIsDone(false), navigate(-1);
        }}
      >
        <DoneDialog
          title={doneDetails.title}
          description={doneDetails.message}
        />
      </Dialog>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>

      <Stack
        px={"36px"}
        py={"40px"}
        marginBottom={"36px"}
        sx={{
          background: "#fff",
          borderRadius: "16px",
          marginTop: "24px",
        }}
      >
        <div className="font-semibold text-xl border-b pb-3 flex items-center justify-between">
          <h2>User Setting</h2>
          {isLoading ? (
            <Icon icon="ep:loading" className="text-lg animate-spin inline" />
          ) : (
            <div
              className={`${
                data?.data?.is_active ? "bg-green-50" : "bg-red-50"
              } px-5 rounded-3xl py-2`}
            >
              <p
                className={`${
                  data?.data?.is_active ? "text-green-600" : "text-red-700"
                } text-xs font-medium`}
              >
                {data?.data?.is_active ? "Active User" : "Inactive User"}
              </p>
            </div>
          )}
        </div>
        <Stack
          width={"100%"}
          columnGap={"40px"}
          alignItems={"center"}
          marginTop={"24px"}
          sx={{
            alignItems: "center",
            paddingRight: "40px",
            paddingBottom: "40px",
            justifyContent: "space-between",
          }}
          rowGap={"16px"}
          direction={"row"}
        >
          <Stack width={"70%"} minWidth={"70%"} gap={"18px"}>
            {isLoading ? (
              <div className="flex items-center justify-center h-full">
                <div className="text-center">
                  <CircularProgress />
                  <p>Loading...</p>
                </div>
              </div>
            ) : (
              <>
                <Stack width={"100%"} direction={"row"} gap={"48px"}>
                  <Stack width={"100%"} gap={"6px"}>
                    <Typography variant="p" color={"#D8D8D8"}>
                      First Name
                    </Typography>

                    <Input
                      disabled={true}
                      variant="outlined"
                      fullWidth
                      placeholder={"First Name"}
                      value={data?.data?.first_name}
                    />
                  </Stack>

                  <Stack width={"100%"} gap={"6px"}>
                    <Typography variant="p" color={"#D8D8D8"}>
                      Last Name
                    </Typography>

                    <Input
                      disabled={true}
                      value={data?.data?.last_name}
                      fullWidth
                      placeholder={"Idris"}
                    />
                  </Stack>
                </Stack>

                <Stack width={"100%"} direction={"row"} gap={"48px"}>
                  <Stack width={"100%"} gap={"6px"}>
                    <Typography variant="p" color={"#D8D8D8"}>
                      Email Address
                    </Typography>

                    <Input
                      disabled={true}
                      fullWidth
                      placeholder={"kamali@aib.ng.com"}
                      value={data?.data?.email}
                    />
                  </Stack>

                  <Stack width={"100%"} gap={"6px"}>
                    <Typography variant="p" color={"#D8D8D8"}>
                      Phone Number
                    </Typography>

                    <Input
                      disabled={true}
                      fullWidth
                      type="number"
                      placeholder={"081 504 044 0405"}
                      value={data?.data?.phone_number}
                    />
                  </Stack>
                </Stack>

                <Stack gap={"8px"} width={"50%"} paddingRight={"22px"}>
                  <Typography variant="p" color={"#D8D8D8"}>
                    Role Profile
                  </Typography>

                  <FormControl variant="standard">
                    <Input
                      disabled={true}
                      fullWidth
                      className={`${
                        isUpperCase(data?.data?.role) ? "capitalize" : ""
                      }`}
                      placeholder={"Super Management"}
                      value={
                        isUpperCase(data?.data?.role)
                          ? data?.data?.role?.replace("-", " ")?.toLowerCase()
                          : data?.data?.role?.replace("-", " ")
                      }
                    />
                  </FormControl>
                </Stack>
              </>
            )}
          </Stack>

          <Stack alignSelf={"flex-center"} gap={"20px"}>
            <div className="w-[184px] h-[184px] bg-red-500 rounded-full grid place-content-center">
              <img src={profileImg} width={"124px"} height={"124px"} alt="" />
            </div>
          </Stack>
        </Stack>

        {isSuperUser && !isLoading && (
          <div>
            {data?.data?.is_active ? (
              <Button
                className="text-[15px] font-bold bg-red-500 rounded-xl px-12 py-2 text-white capitalize"
                onClick={() => setConfirmDeactivation(true)}
              >
                Deactivate User
              </Button>
            ) : (
              <Button
                className="text-white px-12 py-2 bg-primary rounded-xl capitalize text-[15px] font-bold"
                onClick={() => setConfirmActivation(true)}
              >
                Activate User
              </Button>
            )}
          </div>
        )}
      </Stack>
    </Stack>
  );
};

export default UserDetail;
