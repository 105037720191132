import React from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Pagination,
  CircularProgress,
  Dialog,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../helpers/useFetch";
import { ArrowLeft, Warning2 } from "iconsax-react";
import { StyledTableRow } from "../user-data";
import { error } from "../../common/Notifications";
import { useSelector, useDispatch } from "react-redux";
import { setTitle } from "../../redux/slices/users/titleSlice";
import DoneDialog from "../../common/DoneDialog";
import axios from "axios";

function ICAOInterimUsers() {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const { occurrenceId } = useParams();
  const { data, isLoading } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/icaos_users/`
  );

  const { data: occurrenceDetail, isLoading: isLoadingOccurrence } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/`
  );

  React.useEffect(() => {
    dispatch(
      setTitle({
        isLoading: isLoadingOccurrence,
        title: ""  ,
      })
    );

    // occurrenceDetail?.data?.occurrence_report_status

    return () => {
      dispatch(setTitle({ isLoading: false, title: "" }));
    };
  }, [occurrenceDetail, isLoadingOccurrence]);
  const navigate = useNavigate();

  // PAGINATION

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // SUBMIT TO ICAO
  const [confirmSubmit, setConfirmSubmit] = React.useState(false);
  const [loading, setLoading] = React.useState();
  const [isDone, setIsDone] = React.useState(false);

  async function handleSubmit(type) {
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      setLoading(type);
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-report/email-icao/`,
        {
          occurrence_id: occurrenceId,
          type: type,
        },
        config
      );
      setConfirmSubmit(false);
      setIsDone(true);
    } catch (err) {
      error(err?.response?.data?.message);
    }
    setLoading(undefined);
  }

  return (
    <Stack gap={"24px"}>
      <Dialog
        open={confirmSubmit}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "650px",
          },
        }}
        onClose={() => setConfirmSubmit(false)}
      >
        <div className="bg-white rounded-2xl px-10 py-8 text-center">
          <Warning2
            size="45"
            className="text-yellow-800 mx-auto"
            variant="Bold"
          />
          <div className="mt-3">
            <h2 className="font-semibold text-xl">Submit to ICAO users</h2>
            <p className="text-gray-500 pt-1 text-sm">
              Confirm the option you'd like to submit
            </p>
          </div>
          {/* // disabled={
            //   data?.data &&
            //   data?.data[0]?.occurrence?.occurrence_report_status ===
            //     "INTERIM_STATEMENT"
            // } */}
          <div className="mt-8 flex gap-3 justify-center">
            <Button
              variant="contained"
              className="px-6 rounded-xl font-bold text-sm py-2.5 bg-yellow-700 text-white capitalize shadow-none"
              onClick={() => handleSubmit("Preliminary Report")}
            >
              {loading === "Preliminary Report" ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Preliminary Report"
              )}
            </Button>
            <Button
              variant="outlined"
              className="px-6 rounded-xl font-bold text-sm py-2.5 capitalize border-2 border-primary text-primary shadow-none disabled:text-gray-300 disabled:border-gray-300"
              onClick={() => handleSubmit("Interim Report")}
            >
              {loading === "Interim Report" ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Interim Statement"
              )}
            </Button>
            <Button
              variant="contained"
              className="px-6 rounded-xl font-bold text-sm py-2.5 bg-primary text-white capitalize shadow-none"
              onClick={() => handleSubmit("Draft Final Report")}
            >
              {loading === "Draft Final Report" ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Final Report"
              )}
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => setIsDone(false)}
      >
        <DoneDialog
          title="Successfully Submitted"
          description="You have successfully submitted this occurrence to ICAO."
        />
      </Dialog>
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={"8px"}
        justifyContent={"space-between"}
      >
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
        <div className="flex gap-6">
          {data?.data?.length > 0 && (
            <Button
              variant="outlined"
              className="text-primary border-primary rounded-2xl font-bold text-sm px-8 py-2.5 shadow-none capitalize"
              onClick={() => setConfirmSubmit(true)}
            >
              Submit to ICAO
            </Button>
          )}
          <Button
            variant="contained"
            className="text-white bg-primary rounded-2xl font-bold text-sm px-10 py-2.5 shadow-none capitalize"
            onClick={() => navigate(`/icao/add/${occurrenceId}`)}
          >
            Add ICAO
          </Button>
        </div>
      </Stack>

      <Stack
        minHeight={"500px"}
        marginTop={"24px"}
        sx={{
          background: "#fff",
          borderRadius: "16px",
          px: "42px",
          py: "18px",
        }}
      >
        <TableContainer className="mt-6">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="text-[#AEAEAE] min-w-[180px]">
                  S/N
                </TableCell>
                <TableCell className="text-[#AEAEAE] min-w-[250px]">
                  Name
                </TableCell>
                <TableCell className="text-[#AEAEAE] min-w-[200px]">
                  Email
                </TableCell>
                <TableCell className="text-[#AEAEAE] min-w-[150px]">
                  Phone Number
                </TableCell>
                <TableCell
                  className="text-[#AEAEAE] min-w-[180px]"
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={5} align="center">
                    <div className=" min-h-[20rem] flex items-center justify-center ">
                      <div>
                        <CircularProgress />
                        <p className="text-center animate-pulse mt-2">
                          Loading...
                        </p>
                      </div>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : data?.data?.length === 0 ? (
                <StyledTableRow>
                  <TableCell colSpan={5} align="center">
                    <div className="mt-10 mb-10">
                      <p className="text-center font-semibold">No ICAO User</p>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : (
                data?.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => (
                    <StyledTableRow key={row?.id}>
                      <TableCell className="text-sm font-medium">
                        {index + 1}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        {row?.name}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        {row?.email}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        {row?.phone}
                      </TableCell>
                      <TableCell>
                        <div className="flex justify-center">
                          <Button
                            variant="contained"
                            className="cursor-pointer bg-primary capitalize font-bold text-sm shadow-none"
                            onClick={() =>
                              navigate(`/icao/${occurrenceId}/users/${row?.id}`)
                            }
                          >
                            View
                          </Button>
                        </div>
                      </TableCell>
                    </StyledTableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {data?.data?.length > 0 && (
        <div className="py-4 flex justify-center mt-6">
          <Pagination
            rowsPerPageOptions={[5, 25, 100]}
            count={Math.ceil(data?.data?.length / rowsPerPage)}
            rowsPerPage={rowsPerPage}
            color="primary"
            page={page + 1}
            onChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
    </Stack>
  );
}

export default ICAOInterimUsers;
