import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetch from "../../helpers/useFetch";
import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { StyledTableRow } from "../../pages/user-data";
import { Edit2 } from "iconsax-react";
import { useSelector } from "react-redux";

const columns = [
  {
    id: "occurence_number",
    label: "Occurence Number",
    minWidth: 170,
    align: "center",
  },
  {
    id: "title",
    label: "Title",
    minWidth: 170,
    align: "center",
  },
  {
    id: "category",
    label: "Category",
    minWidth: 170,
    align: "center",
  },
  {
    id: "action",
    label: "Action",
    minWidth: 50,
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 120,
    align: "center",
  },
];

const OccurrenceManagerHome = () => {
  const { data, isLoading } = useFetch("api/occurrence-manager/information/");
  const { data: mediaData, isLoading: loadingMedia } =
    useFetch("api/media-library/");
  const user = useSelector((state) => state.users?.user);

  const navigate = useNavigate();
  return (
    <div className="mt-8">
      <div className="bg-primary rounded-t-2xl px-4 py-6 text-white flex items-center justify-between gap-4">
        <h2>Occurrence ({data?.data?.length})</h2>
        <Link to="/occurrence" className="text-sm">
          See all
        </Link>
      </div>
      <TableContainer className="bg-white rounded-b-2xl px-5">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography className="text-[#AEAEAE] font-medium text-sm">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && !data.data ? (
              <StyledTableRow>
                <TableCell colSpan={columns?.length} align="center">
                  <div className="mt-10 mb-10">
                    <CircularProgress />
                    <div>
                      <p className="text-center animate-pulse">Loading...</p>
                    </div>
                  </div>
                </TableCell>
              </StyledTableRow>
            ) : data?.data?.length === 0 ? (
              <StyledTableRow>
                <TableCell colSpan={columns?.length} align="center">
                  <div className="mt-10 mb-10">
                    <p className="text-center">No data found</p>
                  </div>
                </TableCell>
              </StyledTableRow>
            ) : (
              data?.data?.slice(0, 6)?.map((item, index) => (
                <StyledTableRow key={index}>
                  <TableCell align="center">
                    <Typography className="font-medium text-sm">
                      {item.occurrence_number}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="font-medium text-sm">
                      {item.title}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="font-medium text-sm">
                      {item.occurrence_category}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Edit2
                      size="24"
                      variant="Bold"
                      className="mx-auto cursor-pointer text-sm"
                      onClick={() =>
                        navigate(
                          `/occurrence/manage-occurrence/${item.id}/manage-sections`
                        )
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Typography className="font-medium text-xs">
                      <div
                        className={`px-3 py-1 w-fit mx-auto rounded-full ${
                          item.occurrence_status === "PENDING"
                            ? "text-yellow-700 bg-yellow-200/40"
                            : item?.occurrence_status === "PUBLISHED"
                            ? "text-green-700 bg-green-200/40"
                            : "text-yellow-700 bg-yellow-200/40"
                        }`}
                      >
                        {item.occurrence_status?.toLowerCase()}
                      </div>
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="flex items-center justify-between gap-4 py-5 px-2 mt-6 font-semibold">
        <h2>Media Library ({mediaData?.data?.length})</h2>
        <Link to="/media" className="text-sm text-[#ABABAB]">
          See all
        </Link>
      </div>
      {loadingMedia ? (
        <div className="flex justify-center mt-10">
          <CircularProgress />
        </div>
      ) : mediaData?.data?.length > 0 ? (
        <Stack className="grid grid-cols-3 xl:grid-cols-4 gap-6">
          {mediaData?.data?.map((item, index) => {
            return (
              <div key={index} className="bg-white py-4 px-4 rounded-2xl">
                {item?.media_type === "IMAGE" ? (
                  <img
                    src={item?.url}
                    alt={item?.alt_text}
                    className="w-full h-[12rem] object-cover rounded-lg cursor-pointer"
                    onClick={() => navigate(`/media/${item?.id}`)}
                  />
                ) : (
                  <video
                    src={item?.url}
                    alt={item?.alt_text}
                    className="w-full h-[12rem] object-cover rounded-lg cursor-pointer"
                    onClick={() => navigate(`/media/${item?.id}`)}
                  />
                )}
                <div className="mt-2 flex justify-between items-center min-w-0 gap-2">
                  <div className="min-w-0">
                    <h2 className="font-semibold truncate">{item?.title}</h2>
                    <p className="text-gray-600 truncate">{item?.slug}</p>
                  </div>
                  <Edit2
                    className="cursor-pointer"
                    size="24"
                    variant="Bold"
                    onClick={() => navigate(`/media/${item?.id}`)}
                  />
                </div>
              </div>
            );
          })}
        </Stack>
      ) : (
        <div className="py-10">
          <p className="text-center">No data found</p>
        </div>
      )}
    </div>
  );
};

export default OccurrenceManagerHome;
