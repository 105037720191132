import React from 'react'
import { Box } from '@mui/material'
import logo from '../../assets/general/logo-combined.png'


export default function FormBg({ backgroundImage }) {
  return (
    <Box flex={1} sx={{
      background: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <img style={{ marginTop: '52px', marginLeft: '64px' }} src={logo} alt="" />
    </Box>
  )
}
