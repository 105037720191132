import React from "react";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useFetch from "../../helpers/useFetch";
import { useSelector } from "react-redux";
import { ArrowLeft } from "iconsax-react";
import { StyledTableRow } from "../user-data";
import OccurrencePendingTable from "../../components/occurrence/OccurrencePendingTable";
import SectionOwnerOccurenceHome from "./section-owner/SectionOwnerOccurenceHome";
import StakeholderOccurrenceHome from "../../components/occurrence/StakeholderOccurrenceHome";
import OccurrenceForReview from "../../components/occurrence/OccurrenceForReview";

function OccurenceHome() {
  const { data, isLoading, refetch } = useFetch(
    "api/occurrence-manager/information/"
  );
  const userRoles = useSelector((state) => state.users?.user?.roles);
  const user = useSelector((state) => state.users?.user);
  const [isSuperUser, setIsSuperUser] = React.useState(false);
  const [isSectionOwner, setIsSectionOwner] = React.useState(false);
  const [isOccurrenceManager, setIsOccurrenceManager] = React.useState(false);
  const [isStakeholder, setIsStakeholder] = React.useState(false);
  React.useEffect(() => {
    if (userRoles?.role?.toLowerCase()?.includes("super-user")) {
      setIsSuperUser(true);
    } else if (
      userRoles?.role?.toLowerCase()?.includes("occurence-manager/iic")
    ) {
      setIsOccurrenceManager(true);
    } else if (userRoles?.role?.toLowerCase()?.includes("section-owner")) {
      setIsSectionOwner(true);
    } else if (userRoles?.role?.toLowerCase()?.includes("stakeholder")) {
      setIsStakeholder(true);
    }
  }, [userRoles]);

  // PAGINATION
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const navigate = useNavigate();
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [searchOccurrence, setSearchOccurrence] = React.useState({
    name: "",
  });

  function SearchOccurrenceChange(e) {
    setSearchOccurrence({ ...searchOccurrence, name: e });

    const filteredData = data?.data?.filter((item) => {
      return (
        item?.title?.toLowerCase().includes(e.toLowerCase()) ||
        item?.occurrence_number?.toLowerCase().includes(e.toLowerCase())
      );
    });
    setTableData(filteredData);
  }

  return (
    <Stack width={"100%"} gap={"24px"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
        {isSuperUser && (
          <Button
            variant="contained"
            className="bg-primary capitalize font-bold shadow-none px-12 py-2 rounded-2xl"
            onClick={() => navigate("/occurrence/create-occurrence")}
          >
            Create Occurrence
          </Button>
        )}
      </Stack>
      {isSectionOwner ? (
        <div>
          <SectionOwnerOccurenceHome />
          <OccurrenceForReview
            isLoading={isLoading}
            isSuperUser={isSuperUser}
            isOccurrenceManager={isOccurrenceManager}
            isSectionOwner={isSectionOwner}
            data={data?.data}
          />
        </div>
      ) : isStakeholder ? (
        <div>
          <StakeholderOccurrenceHome />
        </div>
      ) : (
        <div>
          {isOccurrenceManager && (
            <OccurrencePendingTable
              occurrences={data?.data
                // ?.filter(
                //   (item) =>
                //     item?.occurrence_status?.toLowerCase() ===
                //     "investigation_in_progress"
                // )
                ?.filter((data) => data?.occurrence_owner?.id == user?.id)}
              loading={isLoading}
            />
          )}
          <OccurrenceForReview
            isLoading={isLoading}
            isSuperUser={isSuperUser}
            isOccurrenceManager={isOccurrenceManager}
            isSectionOwner={isSectionOwner}
            data={data?.data}
          />
          {/* <div className=" mt-6">
            {(isOccurrenceManager || isSectionOwner) && (
              <div className="bg-primary rounded-t-2xl px-4 py-6 text-white flex items-center justify-between gap-4">
                <h2>Occurrence for review</h2>
                <Pagination
                  rowsPerPageOptions={[5, 25, 100]}
                  count={0}
                  rowsPerPage={rowsPerPage}
                  xs={{
                    "& *": {
                      color: "#ffffff",
                    },
                  }}
                  page={page + 1}
                  onChange={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </div>
            )}
            <Stack
              minHeight={"500px"}
              className={`${
                isSuperUser ? "py-5 rounded-2xl " : "py-0 rounded-b-2xl"
              }`}
              sx={{
                background: "#fff",
                px: "32px",
              }}
            >
              <TableContainer className={`${isSuperUser ? "mt-6" : "mt-0"}`}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="text-[#AEAEAE] min-w-[180px]">
                        Occurrence Number
                      </TableCell>
                      <TableCell className="text-[#AEAEAE] min-w-[250px]">
                        Title
                      </TableCell>
                      <TableCell className="text-[#AEAEAE] min-w-[200px]">
                        Occurrence Manager/IIC
                      </TableCell>
                      <TableCell className="text-[#AEAEAE]">Status</TableCell>
                      <TableCell className="text-[#AEAEAE]">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <StyledTableRow>
                        <TableCell colSpan={4} align="center">
                          <div className=" min-h-[20rem] flex items-center justify-center ">
                            <div>
                              <CircularProgress />
                              <p className="text-center animate-pulse mt-2">
                                Loading...
                              </p>
                            </div>
                          </div>
                        </TableCell>
                      </StyledTableRow>
                    ) : isOccurrenceManager || isSectionOwner ? (
                      data?.data
                        // ?.filter(
                        //   (data) => data?.occurrence_owner?.id == user?.id
                        // )
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row) => (
                          <StyledTableRow key={row?.id}>
                            <TableCell className="text-sm font-medium">
                              {row?.occurrence_number}
                            </TableCell>
                            <TableCell className="text-sm font-medium">
                              {row?.title}
                            </TableCell>
                            <TableCell className="text-sm font-medium">
                              {row?.occurrence_owner?.first_name}{" "}
                              {row?.occurrence_owner?.last_name}
                            </TableCell>
                            <TableCell className="text-sm font-medium">
                              <span
                                className={`capitalize px-3 py-2 rounded-full ${
                                  row?.occurrence_status?.toLowerCase() === ""
                                    ? "text-orange-700 bg-orange-50"
                                    : row?.occurrence_status?.toLowerCase() ===
                                      "published"
                                    ? "text-red-700 bg-red-50"
                                    : "text-green-600 bg-green-50"
                                }`}
                              >
                                {row?.occurrence_status?.toLowerCase() === ""
                                  ? "Processing"
                                  : row?.occurrence_status?.toLowerCase()}
                              </span>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                className="cursor-pointer bg-primary capitalize font-bold text-sm shadow-none"
                                onClick={() =>
                                  navigate(
                                    isOccurrenceManager
                                      ? `/occurrence/manage-occurrence/${row.id}`
                                      : `/occurrence/manage-occurrence/${row?.id}`
                                  )
                                }
                              >
                                View
                              </Button>
                            </TableCell>
                          </StyledTableRow>
                        ))
                    ) : (
                      data?.data
                        ?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row) => (
                          <StyledTableRow key={row?.id}>
                            <TableCell className="text-sm font-medium">
                              {row?.occurrence_number}
                            </TableCell>
                            <TableCell className="text-sm font-medium">
                              {row?.title}
                            </TableCell>
                            <TableCell className="text-sm font-medium">
                              {row?.occurrence_owner?.first_name}{" "}
                              {row?.occurrence_owner?.last_name}
                            </TableCell>
                            <TableCell className="text-sm font-medium">
                              <span
                                className={`capitalize px-3 py-2 rounded-full ${
                                  row?.occurrence_status?.toLowerCase() ===
                                  "pending"
                                    ? "text-orange-700 bg-orange-50"
                                    : row?.occurrence_status?.toLowerCase() ===
                                      "published"
                                    ? "text-red-700 bg-red-50"
                                    : "text-orange-700 bg-orange-50"
                                }`}
                              >
                                {row?.occurrence_status?.toLowerCase() === ""
                                  ? "Processing"
                                  : row?.occurrence_status?.toLowerCase()}
                              </span>
                            </TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                className="cursor-pointer bg-primary capitalize font-bold text-sm shadow-none"
                                onClick={() =>
                                  navigate(
                                    `/occurrence/manage-occurrence/${row?.id}`
                                  )
                                }
                              >
                                View
                              </Button>
                            </TableCell>
                          </StyledTableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </div> */}
        </div>
      )}
    </Stack>
  );
}

export default OccurenceHome;
