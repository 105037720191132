import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as auditAPI from '../../../apis/audits';

export const fetchAudit = createAsyncThunk(
    'audit/fetchAudit',
    async (id) => {
        const response = await auditAPI.auditById(id);
        return response.data;
    }
);

export const fetchAudits = createAsyncThunk(
    'audit/fetchAudits',
    async () => {
        await auditAPI.audits();
    }
);

export const fetchAuditsByModule = createAsyncThunk(
    'audit/fetchAuditsByModule',
    async (module, size) => {
        const response = await auditAPI.auditByModules(module, size);
        return response.data;
    }
);

const auditSlice = createSlice({
    name: 'audit',
    initialState: {
        audit: null,
        auditList: [],
        status: 'idle'
    },

    extraReducers: builder => {
        // single audit
        builder
            .addCase(fetchAudit.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAudit.fulfilled, (state, action) => {
                state.audit = action.payload.audit;
                state.status = 'succeeded';
            })
            .addCase(fetchAudit.rejected, (state) => {
                state.status = 'failed';
            });

        // audit list
        builder
            .addCase(fetchAudits.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAudits.fulfilled, (state, action) => {
                state.auditList = action.payload.auditList;
                state.status = 'succeeded';
            })
            .addCase(fetchAudits.rejected, (state) => {
                state.status = 'failed';
            });

        // audit list by module
        builder
            .addCase(fetchAuditsByModule.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAuditsByModule.fulfilled, (state, action) => {
                state.auditList = action.payload.auditList;
                state.status = 'succeeded';
            })
            .addCase(fetchAuditsByModule.rejected, (state) => {
                state.status = 'failed';
            });

    }
});

export default auditSlice.reducer;
