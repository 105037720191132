import React from "react";

import { Stack } from "@mui/material";
import { ArrowLeft } from "iconsax-react";
import useFetch from "../../helpers/useFetch";
import { useNavigate, useParams } from "react-router-dom";

const SubmitForm = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/information/${id}/`
  );

  const navigate = useNavigate();
  return (
    <Stack>
      {" "}
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      <Stack marginTop={"32px"} className="bg-white rounded-2xl px-12 py-6">
        <h2 className="text-xl font-semibold flex items-center gap-2">
          <span>Interim Report:</span>
          {isLoading ? (
            <span className="w-[5rem] h-4 rounded-lg bg-gray-400 inline-block animate-pulse"></span>
          ) : (
            <span>{data?.data?.title}</span>
          )}
        </h2>
      </Stack>
    </Stack>
  );
};

export default SubmitForm;
