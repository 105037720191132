import React from "react";
import OccurrenceDetails from "../../components/occurrence/manager-edits/OccurrenceDetails";
import Loading from "../../common/Loading";
import useFetch from "../../helpers/useFetch";
import { navigationList } from "./ManageOccurence";
import { Stack } from "@mui/material";
import { ArrowLeft } from "iconsax-react";
import { useNavigate, useParams } from "react-router-dom";
import Synopsis from "../../components/occurrence/Synopsis";
import FactualForm from "../../components/occurrence/manager-edits/FactualForm";
import SafetyRecommendationForm from "../../components/occurrence/manager-edits/SafetyRecommendationForm";
import StakeholderPermission from "../../components/occurrence/manager-edits/StakeholderPermission";
import SimilarOccurrence from "../../components/occurrence/manager-edits/SimilarOccurrence";
import Conclusion from "../../components/occurrence/manager-edits/Conclusion";
import AppendicesForm from "../../components/occurrence/manager-edits/AppendicesForm";
import AnalysisForm from "../../components/occurrence/manager-edits/AnalysisForm";

const ManageSections = () => {
  const { occurrenceId } = useParams();
  const navigate = useNavigate();

  // FETCHES DATA FROM API

  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/`
  );

  const navigation = React.useRef(null);
  const [activeNavigation, setActiveNavigation] =
    React.useState("occurrence_details");

  const navQuery = new URLSearchParams(window.location.search).get("nav");
  React.useEffect(() => {
    if (navQuery) {
      setActiveNavigation(navQuery);
    }
  }, [navQuery]);

  function changeNavigation(id) {
    setActiveNavigation(id);
    navigate(`?nav=${id}`);
    // SCROLL TO TOP IN REACT SMOOTH
    if (navigation.current) {
      navigation.current.scrollIntoView({ behavior: "smooth" });
    }
  }
  if (isLoading) {
    return <Loading />;
  }
  return (
    <Stack gap={"32px"}>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>

      <Stack
        ref={navigation}
        bgcolor={"#fff"}
        direction={"row"}
        justifyContent={"space-between"}
        className="py-6 px-10 rounded-2xl"
      >
        <div>
          <h2 className="text-xl font-semibold">{data?.data?.title}</h2>
        </div>

        <div className="flex gap-1 items-end font-semibold">
          <p>Due Date:</p>
          <h1 className="text-red-600 text-[28px] leading-none">
            {data?.data?.icao_deadline_date}
          </h1>
        </div>
      </Stack>

      <Stack className="overflow-x-auto">
        <ul className="flex gap-5 justify-between items-end border-b px-3 whitespace-nowrap">
          {navigationList.map((item, index) => (
            <li
              key={index}
              className={`font-medium pb-2 cursor-pointer ${
                activeNavigation === item?.id
                  ? "border-b-2 border-black"
                  : " text-[#AEAEAE]"
              }`}
              onClick={() => changeNavigation(item?.id)}
            >
              <p>{item.label}</p>
            </li>
          ))}
        </ul>
      </Stack>
      {activeNavigation === "occurrence_details" && (
        <OccurrenceDetails
          information={data?.data}
          occurrenceId={occurrenceId}
          OccurrenceDetailsId={data?.data?.occurrence_details?.id}
          goToNext={() => {
            changeNavigation("synopsis");
            refresh();
          }}
        />
      )}
      {activeNavigation === "synopsis" && (
        <Synopsis
          occurrenceId={occurrenceId}
          information={data?.data}
          occurrenceOwner={data?.data?.occurrence_owner}
          goToNext={() => changeNavigation("sections")}
          refetch={refetch}
        />
      )}
      {activeNavigation === "sections" && (
        <FactualForm
          occurrenceId={occurrenceId}
          information={data?.data}
          fetchInformation={refetch}
          goToNext={() => changeNavigation("analysis")}
        />
      )}
      {activeNavigation === "analysis" && (
        <AnalysisForm
          occurrenceId={occurrenceId}
          information={data?.data}
          occurrenceOwner={data?.data?.occurrence_owner}
          goToNext={() => changeNavigation("similar_occurrences")}
          refetch={refetch}
        />
      )}
      {activeNavigation === "similar_occurrences" && (
        <SimilarOccurrence
          occurrenceId={occurrenceId}
          occurrenceOwner={data?.data?.occurrence_owner}
          information={data?.data}
          goToNext={() => {
            changeNavigation("conclusion");
          }}
          refetch={refetch}
        />
      )}
      {activeNavigation === "conclusion" && (
        <Conclusion
          occurrenceId={occurrenceId}
          information={data?.data}
          occurrenceOwner={data?.data?.occurrence_owner}
          goToNext={() => changeNavigation("safety_recommendation")}
          refetch={refetch}
        />
      )}
      {activeNavigation === "safety_recommendation" && (
        <SafetyRecommendationForm
          occurrenceId={occurrenceId}
          information={data?.data}
          goToNext={() => {
            changeNavigation("appendices");
          }}
          refetch={refetch}
        />
      )}

      {activeNavigation === "appendices" && (
        <AppendicesForm
          occurrenceId={occurrenceId}
          information={data?.data}
          fetchInformation={refetch}
          goToNext={() => changeNavigation("stakeholder_permission")}
        />
      )}
      {activeNavigation === "stakeholder_permission" && (
        <StakeholderPermission
          occurrenceId={occurrenceId}
          information={data?.data}
        />
      )}
    </Stack>
  );
};

export default ManageSections;
