import React from "react";
// import NewspaperIcon from "@mui/icons-material/Newspaper";
// import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
// import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
// import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import Button from "@mui/material/Button";
import { Stack, CircularProgress } from "@mui/material";
import RequestTable from "../../components/dashboard/request-table";
import UserManagement from "../../components/dashboard/user-managament";
import SuperUserHome from "../../components/dashboard/super-user-home";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useFetch from "../../helpers/useFetch";
import SectionOwner from "../occurence-management/section-owner";
import Loading from "../../common/Loading";
import OccurrenceManagerHome from "../../components/dashboard/occurrence-manager-home";
import StakeholderHome from "../../components/dashboard/StakeholderHome";

// function DashboardCard({
//   title,
//   icon,
//   count,
//   countTitle,
//   secondaryIcon,
//   secondaryCount,
//   secondaryCountTitle,
//   primaryButton,
//   secondaryButton,
//   primaryHref,
//   secondaryHref,
//   viewMoreLink,
//   isLoading,
// }) {
//   const userRoles = useSelector((state) => state.users?.user?.roles);
//   return (
//     <div className="bg-white shadow-md rounded-lg p-6">
//       <div className="flex justify-between py-1">
//         <p className="font-bold">{title}</p>
//         {userRoles?.role?.toLowerCase()?.includes("super-administrator") ? (
//           title !== "Occurrence" &&
//           title !== "Media Library" && (
//             <Link to={viewMoreLink} className="text-primary">
//               View More
//             </Link>
//           )
//         ) : (
//           <Link to={viewMoreLink} className="text-primary">
//             View More
//           </Link>
//         )}
//       </div>

//       <div className="flex gap-7">
//         <div className="flex gap-2 mt-5 items-center py-1">
//           {icon}
//           {isLoading ? (
//             <p className="text-3xl font-bold bg-gray-200  h-9 w-4 rounded-md animate-pulse"></p>
//           ) : (
//             <p className="text-3xl font-bold">{count}</p>
//           )}
//           <p className="text-secondary">
//             {countTitle === "Media"
//               ? countTitle
//               : count === 1
//               ? countTitle
//               : `${countTitle}s`}
//           </p>
//         </div>

//         {secondaryIcon && (
//           <div className="flex gap-2 mt-5 items-center py-1">
//             {secondaryIcon}
//             {isLoading ? (
//               <p className="text-3xl font-bold bg-gray-200  h-6 w-4 rounded-md animate-pulse"></p>
//             ) : (
//               <p className="text-3xl font-bold">{secondaryCount}</p>
//             )}

//             <p className="text-secondary">
//               {secondaryCount === 1
//                 ? secondaryCountTitle
//                 : `${secondaryCountTitle}s`}
//             </p>
//           </div>
//         )}
//       </div>

//       {userRoles?.role?.toLowerCase()?.includes("super-administrator") ? (
//         title !== "Occurrence" &&
//         title !== "Media Library" && (
//           <div className="flex justify-between mt-5">
//             <Link to={primaryHref}>
//               <Button
//                 variant="contained"
//                 className={`bg-primary capitalize  py-4 rounded-none ${
//                   secondaryButton ? "px-4" : "px-8"
//                 }`}
//               >
//                 {primaryButton}
//               </Button>
//             </Link>

//             {secondaryButton && (
//               <Link to={secondaryHref}>
//                 <Button
//                   variant="contained"
//                   className={`bg-white border border-black capitalize text-black shadow-none py-4 rounded-none border-solid ${
//                     secondaryButton ? "px-4" : "px-8"
//                   }`}
//                 >
//                   {secondaryButton}
//                 </Button>
//               </Link>
//             )}
//           </div>
//         )
//       ) : (
//         <div className="flex justify-between mt-5">
//           <Link to={primaryHref}>
//             <Button
//               variant="contained"
//               className={`bg-primary capitalize  py-4 rounded-none ${
//                 secondaryButton ? "px-4" : "px-8"
//               }`}
//             >
//               {primaryButton}
//             </Button>
//           </Link>

//           {secondaryButton && (
//             <Link to={secondaryHref}>
//               <Button
//                 variant="contained"
//                 className={`bg-white border border-black capitalize text-black shadow-none py-4 rounded-none border-solid ${
//                   secondaryButton ? "px-4" : "px-8"
//                 }`}
//               >
//                 {secondaryButton}
//               </Button>
//             </Link>
//           )}
//         </div>
//       )}
//     </div>
//   );
// }

function Home() {
  const user = useSelector((state) => state.users);
  const { data, isLoading } = useFetch(`api/user/dashboard_count`);
  const { data: rolesData, isLoading: isLoadingRoles } = useFetch(
    "api/identity-manager/roles/"
  );
  const { data: userListData, isLoading: userRoleIsLoading } =
    useFetch("api/user/");
  const { data: requestsData, isLoading: isLoadingRequests } = useFetch(
    "api/user/all_request_form"
  );

  const userRoles = useSelector((state) => state.users?.user?.roles);

  const [superAdmin, setSuperAdmin] = React.useState(false);
  const [isSupperUser, setIsSuperUser] = React.useState(false);
  const [isOccurrenceUser, setIsOccurrenceUser] = React.useState(false);
  const [isSectionOwner, setIsSectionOwner] = React.useState(false);

  React.useEffect(() => {
    console.log("DASHBOARD", user);
    if (
      user?.user?.roles?.role?.toLowerCase()?.includes("super-administrator")
    ) {
      setSuperAdmin(true);
    } else if (user?.user?.roles?.role?.toLowerCase()?.includes("super-user")) {
      setIsSuperUser(true);
    } else if (
      user?.user?.roles?.role?.toLowerCase()?.includes("occurence-manager")
    ) {
      setIsOccurrenceUser(true);
    } else if (
      user?.user?.roles?.role?.toLowerCase()?.includes("section-owner")
    ) {
      setIsSectionOwner(true);
    }
  }, [user]);

  // if (isLoading) {
  //     return <Loading />
  // }

  return userRoles ? (
    <>
      {isLoading ? (
        <Stack
          className="bg-white rounded-2xl min-h-[calc(100dvh-200px)] grid place-content-center
        "
        >
          <div className="text-center">
            <CircularProgress />
            <p>Loading...</p>
          </div>
        </Stack>
      ) : (
        <div>
          <div>
            <h3 className="text-3xl text-[28px] font-semibold pb-2">
              Welcome, {user.user?.first_name}!
            </h3>
            <p>Continue from where you stopped the last time</p>
          </div>
          {userRoles && superAdmin ? (
            <div className="grid grid-cols-12 mt-8 gap-4">
              <div className="col-span-8">
                <div className="rounded-2xl bg-white px-4 pt-8 pb-6">
                  <div className="flex items-center justify-between gap-4">
                    <h2 className="font-semibold text-xl">
                      Password Reset Requests
                    </h2>
                    <Link
                      to="/reset-requests"
                      className="text-primary text-sm font-medium"
                    >
                      See all
                    </Link>
                  </div>
                  <div className="mt-6">
                    <RequestTable
                      requestsData={requestsData?.data}
                      isLoadingRequests={isLoadingRequests}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-4">
                <div className="rounded-2xl bg-white px-4 pt-8 pb-6">
                  <UserManagement
                    counts={data?.data}
                    roleList={rolesData?.data}
                    userList={userListData?.data}
                    roleLoading={isLoadingRoles}
                    userRoleIsLoading={userRoleIsLoading}
                    loading={isLoading}
                  />
                </div>
              </div>
            </div>
          ) : userRoles && isSupperUser ? (
            <div className="mt-8">
              <SuperUserHome
                counts={data?.data}
                userData={userListData?.data}
                userDataIsLoading={userRoleIsLoading}
                roleData={rolesData?.data}
                roleDataIsLoading={isLoadingRoles}
              />
            </div>
          ) : userRoles && isOccurrenceUser ? (
            <OccurrenceManagerHome />
          ) : userRoles && isSectionOwner ? (
            <SectionOwner />
          ) : (
            <StakeholderHome />
          )}
        </div>
      )}
    </>
  ) : (
    <CircularProgress />
  );
}

export default Home;
