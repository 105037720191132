import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

export default function DialogComponent({ dialogState, primaryActionBtnText, secondaryActionBtnText, title, content, primaryActionBtnColor, secondaryActionBtnColor, primaryBtnAction, secondaryBtnAction, handleClose }) {
    const [open, setOpen] = React.useState(dialogState);

    return (
        <Dialog
            open={dialogState}
            onClose={handleClose}
            maxWidth={'sm'}
            sx={{
                '& .MuiDialog-paperWidthSm': {
                    minWidth: '520px',
                    maxWidth: '520px',
                    minHeight: '190px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '24px',
                    gap: '4px',
                    borderRadius: '4px'
                }
            }}
        >
            <DialogTitle sx={{ p: 0 }}>
                <Typography fontSize={18} fontFamily={'PT Sans'} fontWeight={700} color={'#161616'}>
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ p: 0, height: 'fit-content' }}>
                <Typography fontSize={14} fontFamily={'PT Sans'} fontWeight={400} color={'#7e7e7e'}>
                    {content}
                </Typography>
            </DialogContent>
            <DialogActions sx={{ p: 0, marginTop: '28px', gap: '32px' }}>
                <Button disableElevation variant='contained' onClick={secondaryBtnAction} sx={{
                    '&.MuiButton-contained': {
                        color: secondaryActionBtnColor,
                        backgroundColor: 'transparent',
                        textTransform: 'none',
                        fontFamily: 'PT Sans',
                        border: '1px solid',
                        borderColor: secondaryActionBtnColor,
                        width: '120px',
                        height: '45px',
                        borderRadius: 0,
                        fontSize: 14
                    }
                }}>
                    {secondaryActionBtnText}
                </Button>
                <Button disableElevation variant='contained' onClick={primaryBtnAction} sx={{
                    '&.MuiButton-contained': {
                        color: '#ffffff',
                        textTransform: 'none',
                        fontFamily: 'PT Sans',
                        background: primaryActionBtnColor,
                        width: '120px',
                        height: '45px',
                        borderRadius: 0,
                        fontSize: 14
                    }
                }}>
                    {primaryActionBtnText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}