import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function useWebservice(url) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const baseUrl = process.env.REACT_APP_WEBSERVICE_API + url;
    const options = {
      method: "GET",
      xstfCookieName: "csrftoken",
      xsrfHeaderName: "X-CSRFToken",
      url: baseUrl,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": "csrftoken",
        "X-Requested-With": "XMLHttpRequest",
        // "Authorization": `JWT ${accessToken}`,
      },
    };
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await axios.request(options, { signal });
        setData(response.data);
        console.log(response.data, "data");
        setIsLoading(false);
      } catch (error) {
        setError(error.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchData();
    return () => {
      controller.abort();
    };
  }, [url]);

  const controllerRefetch = new AbortController();
  const signalRefetch = controllerRefetch.signal;

  function refetch() {
    setIsLoading(true);
    const baseUrl = process.env.REACT_APP_WEBSERVICE_API + url;
    const options = {
      method: "GET",
      xstfCookieName: "csrftoken",
      xsrfHeaderName: "X-CSRFToken",
      url: baseUrl,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": "csrftoken",
        "X-Requested-With": "XMLHttpRequest",
        // "Authorization": `JWT ${accessToken}`,
      },
    };
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await axios.request(options, { signalRefetch });
        setData(response?.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      controllerRefetch.abort();
    };
  }

  return { data, isLoading, error, refetch };
}

export default useWebservice;
