import React from "react";
import { Crash } from "../../../assets";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomModal from "../../../components/media/Modal";
import { Button, Stack, TextField, Typography } from "@mui/material";
import KeyInput from "../../../components/occurrence/KeyInput";
import useFetch from "../../../helpers/useFetch";
import { useOutletContext } from "react-router-dom";
import SanitizeHTML from "../../occurence-management/section-owner/SanitizeHTML";
import { useDispatch, useSelector } from "react-redux";
import { getAccessKey } from "../../../redux/slices/users/stakeholderAccessSlice";
import { error, success } from "../../../common/Notifications";
import axios from "axios";

import SimilarOccurrence from "./SimilarOccurrence";
import SafetyRecommendation from "./SafetyRecommendation";
import Loading from "../../../common/Loading";
import { ArrowLeft } from "iconsax-react";

function ExecutiveSummary() {
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [reply, setReply] = React.useState(false);
  const [selectedCommentIndex, setSelectedCommentIndex] = React.useState(null);
  const navigate = useNavigate();
  const { sectionId } = useParams();
  const { occurrenceId } = useParams();

  const [publishedData, data, isLoading, refetch] = useOutletContext();

  const accessKey = useSelector((state) => state.stakeholder.access);
  const stakeHolderEmail = useSelector((state) => state.stakeholder.email);

  const [emailComment, setEmailComment] = React.useState(stakeHolderEmail);

  const {
    data: dataComments,
    isLoading: isLoadingComments,
    refetch: isLoadingRefresh,
  } = useFetch(`api/occurrence-manager/comment/${sectionId}/`);

  const [sectionData, setSectionData] = React.useState([]);

  const initialCommentText = {
    content: "",
    section_page: sectionId,
    email: `${stakeHolderEmail}`,
    reply: "",
  };

  const [commentText, setCommentText] = React.useState(initialCommentText);
  const [replyText, setReplyText] = React.useState(initialCommentText);

  const [auth, setAuth] = React.useState(false);
  // const [openKeyModal, setOpenKeyModal] = React.useState(!accessKey);
  const [openKeyModal, setOpenKeyModal] = React.useState(
    sectionData[0]?.published_status
  );
  const [key, setKey] = React.useState("");
  const [keyAccess, setKeyAccess] = React.useState(accessKey);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setCommentText({ ...commentText, email: stakeHolderEmail });
  }, [stakeHolderEmail]);

  React.useEffect(() => {
    setKeyAccess(accessKey);
  }, [accessKey]);

  const handleKeySuccess = () => {
    dispatch(getAccessKey({ occurrenceId: occurrenceId, key: key }));
  };

  React.useEffect(() => {
    if (!isLoading) {
      const published_status = data?.data?.occurrence_section.filter(
        (obj) => obj.id === sectionId
      );
      setSectionData(published_status);
      setOpenKeyModal(!accessKey && !published_status[0]?.published_status);
      console.log(published_status);
    }
  }, [isLoading, data, sectionId, accessKey]);

  console.log(openKeyModal, "open key");

  async function handleCommentSubmit() {
    console.log(commentText, "<<<<comment text>>>");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/comment-stakeholder/`,
        commentText,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": "csrftoken",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `JWT ${accessToken}`,
          },
        }
      );
      success(res.data.message);
      setLoading(false);
      setCommentText(initialCommentText);
      isLoadingRefresh();
    } catch (err) {
      error(err.response?.data?.message);
      setLoading(false);
    }
  }

  async function handleReplySubmit(reply) {
    const updatedcomment = { ...replyText };
    updatedcomment.reply = reply;
    setReplyText(updatedcomment);

    console.log(replyText, "reply text");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/comment-stakeholder/`,
        updatedcomment,
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": "csrftoken",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `JWT ${accessToken}`,
          },
        }
      );
      success(res.data.message);
      setLoading(false);
      setReplyText(initialCommentText);
      isLoadingRefresh();
    } catch (err) {
      error(err.response?.data?.message);
      setLoading(false);
      console.log(err);
    }
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="">
      <Stack marginBottom={"20px"} marginTop={"10px"}>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-3"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      {sectionData[0]?.slug_name === "similar-occurrence" ? (
        <SimilarOccurrence />
      ) : sectionData[0]?.slug_name === "safety-recommendations" ? (
        <SafetyRecommendation sectionData={sectionData[0]} />
      ) : sectionData[0]?.occurrence_page_section.length > 0 ? (
        sectionData[0]?.occurrence_page_section.map((section, index) => (
          <div key={index} className="mb-5">
            <div>
              <p className="font-bold text-2xl">
                {section?.title || section?.section_name}
              </p>
            </div>

            {section?.occurrence_page_section_contents?.length > 0 ? (
              section?.occurrence_page_section_contents?.map((item, index) => (
                <div
                  className="mt-6 bg-white p-8 rounded-xl shadow-md"
                  key={index}
                >
                  <p className="font-bold text-base">
                    {item?.title || section?.section_name}
                  </p>
                  <hr className="my-3" />
                  {item.page_type === "TEXT" ? (
                    <SanitizeHTML html={`${item?.content}`} />
                  ) : item?.page_type === "IMAGE" ? (
                    <>
                      <div className="mt-3 px-36 flex flex-col items-center justify-center">
                        <div className="w-[750px] h-[450px]">
                          <img
                            src={item?.url}
                            alt={item?.image_alt_text}
                            className="w-full h-full"
                          />
                        </div>
                      </div>
                      <div className="my-3 mx-32 ">
                        <div className="p-2.5 bg-primary/5 border-l-2 border-primary">
                          <p>{item?.image_title}</p>
                        </div>
                        {item?.image_foot_note && (
                          <p className="mt-2.5 font-sm">
                            Footnote: {item?.image_foot_note}
                          </p>
                        )}
                      </div>
                    </>
                  ) : item?.page_type === "VIDEO" ? (
                    <>
                      <div className="mt-3 px-36 flex flex-col items-center justify-center">
                        <div className="w-[750px] h-[450px]">
                          <video
                            style={{ borderRadius: "8px", border: 0 }}
                            className="h-full w-full"
                            controls
                          >
                            <source src={item?.url} type="video/mp4" />
                          </video>
                        </div>
                      </div>
                      <div className="my-3 mx-32 ">
                        <div className="p-2.5 bg-primary/5 border-l-2 border-primary">
                          <p>{item?.image_title}</p>
                        </div>
                        {item.image_foot_note && (
                          <p className="mt-2.5 font-sm">
                            Footnote: {item?.image_foot_note}
                          </p>
                        )}
                      </div>
                    </>
                  ) : item?.page_type === "DATATABLE" ? (
                    <SanitizeHTML html={`${item?.content}`} />
                  ) : item?.page_type === "MAP" ? (
                    <div className="w-full h-full">
                      <iframe
                        referrerPolicy="no-referrer-when-downgrade"
                        src={item?.link}
                        style={{
                          height: "375px",
                          width: "100%",
                          borderRadius: "0 0 4px 4px",
                        }}
                      ></iframe>
                    </div>
                  ) : item?.page_type === "URL" ? (
                    <>
                      <Typography
                        fontSize={14}
                        color={"#161616"}
                        fontWeight={700}
                      >
                        {item?.title}
                      </Typography>

                      <Link
                        to={item?.link}
                        style={{
                          fontSize: 14,
                          fontFamily: "PT Sans",
                          color: "#003163",
                        }}
                      >
                        {item?.link}
                      </Link>
                    </>
                  ) : null}
                </div>
              ))
            ) : (
              <div>No section contents yet</div>
            )}
          </div>
        ))
      ) : (
        <div>No data yet</div>
      )}
      {sectionData[0]?.occurrence_section_media &&
        sectionData[0]?.occurrence_section_media?.length > 0 &&
        sectionData[0]?.occurrence_section_media?.map((item, index) =>
          item?.media_type === "IMAGE" ? (
            <>
              <div className="mt-3 px-36 flex flex-col items-center justify-center">
                <div className="w-[750px] h-[450px]">
                  <img
                    src={item?.url}
                    alt={item?.alt_text}
                    className="w-full h-full"
                  />
                </div>
              </div>
              <div className="my-3 mx-32 ">
                <div className="p-2.5 bg-primary/5 border-l-2 border-primary">
                  <p>{item?.title}</p>
                </div>
                {item?.foot_note && (
                  <p className="mt-2.5 font-sm">Footnote: {item?.foot_note}</p>
                )}
              </div>
            </>
          ) : item?.media_type === "VIDEO" ? (
            <>
              <div className="mt-3 px-36 flex flex-col items-center justify-center">
                <div className="w-[750px] h-[450px]">
                  <video
                    style={{ borderRadius: "8px", border: 0 }}
                    className="h-full w-full"
                    controls
                  >
                    <source src={item?.url} type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="my-3 mx-32 ">
                <div className="p-2.5 bg-primary/5 border-l-2 border-primary">
                  <p>{item?.title}</p>
                </div>
                {item.foot_note && (
                  <p className="mt-2.5 font-sm">Footnote: {item?.foot_note}</p>
                )}
              </div>
            </>
          ) : null
        )}
      {/* Comments */}
      {keyAccess && (
        <div className="mt-6 bg-white p-8 rounded-xl shadow-md">
          <p className="font-bold text-base">
            {dataComments?.data?.length} Comments
          </p>
          <hr className="my-3" />

          <div>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Add comments"
              variant="filled"
              value={commentText?.content}
              fullWidth
              size="small"
              multiline={Infinity}
              rows={6}
              onChange={(e) => {
                setCommentText({ ...commentText, content: e.target.value });
              }}
            />

            <Button
              variant="contained"
              className="bg-primary capitalize py-2.5 rounded-none px-7  text-sm font-normal my-6 "
              onClick={handleCommentSubmit}
            >
              Comment
            </Button>
          </div>

          <div className="space-y-6">
            {dataComments?.data?.map((item, index) => (
              <div className="space-y-2">
                <p className="font-bold text-xs">
                  {item?.name}
                  <span className="font-normal italic text-secondary ml-4">
                    {item.time}
                  </span>
                </p>
                <p className="text-sm">{item?.content}</p>

                <button
                  className="text-xs text-primary/60 italic"
                  onClick={() => {
                    setReply(!reply);
                    setSelectedCommentIndex(index);
                  }}
                >
                  Reply ({item?.reply_count})
                </button>

                {reply && selectedCommentIndex === index && (
                  <>
                    <div>
                      <TextField
                        id="input-with-icon-textfield"
                        placeholder="Add reply"
                        variant="filled"
                        value={replyText?.content}
                        fullWidth
                        size="small"
                        multiline={Infinity}
                        rows={6}
                        onChange={(e) => {
                          setReplyText({
                            ...replyText,
                            content: e.target.value,
                          });
                        }}
                      />
                      <Button
                        variant="contained"
                        className="bg-primary capitalize py-2.5 rounded-none px-7  text-sm font-normal my-6 "
                        onClick={() => handleReplySubmit(item?.id)}
                      >
                        Reply
                      </Button>
                    </div>

                    {item.comment_reply?.length > 0
                      ? item.comment_reply?.map((item, idx) => (
                          <div className="ml-8 border-l-2 border-primary px-4">
                            <p className="font-bold text-xs">
                              {item?.name}
                              <span className="font-normal italic text-secondary ml-4">
                                {item?.time}
                              </span>
                            </p>
                            <p className="text-sm">{item?.content}</p>

                            {/* <button className='text-xs text-primary/60 italic'>Reply</button> */}
                          </div>
                        ))
                      : null}
                  </>
                )}

                <hr />
              </div>
            ))}

            {/* <div className='space-y-2'>
                            <p className='font-bold text-xs'>James Badu <span className='font-normal italic text-secondary ml-4'>2 mins ago</span></p>
                            <p className='text-sm'>I was there on the day and this is the one talk I'll be sharing with designers still getting their head around design tokens. Thanks for the work guys - great presentation.</p>


                            <button className='text-xs text-primary/60 italic'>Reply</button>
                        </div> */}
          </div>
        </div>
      )}
      <CustomModal
        openModal={openKeyModal}
        handleModalClose={() => {}}
        title={`Stakeholder Occurrence Key`}
      >
        <div className="flex flex-col justify-center items-center">
          <div className="w-[482px] space-y-6 py-16 ">
            <div>
              <p className="mb-4 text-sm font-bold ">
                Enter your unique key to access this section
              </p>
              <KeyInput length={5} onComplete={setKey} />
            </div>
            <div className="px-10 pt-14">
              <Button
                variant="contained"
                onClick={handleKeySuccess}
                className="bg-primary capitalize py-4 rounded-none px-7  text-sm font-normal w-full"
              >
                Enter
              </Button>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}

export default ExecutiveSummary;
