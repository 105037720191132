import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  CircularProgress,
} from "@mui/material";
import useFetch from "../../helpers/useFetch";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

const columns = [
  { id: "sn", label: "S/N" },
  { id: "full_name", label: "Full Name", minWidth: 140 },

  {
    id: "email",
    label: "Email",
    minWidth: 150,
    align: "center",
  },
  {
    id: "message",
    label: "Message",
    minWidth: 270,
    align: "center",
  },
  {
    id: "date",
    label: "Date Requested",
    minWidth: 150,
    align: "center",
    format: (value) =>
      new Date(value).toLocaleString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }),
  },
  {
    id: "status",
    label: "Status",
    minWidth: 100,
    align: "center",
  },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  " td,  th": {
    border: 0,
  },
}));

function resetRequest() {
  const navigate = useNavigate();
  const { data: requestsData, isLoading: isLoadingRequests } = useFetch(
    "api/user/all_request_form"
  );

  React.useEffect(() => {
    console.log(requestsData);
  }, [requestsData]);

  // PAGINATION
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <div>
        <h2 className="text-gray-500 pl-4">
          <span
            className="cursor-pointer"
            onClick={() => navigate("/dashboard")}
          >
            Dashboard
          </span>
          / Password Reset Requests
        </h2>
      </div>
      <div className="mt-6">
        <TableContainer className="rounded-2xl bg-white py-4">
          <Table
            stickyHeader
            aria-label="sticky table"
            classes={{
              root: "border-none border-transparent",
            }}
          >
            <TableHead>
              <StyledTableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className="text-gray-500 font-medium"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {isLoadingRequests ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10">
                      <CircularProgress />
                      <div>
                        <p className="text-center animate-pulse">Loading...</p>
                      </div>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : !requestsData?.data?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <div className="mt-10">
                      <p className="text-center">No data available</p>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : (
                requestsData?.data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                        className="cursor-pointer"
                        onClick={() => {
                          navigate(`/reset-requests/${row?.id}`);
                        }}
                      >
                        {columns.map((column) => {
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <span className="font-medium text-sm py-4">
                                {column?.id === "sn" ? (
                                  <p>{index + 1}</p>
                                ) : column?.id === "status" ? (
                                  <div
                                    className={`${
                                      row?.is_done === false
                                        ? "bg-yellow-50"
                                        : row?.is_done === true
                                        ? "bg-green-50"
                                        : "bg-red-50"
                                    } px-4 w-fit mx-auto py-1 rounded-full`}
                                  >
                                    <p
                                      className={`${
                                        row?.is_done === false
                                          ? "text-yellow-500"
                                          : row?.is_done === true
                                          ? "text-green-500"
                                          : "text-red-500"
                                      }`}
                                    >
                                      {row?.is_done === false
                                        ? "Pending"
                                        : "Resolved"}
                                    </p>
                                  </div>
                                ) : column?.id === "message" ? (
                                  <p className="truncate">{row[column?.id]}</p>
                                ) : column?.format ? (
                                  column?.format(row?.created_at)
                                ) : (
                                  <p>{row[column?.id]}</p>
                                )}
                              </span>
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="mt-10 mx-auto w-fit">
        <Pagination
          count={Math.ceil(requestsData?.data?.length / rowsPerPage)}
          page={page + 1}
          rowsPerPage={rowsPerPage}
          color="primary"
          size="small"
          onChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default resetRequest;
