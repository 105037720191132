import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Stack, Typography, Button } from "@mui/material";
import FormBg from "../../components/user-auth/FormBg";
import Logo from "../../assets/general/nsib_logo.png";
import bg from "../../assets/general/loginbackgroundlarge.png";
import FormHeaders from "../../components/user-auth/FormHeaders";
import LoginAdditionalOptions from "../../components/user-auth/LoginAdditionalOptions";
import { userLogin } from "../../redux/slices/users/authSlice";
import CircularProgress from "@mui/material/CircularProgress";
import CustomTextField, {
  CustomPasswordField,
} from "../../components/common/CustomTextField";
import { useFormik } from "formik";
import { LoginValidationSchema } from "../../helpers/validation/UserManagementSchema";
import { error as errorModal } from "../../common/Notifications";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector((state) => state.auth);

  let isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [formErrors, setFormErrors] = React.useState({
    email: "",
    password: "",
    message: "",
  });

  const formik = useFormik({
    initialValues: { email: "", password: "", tenant: "AIBNIGERIA" },
    validationSchema: LoginValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      // dispatch(userLogin(values));
      setFormErrors({
        email: "",
        password: "",
        message: "",
      });
      dispatch(userLogin(values))
        .unwrap()
        .then(() => {
          // dispatch(fetchUser())
          //   .unwrap()
          //   .then(() => {})
          //   .catch((error) => {
          //     console.error("Error in fetchUser:", error);
          //   });
        })
        .catch((error) => {
          Array.isArray(error)
            ? setFormErrors({
                email: error[0]?.hasOwnProperty("email")
                  ? error[0]["email"]
                  : "",
                password: error[0]?.hasOwnProperty("password")
                  ? error[0]["password"]
                  : "",
                message: error[0]?.hasOwnProperty("message")
                  ? error[0]["message"]
                  : "You have errors in your form",
              })
            : setFormErrors({
                email: error?.hasOwnProperty("email") ? error["email"] : "",
                password: error?.hasOwnProperty("password")
                  ? error["password"]
                  : "",
                message: error?.hasOwnProperty("message")
                  ? error["message"]
                  : "You have errors in your form",
              });
          errorModal(formErrors?.message);

          console.error("Error in userLogin:", error);
        });
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/dashboard`);
    }
  }, [isAuthenticated]);

  return (
    <Stack
      direction={"row"}
      width={"100vw"}
      alignItems={"stretch"}
      minHeight={"100vh"}
    >
      <FormBg backgroundImage={bg} />
      <Stack
        direction={"column"}
        gap={"84px"}
        sx={{
          px: "64px",
          maxWidth: "50%",
          py: "52px",
        }}
        flex={1}
      >
        <Stack direction={"row"} gap={"8px"} alignItems={"center"}>
          <img
            src={Logo}
            // width={'55px'}
            // height={'54px'}
            alt=""
            className="h-[110px]"
          />
          {/* <p className='font-bold leading-6 text-ms break-all w-[245px] text-[#211357] tracking-wide uppercase'>Nigeria Safety <br /> Investigation Bureau</p> */}

          {/* <Typography
            variant='p'
            fontFamily={'Pt Sans'}
            letterSpacing={'0.16px'}
            fontSize={14}
            lineHeight={'16.8px'}
            color={'#211357'}
          >
            Accident Investigation
            <br /> Bureau, Nigeria
          </Typography> */}
        </Stack>

        <Stack
          direction={"column"}
          gap={"16px"}
          alignItems={"center"}
          alignSelf={"center"}
          maxWidth={"500px"}
          width={"100%"}
        >
          <FormHeaders
            title={"Welcome"}
            titleColor={"#161616"}
            titleLineHeight={80}
            titleSize={64}
            descriptionColor={"#003163"}
            descriptionLineHeight={28.8}
            description={"login to your account"}
            descriptionTextSize={18}
          />

          <Stack width={"100%"} gap={"6px"}>
            <CustomTextField
              label={"Email Address"}
              placeholder={`Enter your email address`}
              onChange={formik.handleChange}
              value={formik.values.email.trim()}
              name={"email"}
              error={
                !!(
                  (formik.errors.email && formik.touched.email) ||
                  formErrors?.email
                )
              }
              helperText={formik.errors.email || formErrors?.email}
            />
          </Stack>

          <Stack width={"100%"} gap={"6px"}>
            <CustomPasswordField
              label={`Password`}
              value={formik.values.password}
              onChange={formik.handleChange}
              name={"password"}
              placeholder={"Enter your password"}
              error={
                !!(
                  (formik.errors.password && formik.touched.password) ||
                  formErrors?.password
                )
              }
              helperText={formik.errors.password || formErrors?.password}
            />
          </Stack>

          <LoginAdditionalOptions forgotPasswordRoute={"/reset-password"} />

          <Button
            variant="contained"
            disableElevation
            sx={{
              backgroundColor: "#003163 !important",
              borderRadius: 0,
              width: "124px",
              height: "42px",
              fontFamily: "PT Sans",
              textTransform: "none",
              color: "#CFE0FF",
              fontSize: 14,
              mt: "16px",
              alignSelf: "flex-start",
              "&:hover": {
                backgroundColor: "#003163",
              },
            }}
            onClick={formik.handleSubmit}
            disabled={loading}
            // href='/dashboard'
          >
            {loading ? (
              <CircularProgress className="w-5 h-5 text-white" />
            ) : (
              "Login"
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Login;
