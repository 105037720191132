import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as authAPI from "../../../apis/auth";
import { history } from "../../../helpers/history.js";
import { success, error, warning } from "../../../common/Notifications";
import { fetchUser } from "./userSlice";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

export const userLogin = createAsyncThunk(
  "auth/login",
  async (data, thunkAPI) => {
    const res = await authAPI.loginUser(data, thunkAPI);
    return res;
  }
);

export const userLoginVerify = createAsyncThunk(
  "auth/loginVerify",
  async (data, thunkAPI) => {
    const res = await authAPI.verifyUser(data, thunkAPI);
    return res;
  }
);

export const userLogout = createAsyncThunk(
  "auth/logout",
  async (payload, thunkAPI) => {
    const res = await authAPI.logoutUser(thunkAPI);
    return res;
  }
);

export const refreshAuthToken = createAsyncThunk(
  "auth/refreshToken",
  async (payload, thunkAPI) => {
    const res = await authAPI.refreshTokenUser(thunkAPI);
    return res;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    error: null,
    status: "idle",
    drawerOpen: true,
    userLogin: null,
  },

  reducers: {
    toggleDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen;
    },
  },

  extraReducers: (builder) => {
    // Login
    builder
      .addCase(userLogin.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: null,
          isAuthenticated: false,
        };
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.userLogin = payload?.data;
        success(payload.message);
        state.accessToken = payload?.data?.access;
        state.loading = false;
        state.error = null;
        const changePasswordIsRequired = payload?.change_password;
        if (changePasswordIsRequired) {
          history.navigate("/login/verify?cp=true");
        } else {
          history.navigate("/login/verify");
        }
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        error(payload?.message);
        return {
          ...state,
          loading: false,
          status: payload?.status,
          error: payload,
        };
      });

    // Verify login
    builder
      .addCase(userLoginVerify.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: null,
          isAuthenticated: false,
        };
      })
      .addCase(userLoginVerify.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.loading = false;
        state.status = payload?.status;
        state.isAuthenticated = true;
        // get route query
        const query = new URLSearchParams(history.location.search);
        const redirect = query.get("cp");
        if (redirect) {
          history.navigate("/change-password");
          localStorage.setItem("changePassword", "true");
        } else {
          history.navigate("/dashboard");
        }
        success(payload?.message);
      })
      .addCase(userLoginVerify.rejected, (state, { payload }) => {
        error(payload?.message);
        return {
          ...state,
          status: payload?.status,
          loading: false,
          isAuthenticated: false,
        };
      });

    // Logout
    builder
      .addCase(userLogout.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(userLogout.fulfilled, (state, { payload }) => {
        success(payload?.message);
        history.navigate("/login");
        return {
          ...state,
          loading: false,
          accessToken: null,
          refreshToken: null,
          isAuthenticated: false,
          error: null,
          status: "idle",
        };
      })
      .addCase(userLogout.rejected, (state, actions) => {
        error(actions.payload?.message);
        if (actions?.payload?.message === "User already logged out") {
          return {
            ...state,
            loading: false,
            accessToken: null,
            refreshToken: null,
            isAuthenticated: false,
            error: null,
            status: "idle",
          };
        } else {
          return {
            ...state,
            status: "idle",
            loading: false,
          };
        }
      });

    // Refresh token
    builder
      .addCase(refreshAuthToken.fulfilled, (state, action) => {
        state.accessToken = action?.payload.data.access;
      })
      .addCase(refreshAuthToken.rejected, (state) => {
        state.status = "idle";
      });
  },
});

export const { logout } = authSlice.actions;
export const { toggleDrawer } = authSlice.actions;

export default authSlice.reducer;
