import React from "react";

import { Stack, Dialog, Button, Typography } from "@mui/material";
import { ArrowLeft } from "iconsax-react";
import useFetch from "../../helpers/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import { Input } from "../user-management/ManageProfile";
import ConfirmDialog from "../../common/ConfirmDialog";
import DoneDialog from "../../common/DoneDialog";
import Loading from "../../common/Loading";
import { error } from "../../common/Notifications";
import { useSelector } from "react-redux";
import axios from "axios";

const ICAODetail = () => {
  const { occurrenceId, userId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useFetch(
    `api/occurrence-manager/information/${occurrenceId}/icaos_users/`
  );
  const accessToken = useSelector((state) => state.auth.accessToken);
  React.useEffect(() => {
    if (data?.data) {
      const user = data?.data?.find((icao) => icao.id === userId);
      setFormData({
        name: user?.name,
        email: user?.email,
        phone: user?.phone,
      });
    }
  }, [data]);
  // FORM
  const [formData, setFormData] = React.useState({
    name: "",
    email: "",
    phone: "",
  });

  // DIALOGS
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [confirmUpdate, setConfirmUpdate] = React.useState(false);
  const [actionText, setActionText] = React.useState({
    title: "",
    description: "",
  });
  const [isDone, setIsDone] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  async function handleAction(type) {
    if (
      (type === "update" && !formData.name) ||
      !formData.email ||
      !formData.phone
    ) {
      error("Please fill in all required fields");
      return;
    }
    if (type === "update" && formData.phone.length !== 11) {
      error("Please enter a valid phone number");
      return;
    }
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "Application/json",
          Authorization: `JWT ${accessToken}`,
        },
      };
      if (type === "delete") {
        await axios.delete(
          `${process.env.REACT_APP_BASE_API}api/occurrence-manager/icao/${userId}/`,
          config
        );
        setConfirmDelete(false);
        setActionText({
          title: "ICAO User deleted",
          description: "You have successfully deleted ICAO User",
        });
      } else {
        await axios.put(
          `${process.env.REACT_APP_BASE_API}api/occurrence-manager/icao/${userId}/`,
          { ...formData, occurrence_id: occurrenceId },
          config
        );
        setConfirmUpdate(false);
        setActionText({
          title: "ICAO User updated",
          description: "You have successfully updated ICAO User",
        });
      }
      setIsDone(true);
    } catch (err) {
      error(err?.response?.data?.message);
    }

    setLoading(false);
  }

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Stack>
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={true}
          confirmText="Delete"
          title="Delete ICAO"
          isLoading={loading}
          description="Are you sure you want to delete ICAO? You can't undo this."
          handleClose={() => setConfirmDelete(false)}
          handleConfirm={() => handleAction("delete")}
        />
      </Dialog>
      <Dialog
        open={confirmUpdate}
        onClose={() => setConfirmUpdate(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            minWidth: "450px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={false}
          confirmText="Update"
          title="Update ICAO"
          isLoading={loading}
          description="Are you sure you want to update this ICAO?"
          handleClose={() => setConfirmUpdate(false)}
          handleConfirm={() => handleAction("update")}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => navigate(-1)}
      >
        <DoneDialog
          title={actionText?.title}
          description={actionText?.description}
        />
      </Dialog>
      <Stack>
        <div
          className="flex items-center gap-1 cursor-pointer w-fit ml-6"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft size="22" color="#000000" />
          <p>Back</p>
        </div>
      </Stack>
      {/* <Stack marginTop={"32px"} className="bg-white rounded-2xl px-12 py-6">
        <h2 className="text-xl font-semibold flex items-center gap-2">
          <span>Interim Report:</span>
          {isLoading ? (
            <span className="w-[5rem] h-4 rounded-lg bg-gray-400 inline-block animate-pulse"></span>
          ) : (
            <span>{data?.data?.title}</span>
          )}
        </h2>
      </Stack> */}
      <Stack marginTop={"32px"} className="bg-white rounded-2xl px-12 py-6">
        <div className="flex  gap-12">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Name
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.name}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  name: target.value,
                }));
              }}
              placeholder="ICAO Name"
            />
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Email
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={formData?.email}
              onChange={({ target }) => {
                setFormData((prev) => ({
                  ...prev,
                  email: target.value,
                }));
              }}
              placeholder="ICAO Email"
            />
          </Stack>
        </div>
        <Stack className="pr-6 mt-10" gap={"6px"} width={"50%"}>
          <Typography variant="p" color={"#D8D8D8"}>
            Phone Number
          </Typography>
          <Input
            sx={{
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "#D9D9D9",
                },

                "&.Mui-focused fieldset": {
                  borderColor: "#003163",
                },
              },
            }}
            fullWidth
            value={formData?.phone}
            onChange={({ target }) => {
              setFormData((prev) => ({
                ...prev,
                phone: target.value,
              }));
            }}
            placeholder="ICAO Phone Number"
          />
        </Stack>
        <div className="flex gap-4 justify-end mt-10">
          <Button
            className="bg-red-600 text-white capitalize font-semibold text-sm shadow-none px-6 rounded-lg"
            onClick={() => setConfirmDelete(true)}
          >
            Delete
          </Button>
          <Button
            className="bg-primary text-white capitalize font-semibold text-sm shadow-none px-6 rounded-lg"
            onClick={() => setConfirmUpdate(true)}
          >
            Update
          </Button>
        </div>
      </Stack>
    </Stack>
  );
};

export default ICAODetail;
