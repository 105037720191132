import React from "react";
import { Stack, Typography, Button, CircularProgress } from "@mui/material";
import { Input } from "../../pages/user-management/ManageProfile";
import { Editor, EditorProvider, Toolbar } from "react-simple-wysiwyg";
import {
  BtnAlignCenter,
  BtnAlignLeft,
  BtnAlignRight,
  BtnBold,
  BtnItalic,
  BtnUnderline,
} from "../../components/occurrence/Synopsis";
import { error } from "../../common/Notifications";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";

const QuerySectionModal = ({ setDone }) => {
  const { sectionId } = useParams();
  const [value, setValue] = React.useState({
    reason: "",
    content: "",
  });

  const accessToken = useSelector((state) => state.auth.accessToken);
  const [loading, setLoading] = React.useState(false);
  async function handleQuery() {
    if (!value.reason || !value.content) {
      error("Please add a reason and content");
      return;
    }
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/${sectionId}/query_section/ `,
        { ...value, section_page: sectionId },
        config
      );
      setDone();
    } catch (err) {
      error(err.response?.data?.message || "Something went wrong");
    }
    setLoading(false);
  }
  return (
    <div className="py-12 px-9">
      <div>
        <h2 className="text-xl font-bold text-center">
          Query Dialog for Section{" "}
        </h2>
        <div className="mt-6">
          <div>
            <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
              Reason for Query
            </Typography>
            <Input
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: "#D9D9D9",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#003163",
                  },
                },
              }}
              fullWidth
              value={value.reason}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, reason: e.target.value }))
              }
              placeholder="Enter reason"
            />
          </div>
          <div className="mt-6">
            <Typography color={"#D8D8D8"} className="pl-3 pb-2.5">
              Details of Query
            </Typography>
            <div>
              <EditorProvider>
                <Editor
                  value={value.content}
                  onChange={(e) =>
                    setValue((prev) => ({ ...prev, content: e.target.value }))
                  }
                >
                  <Toolbar>
                    <Stack
                      direction={"row"}
                      gap={"32px"}
                      paddingLeft={"20px"}
                      paddingY={"10px"}
                      className="text-center"
                    >
                      <BtnAlignLeft />
                      <BtnAlignCenter />
                      <BtnAlignRight />
                      <BtnItalic />
                      <BtnBold />
                      <BtnUnderline />
                    </Stack>
                  </Toolbar>
                </Editor>
              </EditorProvider>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <Button
              className="bg-red-600 py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
              onClick={handleQuery}
            >
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Query Section"
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuerySectionModal;
