import React from "react";
import {
  Stack,
  Button,
  CircularProgress,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";

import { Editor, EditorProvider, Toolbar } from "react-simple-wysiwyg";
import { useSelector } from "react-redux";
import { error, success } from "../../../common/Notifications";
import axios from "axios";
import {
  BtnAlignCenter,
  BtnAlignLeft,
  BtnAlignRight,
  BtnItalic,
  BtnBold,
  BtnUnderline,
} from "../Synopsis";
import { severityList } from "../section-owner/SectionForm";

const Conclusion = ({
  occurrenceId,
  occurrenceOwner,
  isPreview,
  information,
  refetch,
  goToNext,
}) => {
  const [value, setValue] = React.useState({
    content: "",
    severity: "",
  });
  React.useEffect(() => {
    if (information) {
      const section = information?.occurrence_section_group?.find(
        (group) => group?.section_name === "ANALYSIS"
      );
      if (
        section &&
        section.occurence_section &&
        section.occurence_section.length > 0
      ) {
        setValue({
          content:
            section.occurence_section[0]?.occurrence_page_section[0]?.content,
          severity: section.occurence_section[0]?.severity,
        });
      }
    }
  }, []);
  const user = useSelector((state) => state.users.user);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [userIsOccurenceManager, setUserIsOccurenceManager] =
    React.useState(false);

  React.useEffect(() => {
    if (user) {
      if (user?.roles?.role?.toLowerCase()?.includes("occurence-manager/iic")) {
        setUserIsOccurenceManager(true);
      }
    }
  }, [user]);

  const [loading, setLoading] = React.useState(false);
  async function handleSubmit() {
    if (!value.content || !value.severity) {
      error("Please enter your analysis");
      return;
    }
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      const body = {
        section_name: "Analysis",
        occurrence: occurrenceId,
        section_group_name: "ANALYSIS",
        section_owner: occurrenceOwner,
        content: value.content,
        severity: value.severity,
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/`,
        body,
        config
      );
      success("Occurrence Details updated successfully");
      goToNext();
      refetch();
    } catch (err) {
      error(err.response?.data?.message);
    }
    setLoading(false);
  }
  return (
    <Stack gap={"32px"}>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Analysis</h2>
      </div>

      <div className="bg-white py-6 px-6 rounded-2xl">
        <Stack gap={"6px"} marginBottom={"32px"}>
          <Typography variant="p" color={"#D8D8D8"}>
            Severity
          </Typography>
          <FormControl>
            <Select
              labelId="Severity"
              id="Severity"
              value={value?.severity}
              onChange={(e) => {
                setValue((prevState) => ({
                  ...prevState,
                  severity: e.target.value,
                }));
              }}
              disabled={!userIsOccurenceManager}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className="rounded-lg font-semibold"
              sx={{
                "&.MuiInputBase-root": {
                  width: "220px",
                },
              }}
            >
              <MenuItem value="">
                <Typography>Select Severity</Typography>
              </MenuItem>
              {severityList?.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item?.value}
                  className="text-secondary"
                  sx={{
                    outline: "0px",
                    "&.MuiMenuItem-root": {
                      backgroundColor: "#f4f4f4",
                      color: "#7E7E7E",
                      padding: "10px 14px",
                      borderBottom: "1px solid",
                      borderBottomColor: "#CBCBCB",
                      "&:last-child": { border: 0 },
                      "&:active": {
                        backgroundColor: "#051445",
                        color: "#ffffff !important",
                      },
                      "&:hover": {
                        backgroundColor: "#0C33AE",
                        color: "#ffffff !important",
                      },
                      "&:focus-visible": {
                        outline: 0,
                      },
                    },
                  }}
                >
                  <div className="flex items-center gap-3">
                    <span
                      className="w-3 h-3 rounded-full"
                      style={{
                        backgroundColor: item?.colorCode,
                        border: item?.border,
                      }}
                    ></span>
                    <span>{item?.label}</span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Typography variant="p" color={"#D8D8D8"}>
          Analysis
        </Typography>
        <EditorProvider>
          <Editor
            value={value.content}
            disabled={!userIsOccurenceManager}
            onChange={(e) =>
              setValue((prevState) => ({
                ...prevState,
                content: e.target.value,
              }))
            }
          >
            <Toolbar>
              <Stack
                direction={"row"}
                gap={"32px"}
                paddingLeft={"20px"}
                paddingY={"10px"}
                className="text-center"
              >
                <BtnAlignLeft />
                <BtnAlignCenter />
                <BtnAlignRight />
                <BtnItalic />
                <BtnBold />
                <BtnUnderline />
              </Stack>
            </Toolbar>
          </Editor>
        </EditorProvider>
      </div>
      <div className="flex justify-end">
        {!isPreview ? (
          <Button
            loading={loading}
            className="bg-primary py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress size={24} /> : "Save and Continue"}
          </Button>
        ) : (
          <Button
            className="bg-primary py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
            onClick={goToNext}
          >
            Next
          </Button>
        )}
      </div>
    </Stack>
  );
};

export default Conclusion;
