import { Stack, Typography } from '@mui/material'
import React from 'react'

export default function FormHeaders({ title, description, marginBottom, titleSize, descriptionTextSize, titleColor, descriptionColor, titleLineHeight, descriptionLineHeight }) {
  return (
    <Stack justifyContent={'center'} marginBottom={`${marginBottom}px`}>
      <Typography fontSize={titleSize} color={titleColor} textAlign={'center'} fontFamily={'Pt Sans'} fontWeight={700} lineHeight={`${titleLineHeight}px`} variant='h1'>
        {title}
      </Typography>
      <Typography textAlign={'center'} fontFamily={'Pt Sans'} fontWeight={700} color={descriptionColor} letterSpacing={'0.36px'} fontSize={`${descriptionTextSize}px`} lineHeight={`${descriptionLineHeight}px`}>
        {description}
      </Typography>
    </Stack>
  )
}
