import React from "react";
import {
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
} from "@mui/material";
import { StyledTableRow } from "../../pages/user-data";
import ConfirmDialog from "../../common/ConfirmDialog";
import DoneDialog from "../../common/DoneDialog";
import { error, success } from "../../common/Notifications";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const occurrenceInformationColumns = [
  {
    label: "Occurrence Number",
    id: "occurrence_number",
    minWidth: 140,
  },
  {
    label: "Date",
    id: "occurrence_date",
    minWidth: 80,
  },
  {
    label: "Category",
    id: "occurrence_category",
    minWidth: 120,
  },
  {
    label: "Type",
    id: "occurrence_type",
    minWidth: 120,
  },
  {
    label: "Owner",
    id: "occurrence_owner_name",
    minWidth: 100,
  },
  {
    label: "ICAO Deadline",
    id: "icao_deadline_date",
    minWidth: 80,
  },
  {
    label: "Last Updated",
    id: "updated_at",
    minWidth: 80,
  },
];

const InformationSection = ({ information }) => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [confirmPublish, setConfirmPublish] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  async function handlePublish() {
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };

      await axios.put(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/information/${information?.id}/submit_occurrence/`,
        null,
        config
      );
      setConfirmPublish(false);
      setIsDone(true);
    } catch (err) {
      error(err?.response?.data?.message);
    }
    setLoading(false);
  }
  return (
    <div>
      <Dialog
        open={confirmPublish}
        nClose={() => setConfirmPublish(false)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
          },
        }}
      >
        <ConfirmDialog
          isDelete={false}
          isCustom={true}
          confirmText="Submit"
          title="Submit Occurrence to Super User"
          isLoading={loading}
          description="Are you sure you want to submit occurrence changes to the super user? Please make sure all sections are filled appropriately before submitting."
          handleClose={() => setConfirmPublish(false)}
          handleConfirm={handlePublish}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() =>
          navigate(`/occurrence/manage-occurrence/${information.id}`, {
            replace: true,
          })
        }
      >
        <DoneDialog
          title="Successfully Submitted"
          description="You have successfully submitted occurrence for review by Super User."
        />
      </Dialog>
      <Stack
        bgcolor={"#fff"}
        px={"32px"}
        pt={"14px"}
        pb={"5px"}
        className="rounded-2xl"
      >
        <Stack>
          <div className="flex justify-between gap-6 items-center mb-4">
            <Typography fontSize={"20px"} fontWeight={600} color={"#161616"}>
              Occurrence Information
            </Typography>

            <Button
              variant="contained"
              className="text-white bg-primary capitalize font-bold text-sm rounded-2xl px-10 shadow-none py-2.5"
              onClick={() => setConfirmPublish(true)}
            >
              Submit Occurrence
            </Button>
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {occurrenceInformationColumns?.map((column, index) => (
                    <TableCell key={index}>
                      <Typography
                        fontWeight={500}
                        fontSize={"13px"}
                        color={"#AEAEAE"}
                        align="center"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column?.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {information && (
                  <StyledTableRow>
                    {occurrenceInformationColumns?.map((column, index) => (
                      <TableCell key={index}>
                        <Typography
                          fontSize={"14px"}
                          fontWeight={500}
                          align="center"
                        >
                          {information[column?.id]}
                        </Typography>
                      </TableCell>
                    ))}
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
    </div>
  );
};

export default InformationSection;
