import React from 'react'
import {
  Stack,
  Typography,
  IconButton
} from '@mui/material'

export default function Alert({ title, iconClass, message, color, display }) {
  const [displayState, setDisplayState] = React.useState(display)
  return (
    <Stack alignItems={'center'} display={displayState ? 'flex' : 'none'} paddingLeft={'16px'} direction={'row'} sx={{
      position: 'absolute',
      right: '400px',
      width: '300px',
      height: '48px',
      background: '#393939',
      borderLeft: '4px solid',
      borderColor: color,
      borderRadius: '4px',
      gap: '8px'
    }}>
      <i
        className={iconClass}
        style={{
          color: color,
          fontSize: '20px',
          marginRight: '8px'
        }}>
      </i>
      <Typography fontFamily={'PT Sans'} color={'#fff'} fontSize={14} fontWeight={600}>
        {title}
      </Typography>
      <Typography fontFamily={'PT Sans'} color={'#fff'} fontSize={14} fontWeight={400}>
        {message}
      </Typography>
      <IconButton onClick={() => (setDisplayState(!displayState))} sx={{
        marginLeft: 'auto'
      }}>
        <i className="bi bi-x-lg" style={{
          fontSize: 16,
          color: '#fff'
        }}></i>
      </IconButton>
    </Stack>
  )
}
