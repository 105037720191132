import React from "react";
import { MoreVertRounded } from "@mui/icons-material";
import {
  Stack,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Table,
  TableBody,
  Button,
  Pagination,
  TablePagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Loading from "../../common/Loading";
import CircularProgress from "@mui/material/CircularProgress";

const columns = [
  {
    id: "title",
    label: "Title",
    minWidth: 170,
    format: (value) => <p className="font-bold text-sm ">{value}</p>,
  },
  {
    id: "occurrence_date",
    label: "Occurrence Date",
    minWidth: 170,
    format: (value) => <p className="">{value}</p>,
  },
  {
    id: "airline",
    label: "Airline",
    minWidth: 100,
  },
  {
    id: "location",
    label: "Incident Location",
    minWidth: 170,
  },
  {
    id: "aircraft_type",
    label: "Aircraft Type",
    minWidth: 140,
  },
  {
    id: "occurrence_report_status",
    label: "Status",
    minWidth: 190,
    format: (value) => (
      <p
        className={` ${
          value === "CLOSED"
            ? "text-error"
            : value === "FINAL"
            ? "text-primary"
            : ""
        }`}
      >
        {value}
      </p>
    ),
  },
];

function OccurenceHome({ data, isLoading }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = [
    data?.map((item) => {
      return { ...item[0] };
    }),
  ];

  const rangeStart = page * rowsPerPage + 1;
  const rangeEnd = Math.min((page + 1) * rowsPerPage, data?.length);

  return (
    <div className="">
      <div className=" flex items-center justify-between">
        <p className="font-bold text-[22px]">Occurrence Index</p>
        <div className="flex items-center gap-7">
          <p>{`${rangeStart} - ${rangeEnd} of ${data?.length}`}</p>
          {/* <MoreVertRounded /> */}

          <TablePagination
            component="div"
            count={rows?.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={""}
            backIconButtonProps={{
              sx: { display: "none" },
            }}
            nextIconButtonProps={{
              sx: { display: "none" },
            }}
            SelectProps={{
              // variant: 'outlined',
              IconComponent: MoreVertRounded,
              value: "a",
              sx: {
                // color: '#fff',
                background: "transparent",
                border: "none",
                borderRadius: 0,
                // fill: '#fff',
                backgroundColor: "none",
                "& .MuiSelect-select": {
                  borderRadius: 0,
                  fill: "#fff",
                },
              },
            }}
            sx={{
              "& .MuiSelect-nativeInput": {
                minWidth: "0px",
                minHeight: 0,
                width: 0,
                height: 0,
              },
              padding: 0,
              "& .MuiTablePagination-displayedRows": {
                display: "none",
              },
            }}
          />
        </div>
      </div>

      <div className="mt-5 bg-white">
        <TableContainer component={Stack}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className="bg-primary px-8 py-6 text-white rounded-t-lg">
              <TableRow>
                {columns?.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column?.minWidth }}
                  >
                    <p className="text-white">{column?.label}</p>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="mt-10 bg-white px-8">
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <CircularProgress className="text-primary" />
                  </TableCell>
                </TableRow>
              ) : data?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns?.length} align="center">
                    <Typography
                      fontFamily={"PT Sans"}
                      fontSize={14}
                      color={"#161616"}
                      variant="body2"
                    >
                      No occurrence found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns?.map((column) => {
                          const value =
                            column?.id == "airline" ||
                            column?.id == "location" ||
                            column?.id == "aircraft_type"
                              ? row?.occurrence_details[column?.id]
                              : row[column?.id]?.replace(/_/g, " ");
                          return (
                            <TableCell
                              key={column?.id}
                              align={column?.align}
                              component="a"
                              href={`/occurence-index/${row?.id}`}
                            >
                              {value || "-"}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="py-4 flex justify-end">
          <Pagination
            rowsPerPageOptions={[5, 25, 100]}
            count={Math.ceil(rows[0]?.length / rowsPerPage)}
            rowsPerPage={rowsPerPage}
            page={page + 1}
            onChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default OccurenceHome;
