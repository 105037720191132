import axios from "axios";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../redux/slices/users/authSlice";
import { clearUserData } from "../redux/slices/users/userSlice";
import axiosInstance from "./axiosInstance";

function useFetch(url) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const handleLogout = async () => {
    // dispatch(userLogout())
    dispatch(userLogout())
      .unwrap()
      .then(() => dispatch(clearUserData()).unwrap);

    // dispatch(refreshAuthToken())
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const baseUrl = process.env.REACT_APP_BASE_API + url;
    const options = {
      method: "GET",
      xstfCookieName: "csrftoken",
      xsrfHeaderName: "X-CSRFToken",
      url: baseUrl,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": "csrftoken",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `JWT ${accessToken}`,
      },
    };
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await axiosInstance.request(options, { signal });
        setData(response?.data);
        console.log(response?.data, "data");
        setIsLoading(false);
      } catch (error) {
        // LOGOUT IF AUTHENTICATION CREDENTAIL HAS EXPIRED
        if (
          error?.response?.status === 403 &&
          error?.response?.data?.detail
            ?.toLowerCase()
            ?.includes("authentication credentials were not provided")
        ) {
          handleLogout();
        }
        setError(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchData();

    return () => {
      controller.abort();
    };
  }, [url]);

  const controllerRefecth = new AbortController();
  const signalRefresh = controllerRefecth.signal;

  function refetch() {
    setIsLoading(true);
    const baseUrl = process.env.REACT_APP_BASE_API + url;
    const options = {
      method: "GET",
      xstfCookieName: "csrftoken",
      xsrfHeaderName: "X-CSRFToken",
      url: baseUrl,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": "csrftoken",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `JWT ${accessToken}`,
      },
    };
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await axios.request(options, { signalRefresh });
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    return () => {
      controllerRefecth.abort();
    };
  }

  return { data, isLoading, error, refetch };
}

export default useFetch;
