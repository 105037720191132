import React, { useState } from "react";
import {
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useFetch from "../../helpers/useFetch";
import { StyledTableRow } from "../../pages/user-data";
import { Edit2 } from "iconsax-react";
import Loading from "../../common/Loading";
import MediaEditModal from "./MediaEditModal";
import DoneDialog from "../../common/DoneDialog";
import { error, success } from "../../common/Notifications";
import axios from "axios";

const occurrenceInformationColumns = [
  {
    label: "Occurrence Number",
    id: "occurrence_number",
    minWidth: 140,
  },
  {
    label: "Date",
    id: "occurrence_date",
    minWidth: 100,
  },
  {
    label: "Category",
    id: "occurrence_category",
    minWidth: 120,
  },
  {
    label: "Type",
    id: "occurrence_type",
    minWidth: 120,
  },
  {
    label: "Occurence Manager/IIC",
    id: "occurrence_owner_name",
    minWidth: 100,
  },
  {
    label: "ICAO Deadline",
    id: "icao_deadline_date",
    minWidth: 80,
  },
  {
    label: "Last Updated",
    id: "updated_at",
    minWidth: 80,
  },
];

const occurenceDetailsFirstColumns = [
  {
    label: "Registered Owner",
    id: "registered_owner",
    minWidth: 120,
  },
  {
    label: "Registered Operator (Airline)",
    id: "airline",
    minWidth: 120,
  },
  {
    label: "Aircraft Manufacturer",
    id: "aircraft_manufacturer",
    minWidth: 120,
  },
  {
    label: "Aircraft Type",
    id: "aircraft_type",
    minWidth: 120,
  },
];

const occurenceDetailsSecondColumns = [
  {
    label: "Aircraft Model",
    id: "aircraft_model",
    minWidth: 120,
  },
  {
    label: "Aircraft Registration Number",
    id: "aircraft_registration_number",
    minWidth: 120,
  },
  {
    label: "Aircraft Serial Number",
    id: "aircraft_serial_number",
    minWidth: 120,
  },
  {
    label: "Type of Operation",
    id: "type_of_operation",
    minWidth: 120,
  },
];

const occurenceDetailsThirdColumns = [
  {
    label: "Damage to Aircraft",
    id: "damage_to_aircraft",
    minWidth: 120,
  },
  {
    label: "Flight Destination",
    id: "flight_destination",
    minWidth: 120,
  },
  {
    label: "Flight Departure",
    id: "flight_departure",
    minWidth: 120,
  },
  {
    label: "Total Airframe Time",
    id: "total_airframe_time",
    minWidth: 120,
  },
];

const occurenceDetailsFourthColumns = [
  {
    label: "Certificate of Airworthiness Valid Till",
    id: "certificate_of_airworthiness",
  },
  {
    label: "Cerificate of Registration Date Issued",
    id: "certificate_of_registration",
  },
  {
    label: "Location",
    id: "location",
  },
  {
    label: "State",
    id: "state",
  },
];

const occurenceDetailsFifthColumns = [
  {
    label: "Country",
    id: "country",
  },
  {
    label: "Flight Phase",
    id: "flight_phase",
  },
  {
    label: "Highest Injury Level",
    id: "high_injury_level",
  },
];

const statuses = [
  {
    label: "Interim Report",
    value: "Interim Report",
  },
  {
    label: "Preliminary Report",
    value: "Preliminary Report ",
  },
  {
    label: "Draft Final Report",
    value: "Draft Final Report",
  },
];

const OccurrenceDetails = ({ information, goToNext }) => {
  const { occurrenceId } = useParams();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.users?.user?.roles?.role);
  const accessToken = useSelector((state) => state.auth.accessToken);

  React.useEffect(() => {
    if (information) {
      setActiveStatus(information.occurrence_report_status || "");
    }
  }, [information]);

  // FETCHES DATA FROM API
  const { data: mediaData, isLoading: loadingMedia } =
    useFetch("api/media-library/");
  //   const {
  //     data: dataOccurrenceType,
  //     isLoading: isLoadingOccurrenceType,
  //     refetch: refetchOccurrenceType,
  //   } = useFetch(`api/settings/occurrence/`);
  //   const {
  //     data: dataOccurrenceCategory,
  //     isLoading: isLoadingOccurrenceCategory,
  //     refetch: refetchOccurrenceCategory,
  //   } = useFetch(`api/settings/occurrence/category/`);

  // DATA
  const [occurenceDetailsVisiblity, setOccurenceDetailsVisibility] =
    useState(false);

  // IMAGES
  const [imageToShow, setImageToShow] = useState();
  const [isDone, setIsDone] = useState(false);

  // CHANGE STATUS
  const [activeStatus, setActiveStatus] = useState("");
  async function changeOccurrenceStatus(status) {
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "application/json",
        },
      };
      setActiveStatus(status);
      axios.put(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/information/${occurrenceId}/update_status/`,
        {
          status: status,
        },
        config
      );
      success("Status changed successfully");
    } catch (err) {
      error(err?.response?.data?.message);
    }
  }

  return (
    <Stack gap={"32px"}>
      <Dialog
        open={!!imageToShow}
        onClose={() => setImageToShow(undefined)}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
      >
        <MediaEditModal
          occurrenceTitle={information?.title}
          imageDetail={mediaData?.data?.find(
            (image) => image?.id === imageToShow
          )}
          setDone={() => {
            setIsDone(true);
            setImageToShow(undefined);
          }}
        />
      </Dialog>
      <Dialog
        open={isDone}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "90%",
            maxWidth: "550px",
          },
        }}
        onClose={() => setIsDone(false)}
      >
        <DoneDialog
          title="Successfully Editted"
          description="You have successfully edited this media and has been updated for you to access it."
        />
      </Dialog>
      <Stack
        bgcolor={"#fff"}
        px={"32px"}
        pt={"14px"}
        pb={"5px"}
        className="rounded-t-2xl"
      >
        <Stack>
          <div className="flex justify-between">
            <Typography fontSize={14} fontWeight={600} color={"#161616"}>
              Occurrence Information
            </Typography>
            {userRole?.toLowerCase()?.includes("super-user") && (
              <FormControl>
                <Select
                  labelId="status"
                  id="status"
                  value={activeStatus}
                  onChange={(e) => {
                    setActiveStatus(e.target.value);
                    changeOccurrenceStatus(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  className="rounded-lg font-semibold"
                  sx={{
                    "&.MuiInputBase-root": {
                      width: "220px",
                    },
                  }}
                >
                  {statuses?.map((status, index) => (
                    <MenuItem
                      key={index}
                      value={status.value}
                      className="text-secondary"
                      sx={{
                        outline: "0px",
                        "&.MuiMenuItem-root": {
                          backgroundColor: "#f4f4f4",
                          color: "#7E7E7E",
                          padding: "10px 14px",
                          borderBottom: "1px solid",
                          borderBottomColor: "#CBCBCB",
                          "&:last-child": { border: 0 },
                          "&:active": {
                            backgroundColor: "#051445",
                            color: "#ffffff !important",
                          },
                          "&:hover": {
                            backgroundColor: "#0C33AE",
                            color: "#ffffff !important",
                          },
                          "&:focus-visible": {
                            outline: 0,
                          },
                        },
                      }}
                    >
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {occurrenceInformationColumns?.map((column, index) => (
                    <TableCell key={index}>
                      <Typography
                        fontWeight={500}
                        fontSize={"13px"}
                        color={"#AEAEAE"}
                        align="center"
                        style={{ minWidth: column.minWidth }}
                      >
                        {column?.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {information && (
                  <StyledTableRow>
                    {occurrenceInformationColumns?.map((column, index) => (
                      <TableCell key={index}>
                        <Typography
                          fontSize={"14px"}
                          fontWeight={500}
                          align="center"
                        >
                          {information[column?.id]}
                        </Typography>
                      </TableCell>
                    ))}
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>

      <Stack
        bgcolor={"#fff"}
        px={"32px"}
        pt={"14px"}
        pb={"62px"}
        className="rounded-b-2xl"
        gap={"28px"}
      >
        <Stack
          width={"100%"}
          direction={"row"}
          paddingBottom={"20px"}
          gap={"64px"}
          alignItems={"flex-end"}
          justifyContent={"space-between"}
        >
          <Typography fontSize={16} fontWeight={700} color={"#161616"}>
            Occurrence Details
          </Typography>
        </Stack>
        {information && (
          <div className="overflow-x-auto">
            <div>
              <div className="grid grid-cols-4 border-b pb-3">
                {occurenceDetailsFirstColumns.map((item, index) => (
                  <Typography
                    key={index}
                    fontWeight={500}
                    fontSize={"13px"}
                    color={"#AEAEAE"}
                    align="center"
                    minWidth={120}
                  >
                    {item?.label}
                  </Typography>
                ))}
              </div>
              <div className="grid grid-cols-4 mt-3">
                {occurenceDetailsFirstColumns.map((item, index) => (
                  <Typography
                    key={index}
                    fontSize={"14px"}
                    visibility={
                      occurenceDetailsVisiblity ? "hidden" : "visible"
                    }
                    fontWeight={500}
                    minWidth={120}
                    align="center"
                  >
                    {information?.occurrence_details[item?.id] || "-"}
                  </Typography>
                ))}
              </div>
            </div>
            <div>
              <div className="grid grid-cols-4 border-b pb-3 mt-6">
                {occurenceDetailsSecondColumns.map((item, index) => (
                  <Typography
                    key={index}
                    fontWeight={500}
                    fontSize={"13px"}
                    color={"#AEAEAE"}
                    align="center"
                    minWidth={120}
                  >
                    {item?.label}
                  </Typography>
                ))}
              </div>
              <div className="grid grid-cols-4 mt-3">
                {occurenceDetailsSecondColumns.map((item, index) => (
                  <Typography
                    key={index}
                    fontSize={"14px"}
                    visibility={
                      occurenceDetailsVisiblity ? "hidden" : "visible"
                    }
                    fontWeight={500}
                    minWidth={120}
                    align="center"
                  >
                    {information?.occurrence_details[item?.id] || "-"}
                  </Typography>
                ))}
              </div>
            </div>
            <div>
              <div className="grid grid-cols-4 border-b pb-3 mt-6">
                {occurenceDetailsThirdColumns.map((item, index) => (
                  <Typography
                    key={index}
                    fontWeight={500}
                    fontSize={"13px"}
                    color={"#AEAEAE"}
                    align="center"
                    minWidth={120}
                  >
                    {item?.label}
                  </Typography>
                ))}
              </div>
              <div className="grid grid-cols-4 mt-3">
                {occurenceDetailsThirdColumns.map((item, index) => (
                  <Typography
                    key={index}
                    fontSize={"14px"}
                    visibility={
                      occurenceDetailsVisiblity ? "hidden" : "visible"
                    }
                    fontWeight={500}
                    minWidth={120}
                    align="center"
                  >
                    {information?.occurrence_details[item?.id] || "-"}
                  </Typography>
                ))}
              </div>
            </div>
            <div>
              <div className="grid grid-cols-4 border-b pb-3 mt-6">
                {occurenceDetailsFourthColumns.map((item, index) => (
                  <Typography
                    key={index}
                    fontWeight={500}
                    fontSize={"13px"}
                    color={"#AEAEAE"}
                    align="center"
                    minWidth={120}
                  >
                    {item?.label}
                  </Typography>
                ))}
              </div>
              <div className="grid grid-cols-4 mt-3">
                {occurenceDetailsFourthColumns.map((item, index) => (
                  <Typography
                    key={index}
                    fontSize={"14px"}
                    visibility={
                      occurenceDetailsVisiblity ? "hidden" : "visible"
                    }
                    fontWeight={500}
                    minWidth={120}
                    align="center"
                  >
                    {information?.occurrence_details[item?.id] || "-"}
                  </Typography>
                ))}
              </div>
            </div>
            <div>
              <div className="grid grid-cols-4 border-b pb-3 mt-6">
                {occurenceDetailsFifthColumns.map((item, index) => (
                  <Typography
                    key={index}
                    fontWeight={500}
                    fontSize={"13px"}
                    color={"#AEAEAE"}
                    align="center"
                    minWidth={120}
                  >
                    {item?.label}
                  </Typography>
                ))}
              </div>
              <div className="grid grid-cols-4 mt-3">
                {occurenceDetailsFifthColumns.map((item, index) => (
                  <Typography
                    key={index}
                    fontSize={"14px"}
                    visibility={
                      occurenceDetailsVisiblity ? "hidden" : "visible"
                    }
                    fontWeight={500}
                    minWidth={120}
                    align="center"
                  >
                    {information?.occurrence_details[item?.id] || "-"}
                  </Typography>
                ))}
              </div>
            </div>
          </div>
        )}
      </Stack>
      <Stack className="rounded-2xl bg-white py-5 px-10 font-semibold">
        <h2>Media</h2>
      </Stack>
      {mediaData?.data?.length > 0 && (
        <Stack className="grid grid-cols-3 xl:grid-cols-4 gap-6">
          {mediaData?.data?.map((item, index) => {
            return (
              <div key={index} className="bg-white py-4 px-4 rounded-2xl">
                <img
                  src={item?.url}
                  alt={item?.alt_text}
                  className="w-full h-[12rem] object-cover rounded-lg cursor-pointer"
                  onClick={() => navigate(`/media/${item?.id}`)}
                />
                <div className="mt-2 flex justify-between items-center gap-2">
                  <div className="min-w-0">
                    <h2 className="font-semibold truncate">{item?.title}</h2>
                    <p className="text-gray-600 truncate">{item?.slug}</p>
                  </div>
                  <Edit2
                    className="cursor-pointer"
                    size="24"
                    variant="Bold"
                    onClick={() => setImageToShow(item?.id)}
                  />
                </div>
              </div>
            );
          })}
        </Stack>
      )}
      <div className="flex justify-end">
        <Button
          className="bg-primary py-2 shadow-none w-fit text-white font-bold rounded-xl px-12 capitalize"
          onClick={goToNext}
        >
          Next
        </Button>
      </div>
    </Stack>
  );
};

export default OccurrenceDetails;
