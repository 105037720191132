import axios from 'axios';



const API = process.env.REACT_APP_BASE_API

export const getUser = async (thunkAPI) => {
    const accessToken = thunkAPI.getState().auth.accessToken;

    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `JWT ${accessToken}`,
            },
        }
        const res = await axios.get(
            `${API}api/user/auth-user`,
            config
        )
        return res.data
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)

    }
}

export const getUsers = async () => {
    const response = await axios.get('/api/user');

    return response.data;
}

export const getUsersGroup = async () => {
    const response = await axios.get('/api/user/allusersgroup');

    return response.data;
}

export const activateUser = async (id) => {
    const response = await axios.get(`/api/user/${id}/activate_user`);

    return response.data;
}

export const deactivateUser = async (id) => {
    const response = await axios.get(`/api/user/${id}/deactivate_user`);

    return response.data;
}

export const updateUser = async (id, body) => {
    const response = await axios.put(`/api/user/${id}`, {
        body
    });

    return response.data;
}

export const updateImage = async (formdata) => {
    const response = await axios.put('/api/user/update_profile_picture');

    return response.data;
}