import React from "react";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Typography,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputBase,
  InputAdornment,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { showPwd, hidePwd, calendarIcon } from "../../assets/";
import dayjs from "dayjs";

export default function InputField({
  fieldType,
  placeholder,
  label,
  error,
  errorText,
  width,
  disabled,
  border,
  selectItemList,
  defaultValue,
  onChange,
  value,
  flex,
  multiline,
  sm,
}) {
  const Form = styled(TextField)((theme) => ({
    "& .MuiOutlinedInput-root": {
      border: "1px solid",
      fontSize: 14,
      alignItems: "flex-start",
      height: sm ? 64 : multiline ? 150 : 48,
      borderRadius: "8px",
      background: "none",
      borderColor: "transparent",
      // background: theme.palette.secondary.light,
      color: "#000",

      "& fieldset": {
        border: "1px solid",
        borderColor: "#D9D9D9",
      },

      "&:hover fieldset": {
        border: "1px solid",
        borderColor: error ? "#DA1E28" : border ? "#b5b5b5" : "#003163",
      },

      "&:disabled .MuiTextField-root:disabled fieldset": {
        bborder: "1px solid",
        borderColor: error ? "#DA1E28" : border ? "#b5b5b5" : "#003163",
      },

      "&.Mui-focused fieldset": {
        border: "1px solid",
        borderColor: error ? "#DA1E28" : border ? "#b5b5b5" : "#003163",
      },
    },
  }));

  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [selectValue, setSelectValue] = React.useState(placeholder);
  const currentDate = new Date();

  const handleChange = (event) => {
    setSelectValue(fieldType === "date" ? null : event.target.value);
    onChange(fieldType === "date" ? event : event.target.value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Stack direction={"column"} width={width} gap={"6px"} flex={flex}>
      <Typography
        variant="p"
        fontSize={12}
        fontFamily={"Pt Sans"}
        color={"#646464"}
      >
        {label}
      </Typography>

      <Form
        fullWidth
        variant="outlined"
        placeholder={placeholder}
        type={fieldType}
        disabled={disabled}
        defaultValue={defaultValue}
        multiline={multiline}
        value={value}
        sx={{
          display:
            fieldType === "password" ||
            fieldType === "select" ||
            fieldType === "date"
              ? "none"
              : "block",
        }}
        onChange={onChange}
      />

      <Form
        fullWidth
        disabled={disabled}
        variant="outlined"
        placeholder={placeholder}
        sx={{
          display: fieldType === "password" ? "block" : "none",
        }}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              disableRipple
            >
              <img src={showPassword ? hidePwd : showPwd} />
            </IconButton>
          ),
        }}
      />
      {selectItemList && fieldType === "select" ? (
        <FormControl
          variant="standard"
          sx={{ height: "53px", position: "relative" }}
        >
          <Select
            onChange={onChange}
            IconComponent={null}
            MenuProps={{
              PaperProps: {
                sx: {
                  marginTop: 0,
                  boxShadow: "none",
                  "&:focus-visible": {
                    outline: "none",
                  },
                  borderRadius: 0,
                  py: 0,
                  "& ul": {
                    p: 0,
                    borderRadius: 0,
                    outline: 0,
                  },
                },
              },
            }}
            input={
              <InputBase
                endAdornment={
                  <InputAdornment sx={{ position: "absolute", right: "16px" }}>
                    {" "}
                    <i
                      className="bi bi-chevron-down"
                      style={{ fontSize: 12 }}
                    ></i>{" "}
                  </InputAdornment>
                }
              />
            }
            value={selectValue}
            sx={{
              height: "53px",
              "&.MuiInputBase-root": {
                height: "53px",
                display: "flex",
                alignContent: "stretch",
                justifyContent: "stretch",
              },
              "& .MuiInputBase-input": {
                fontFamily: "PT Sans",
                fontSize: 14,
                borderRadius: 0,
                position: "relative",
                py: "13.38px",
                px: "14px",
                backgroundColor: "#f4f4f4",
                border: 0,
                color: "#B1B1B1",
                fontWeight: 400,
                borderBottom: error
                  ? "1px solid"
                  : border
                  ? "1px solid"
                  : "1px solid",
                borderColor: error ? "#DA1E28" : border ? "#b5b5b5" : "#003163",
                fontFamily: "PT Sans",
                "&:focus": {
                  borderRadius: 0,
                  borderColor: error
                    ? "#DA1E28"
                    : border
                    ? "#b5b5b5"
                    : "#003163",
                },
              },
              "& .MuiPaper-root": {
                background: "#000",
              },
            }}
          >
            <MenuItem
              value={placeholder}
              sx={{
                outline: "0px",
                display: "none",
                "&.MuiMenuItem-root": {
                  backgroundColor: "#f4f4f4",
                  color: "#7E7E7E",
                  height: 49,
                  padding: "16px 14px",
                  border: 0,
                  borderBottom: "1px solid",
                  borderBottomColor: error
                    ? "#DA1E28"
                    : border
                    ? "#CBCBCB"
                    : "#003163",
                  "&:last-child": { border: 0 },
                  "&:active": {
                    backgroundColor: "#003163",
                    color: "#fff",
                  },
                  "&:hover": {
                    backgroundColor: "#003163",
                    color: "#fff",
                  },
                  "&:focus-visible": {
                    outline: 0,
                  },
                },
              }}
            >
              {placeholder}
            </MenuItem>
            {selectItemList.map((eachItem) => (
              <MenuItem
                key={eachItem.id}
                value={eachItem.value}
                sx={{
                  outline: "0px",
                  "&.MuiMenuItem-root": {
                    backgroundColor: "#f4f4f4",
                    color: "#7E7E7E",
                    height: 49,
                    padding: "16px 14px",
                    border: 0,
                    borderBottom: "1px solid",
                    borderBottomColor: error
                      ? "#DA1E28"
                      : border
                      ? "#CBCBCB"
                      : "#003163",
                    "&:last-child": { border: 0 },
                    "&:active": {
                      backgroundColor: "#003163",
                      color: "#fff",
                    },
                    "&:hover": {
                      backgroundColor: "#003163",
                      color: "#fff",
                    },
                    "&:focus-visible": {
                      outline: 0,
                    },
                  },
                }}
              >
                <Typography
                  fontFamily={"PT Sans"}
                  fontSize={14}
                  color={"inherit"}
                >
                  {eachItem.value}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}

      {fieldType === "date" ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={
              value === undefined || value === null ? "" : dayjs(`${value}`)
            }
            onChange={(value) => handleChange(value)}
            slotProps={
              {
                // textField: {
                //   placeholder: placeholder,
                //   fullWidth: true,
                //   InputProps: {
                //     endAdornment: (
                //       <InputAdornment position="end">
                //         <IconButton sx={{ p: 0 }} > <img src={calendarIcon} alt="" />
                //         </IconButton>
                //       </InputAdornment>
                //     )
                //   }
                // }
              }
            }
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "transparent",
                fontWeight: 600,
                color: "#7E7E7E",
                borderColor: "#D9D9D9",
                borderRadius: "8px",
                "& fieldset": {
                  border: "1px solid",
                  borderColor: "#D9D9D9",
                  padding: 0,
                },
                "&:hover fieldset": {
                  border: "1px solid",
                  borderColor: "#D9D9D9",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid",
                  borderColor: "#D9D9D9",
                },
              },
              "& .MuiInputBase-input": {
                cursor: "pointer",
              },
            }}
          />
        </LocalizationProvider>
      ) : null}
      <Typography
        marginTop={"-6px"}
        fontFamily={"PT Sans"}
        fontSize={12}
        variant="body2"
        sx={{
          display: error ? "block" : "none",
          color: "#DA1E28",
        }}
      >
        {errorText}
      </Typography>
    </Stack>
  );
}

export function InputField1(props) {
  const Form = styled(TextField)((theme) => ({
    border: "none",
    "& .MuiOutlinedInput-root": {
      fontFamily: "PT Sans",
      border: "none",
      fontSize: 14,
      alignItems: "flex-start",
      // height: sm ? 64 : multiline ? 150 : 48,
      borderRadius: 0,
      background: "#F4F4F4",
      borderColor: "#003163",
      // background: theme.palette.secondary.light,
      color: "#000",

      // "& fieldset": {
      //   border: 'none',
      //   borderBottom: error ? '1px solid' : border ? '1px solid' : '1px solid',
      //   borderColor: error ? '#DA1E28' : border ? '#b5b5b5' : '#003163',
      //   fontSize: 14
      // },

      // "&:hover fieldset": {
      //   borderBottom: error ? '1px solid' : border ? '1px solid' : '1px solid',
      //   borderColor: error ? '#DA1E28' : border ? '#b5b5b5' : '#003163',
      // },

      // '&:disabled .MuiTextField-root:disabled fieldset': {
      //   borderBottom: error ? '1px solid' : border ? '1px solid' : '1px solid',
      //   borderColor: error ? '#DA1E28' : border ? '#b5b5b5' : '#003163',
      // },

      // "&.Mui-focused fieldset": {
      //   borderBottom: error ? '1px solid' : border ? '1px solid' : '1px solid',
      //   borderColor: error ? '#DA1E28' : border ? '#b5b5b5' : '#003163',
      // }
    },
  }));

  const [showPassword, setShowPassword] = React.useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const currentDate = new Date();
  const handleChange = (event) => {
    setSelectValue(event.target.value);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Stack direction={"column"} gap={"6px"}>
      <Form {...props} />
    </Stack>
  );
}
