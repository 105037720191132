import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import { AxiosInterceptor } from './helpers/axiosInstance';

// FONTS
import BrFirmaRegular from "./assets/font/br-firma-font/BRFirma-Regular-BF65557b67059a7.otf";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

// All `Portal`-related components need to have the the main app wrapper element as a container
// so that the are in the subtree under the element used in the `important` option of the Tailwind's config.
const theme = createTheme({
  palette: {
    primary: {
      main: "#0C33AE",
    },
  },
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiDialog: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Raleway';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('BrFirma'), local('BrFirma-Regular'), url(${BrFirmaRegular}) format('woff2');
        }
      `,
    },
  },
  typography: {
    fontFamily: "BrFirma sans-serif",
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <PersistGate loading={null} persistor={persistor}>
              <ReactNotifications />
              <CssBaseline />
              {/* <AxiosInterceptor /> */}
              <App />
              <ToastContainer />
            </PersistGate>
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
