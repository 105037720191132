import React from "react";
import {
  Stack,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  BtnAlignCenter,
  BtnAlignLeft,
  BtnAlignRight,
  BtnBold,
  BtnItalic,
  BtnUnderline,
} from "../Synopsis";
import { Editor, EditorProvider, Toolbar } from "react-simple-wysiwyg";
import useFetch from "../../../helpers/useFetch";
import { useSelector } from "react-redux";
import { error, success } from "../../../common/Notifications";
import axios from "axios";
import { severityList } from "../section-owner/SectionForm";
import CustomSelect from "../../common/CustomSelect";

const SimilarOccurrence = ({
  occurrenceId,
  occurrenceOwner,
  information,
  isPreview,
  goToNext,
  refetch,
}) => {
  const { data: aircraftManufacturerData, isLoading: manufacturerIsLoading } =
    useFetch(`api/settings/manufacturer/`);
  const { data: airlineOperator, isLoading: airlineOperatorIsLoading } =
    useFetch(`api/settings/airline/`);
  const user = useSelector((state) => state.users.user);
  const { data: aircraftTypesData } = useFetch(`api/settings/aircraft-type/`);
  // const { data: sectionOwnerData } = useFetch(
  //   `api/occurrence-manager/sections/owners_list`
  // );
  const [formData, setFormData] = React.useState({
    occurrence: occurrenceId,
    section_group_name: "SIMILAR OCCURRENCE",
    section_owner: occurrenceOwner,
    manufacturer: "",
    airline_type: "",
    severity: "",
    airline_operator: "",
    content: "",
  });

  const [sortedAircraftTypes, setSortedAircraftTypes] = React.useState();
  React.useEffect(() => {
    setSortedAircraftTypes(aircraftTypesData?.data);
    if (
      formData.manufacturer &&
      aircraftManufacturerData?.data &&
      aircraftManufacturerData?.data?.length &&
      aircraftTypesData?.data &&
      aircraftTypesData?.data?.length
    ) {
      const selectedManufacturer = aircraftManufacturerData?.data?.find(
        (manufacturer) => manufacturer?.value == formData.manufacturer
      );
      const selectedType = aircraftTypesData?.data?.find(
        (type) => type?.value == formData.airline_type
      );
      if (selectedManufacturer) {
        if (selectedType) {
          if (selectedType?.iata_code != selectedManufacturer?.id) {
            setFormData((prev) => ({
              ...prev,
              airline_type: "",
            }));
          }
        }
        setSortedAircraftTypes(
          aircraftTypesData?.data?.filter(
            (type) => type?.iata_code == selectedManufacturer?.id
          )
        );
      }
    }
  }, [formData, aircraftManufacturerData, aircraftTypesData]);

  React.useEffect(() => {
    if (information) {
      const section = information?.occurrence_section_group?.find(
        (group) => group?.section_name === "SIMILAR OCCURRENCE"
      );
      if (
        section &&
        section.occurence_section &&
        section.occurence_section.length > 0
      ) {
        setFormData((prev) => ({
          ...prev,
          airline_operator: section.occurence_section[0].airline_operator,
          airline_type: section.occurence_section[0].airline_type,
          manufacturer: section.occurence_section[0].manufacturer,
          severity: section.occurence_section[0].severity,
          content:
            section.occurence_section[0].occurrence_page_section[0].content,
        }));
      }
    }
  }, [information]);

  const accessToken = useSelector((state) => state.auth.accessToken);
  const [loading, setLoading] = React.useState(false);
  async function handleSubmit() {
    if (
      !formData.section_owner ||
      !formData.manufacturer ||
      !formData.airline_type ||
      !formData.airline_operator ||
      !formData.content ||
      !formData.severity
    ) {
      error("Please fill all the fields");
      return;
    }
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `JWT ${accessToken}`,
          "Content-Type": "Application/json",
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BASE_API}api/occurrence-manager/sections/create_similar_occurrence/`,
        formData,
        config
      );

      success("Occurrence Details updated successfully");
      goToNext();
      refetch();
    } catch (err) {
      error(err.response?.data?.message);
    }
    setLoading(false);
  }
  return (
    <Stack gap={"32px"}>
      <div className="text-xl font-semibold bg-white rounded-2xl py-4 px-8">
        <h2>Similar Occurrence</h2>
      </div>
      <div className="bg-white py-8 px-8 rounded-2xl">
        <div className="flex gap-10">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Manufacturer
            </Typography>

            <CustomSelect
              value={formData?.manufacturer}
              list={aircraftManufacturerData?.data || []}
              isLoading={manufacturerIsLoading}
              disabled={user?.roles?.role?.toLowerCase() == "super-user"}
              placeholder="Select Manufacturer"
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  manufacturer: e,
                }));
              }}
            />
          </Stack>
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Airline Type
            </Typography>
            <FormControl className="w-full">
              <Select
                labelId="simple-select-standard-label"
                id="demo-simple-select-standard"
                value={formData?.airline_type}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    airline_type: e.target.value,
                  }));
                }}
                displayEmpty
                disabled={user?.roles?.role?.toLowerCase() == "super-user"}
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Airline type"}</p>
                </MenuItem>
                {sortedAircraftTypes?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.name}
                    className="text-secondary"
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
        <div className="flex gap-10 mt-6">
          <Stack className="flex-1" gap={"6px"}>
            <Typography variant="p" color={"#D8D8D8"}>
              Airline Operator
            </Typography>
            <CustomSelect
              value={formData?.airline_operator}
              list={airlineOperator?.data || []}
              isLoading={airlineOperatorIsLoading}
              placeholder="Select Airline Operator"
              disabled={user?.roles?.role?.toLowerCase() == "super-user"}
              onChange={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  airline_operator: e,
                }));
              }}
            />
          </Stack>
          <Stack gap={"6px"} className="flex-1">
            <Typography variant="p" color={"#D8D8D8"}>
              Severity
            </Typography>
            <FormControl>
              <Select
                labelId="Severity"
                id="Severity"
                value={formData?.severity}
                disabled={user?.roles?.role?.toLowerCase() == "super-user"}
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    severity: e.target.value,
                  }));
                }}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                className="rounded-lg font-semibold"
                sx={{
                  "&.MuiInputBase-root": {
                    minWidth: "220px",
                  },
                }}
              >
                <MenuItem value="">
                  <p className=" text-secondary ">{"Select Severity"}</p>
                </MenuItem>
                {severityList?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item?.value}
                    className="text-secondary"
                    sx={{
                      outline: "0px",
                      "&.MuiMenuItem-root": {
                        backgroundColor: "#f4f4f4",
                        color: "#7E7E7E",
                        padding: "10px 14px",
                        borderBottom: "1px solid",
                        borderBottomColor: "#CBCBCB",
                        "&:last-child": { border: 0 },
                        "&:active": {
                          backgroundColor: "#051445",
                          color: "#ffffff !important",
                        },
                        "&:hover": {
                          backgroundColor: "#0C33AE",
                          color: "#ffffff !important",
                        },
                        "&:focus-visible": {
                          outline: 0,
                        },
                      },
                    }}
                  >
                    <div className="flex items-center gap-3">
                      <span
                        className="w-3 h-3 rounded-full"
                        style={{
                          backgroundColor: item?.colorCode,
                          border: item?.border,
                        }}
                      ></span>
                      <span>{item?.label}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </div>
        <Stack className="mt-6" gap={"6px"}>
          <Typography variant="p" color={"#D8D8D8"}>
            Summary
          </Typography>
          <EditorProvider>
            <Editor
              value={formData.content}
              disabled={
                !user?.roles?.role?.toLowerCase()?.includes("occurence")
              }
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  content:
                    user?.roles?.role?.toLowerCase() == "super-user"
                      ? formData.content
                      : e.target.value,
                }))
              }
            >
              <Toolbar>
                <Stack
                  direction={"row"}
                  gap={"32px"}
                  paddingLeft={"20px"}
                  paddingY={"10px"}
                  className="text-center"
                >
                  <BtnAlignLeft />
                  <BtnAlignCenter />
                  <BtnAlignRight />
                  <BtnItalic />
                  <BtnBold />
                  <BtnUnderline />
                </Stack>
              </Toolbar>
            </Editor>
          </EditorProvider>
        </Stack>
      </div>
      <div className="flex justify-end">
        {isPreview ? (
          <Button
            variant="contained"
            className="bg-primary px-12 rounded-xl font-bold text-sm py-2.5 capitalize shadow-none"
            onClick={goToNext}
          >
            Next
          </Button>
        ) : (
          <Button
            variant="contained"
            className="bg-primary px-12 rounded-xl font-bold text-sm py-2.5 capitalize shadow-none"
            onClick={handleSubmit}
          >
            {loading ? <CircularProgress size={20} /> : "Save and Continue"}
          </Button>
        )}
      </div>
    </Stack>
  );
};

export default SimilarOccurrence;
