import React from "react";
import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Pagination,
  Button,
} from "@mui/material";
import { StyledTableRow } from "../../pages/user-data";
import { useNavigate } from "react-router-dom";
import useFetch from "../../helpers/useFetch";

const OccurrenceForReview = ({
  isSuperUser,
  isSectionOwner,
  isOccurrenceManager,
  isLoading,
  data,
}) => {
  const navigate = useNavigate();
  const {
    data: occurrencesForReviwers,
    isLoading: occurrenceForReviwersLoading,
  } = useFetch(`api/occurrence-manager/reviewers/`);

  // PAGINATION
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <div className=" mt-6">
      {(isOccurrenceManager || isSectionOwner) && (
        <div className="bg-primary rounded-t-2xl px-4 py-6 text-white flex items-center justify-between gap-4">
          <h2 className="text-xl font-semibold">Occurrence for review</h2>
          <Pagination
            rowsPerPageOptions={[5, 25, 100]}
            count={0}
            rowsPerPage={rowsPerPage}
            xs={{
              "& *": {
                color: "#ffffff",
              },
            }}
            page={page + 1}
            onChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      )}
      <Stack
        className={`${
          isSuperUser ? "py-5 rounded-2xl " : "py-0 rounded-b-2xl"
        }`}
        sx={{
          background: "#fff",
          px: "32px",
        }}
      >
        <TableContainer
          className={`${isSuperUser ? "mt-6" : "mt-0"} min-h-[20rem]`}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="text-[#AEAEAE] min-w-[180px]">
                  Occurrence Number
                </TableCell>
                <TableCell className="text-[#AEAEAE] min-w-[250px]">
                  Title
                </TableCell>
                <TableCell className="text-[#AEAEAE] min-w-[200px]">
                  Name
                </TableCell>
                {isSuperUser && (
                  <TableCell className="text-[#AEAEAE]">Status</TableCell>
                )}
                <TableCell className="text-[#AEAEAE]">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading || occurrenceForReviwersLoading ? (
                <StyledTableRow>
                  <TableCell colSpan={4} align="center">
                    <div className=" min-h-[20rem] flex items-center justify-center ">
                      <div>
                        <CircularProgress />
                        <p className="text-center animate-pulse mt-2">
                          Loading...
                        </p>
                      </div>
                    </div>
                  </TableCell>
                </StyledTableRow>
              ) : isOccurrenceManager || isSectionOwner ? (
                occurrencesForReviwers?.data?.length > 0 ? (
                  occurrencesForReviwers?.data
                    // ?.filter(
                    //   (data) => data?.occurrence_owner?.id == user?.id
                    // )
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row) => (
                      <StyledTableRow key={row?.id}>
                        <TableCell className="text-sm font-medium">
                          {row?.occurrence?.occurrence_number}
                        </TableCell>
                        <TableCell className="text-sm font-medium">
                          {row?.occurrence?.title}
                        </TableCell>
                        <TableCell className="text-sm font-medium">
                          {row?.name}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            className="cursor-pointer bg-primary capitalize font-bold text-sm shadow-none"
                            onClick={() =>
                              navigate(
                                `/occurrence/manage-occurrence/${row?.occurrence?.id}`
                              )
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                      </StyledTableRow>
                    ))
                ) : (
                  <StyledTableRow>
                    <TableCell colSpan={4} align="center">
                      No occurrence for review
                    </TableCell>
                  </StyledTableRow>
                )
              ) : (
                data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <StyledTableRow key={row?.id}>
                      <TableCell className="text-sm font-medium">
                        {row?.occurrence_number}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        {row?.title}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        {row?.occurrence_owner?.first_name}{" "}
                        {row?.occurrence_owner?.last_name}
                      </TableCell>
                      <TableCell className="text-sm font-medium">
                        <span
                          className={`capitalize px-3 py-2 rounded-full ${
                            row?.occurrence_status?.toLowerCase() === "pending"
                              ? "text-orange-700 bg-orange-50"
                              : row?.occurrence_status?.toLowerCase() ===
                                "published"
                              ? "text-red-700 bg-red-50"
                              : "text-orange-700 bg-orange-50"
                          }`}
                        >
                          {row?.occurrence_status?.toLowerCase() === ""
                            ? "Processing"
                            : row?.occurrence_status?.toLowerCase()}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          className="cursor-pointer bg-primary capitalize font-bold text-sm shadow-none"
                          onClick={() =>
                            navigate(`/occurrence/manage-occurrence/${row?.id}`)
                          }
                        >
                          View
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </div>
  );
};

export default OccurrenceForReview;
